"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * The ActivitySpec determines the parameters and properties of an Activity and its corresponding generated ActivityEvents.
 */
var ActivitySpec = /** @class */ (function () {
    function ActivitySpec() {
    }
    return ActivitySpec;
}());
exports.ActivitySpec = ActivitySpec;
