// Core Imports
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  Link,
  Menu,
  MenuItem,
  Slide,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import SPARK from "spark-core"
import locale_lang from "../locale_map.json"
import { Service } from "./DBService/DBService"

// Local Imports
import { useTranslation } from "react-i18next"
import { ReactComponent as Logotext } from "../adaptable/CompanyName.svg"
import { ReactComponent as Logo } from "../adaptable/Logo.svg"
import theme from "../adaptable/theme.json"
import { getSelectedLanguage, userLanguages } from "../i18n"
import { ResponsiveMargin } from "./Utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoLogin: {
      width: 90,
      margin: "0 auto 30px",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: 69,
        marginBottom: 30,
      },
    },
    logoText: {
      width: "100%",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        margin: "0 auto",
      },
      "& svg": { width: "100%", height: 41, marginBottom: 10 },
    },
    textfieldStyle: {
      "& input": { backgroundColor: "#f5f5f5", borderRadius: 10 },
      "& fieldset": { border: 0 },
    },
    buttonNav: {
      "& button": { width: 200, "& span": { textTransform: "capitalize", fontSize: 16, fontWeight: "bold" } },
    },
    linkBlue: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      cursor: "pointer",
      "&:hover": { textDecoration: "underline" },
    },
    loginContainer: { height: "90vh", paddingTop: "3%" },
    loginInner: { maxWidth: 280 },
    loginDisabled: {
      opacity: 0.5,
    },
  })
)

export default function LoginParticipant({ setIdentity, lastDomain, onComplete, ...props }) {
  const { t, i18n } = useTranslation()
  const [state, setState] = useState({
    serverAddress: lastDomain,
    id: undefined,
    passphrase: undefined,
    password: undefined,
  })
  const [srcLocked, setSrcLocked] = useState(false)
  const [tryitMenu, setTryitMenu] = useState<Element>()
  const [loginClick, setLoginClick] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const [dialogState, setDialogState] = useState(null)
  const [studies, setStudies] = useState([])
  const [joinableStudy, setJoinableStudy] = useState({ name: null, id: null })

  const getJoinableStudies = () => {
    // FIXME: do this using patched spark sdk
    const baseUrl = (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + state.serverAddress
    fetch(baseUrl + "/study")
      .then((res) => res.json())
      .then(
        (result) => {
          setStudies(result.data)
        },
        (error) => {
          enqueueSnackbar(`${error}`, {
            variant: "error",
          })
        }
      )
  }

  const [selectedLanguage, setSelectedLanguage]: any = useState(getSelectedLanguage(locale_lang))
  useEffect(() => {
    let query = window.location.hash.split("?")
    if (!!query && query.length > 1) {
      let src = Object.fromEntries(new URLSearchParams(query[1]))["src"]
      if (typeof src === "string" && src.length > 0) {
        setState((state) => ({ ...state, serverAddress: src }))
        setSrcLocked(true)
      }
    }
    getJoinableStudies()
  }, [])
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
  }, [selectedLanguage])

  let handleChange = (event) =>
    setState({
      ...state,
      [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
    })

  let handleLogin = (event: any): void => {
    event.preventDefault()
    setLoginClick(true)
    if (!state.password) {
      enqueueSnackbar(`${t("Incorrect passphrase.")}`, {
        variant: "error",
      })
      setLoginClick(false)
      return
    }
    setIdentity({
      id: state.passphrase,
      password: state.password,
      serverAddress: state.serverAddress,
    })
      .then((res) => {
        if (res.authType === "participant") {
          localStorage.setItem("lastTab" + res.identity.id, JSON.stringify(new Date().getTime()))
          SPARK.SensorEvent.create(res.identity.id, {
            timestamp: Date.now(),
            sensor: "spark.analytics",
            data: {
              type: "login",
              device_type: "Dashboard",
              user_agent: `SPARK-dashboard/${process.env.REACT_APP_GIT_SHA} ${window.navigator.userAgent}`,
            },
          } as any)
          SPARK.Type.setAttachment(res.identity.id, "me", "spark.participant.timezone", timezoneVal())
        }
        localStorage.setItem(
          "SPARK_user_" + res.identity.id,
          JSON.stringify({
            language: selectedLanguage,
          })
        )
        ;(async () => {
          await Service.deleteDB()
        })()
        setLoginClick(false)
        onComplete()
      })
      .catch((err) => {
        console.warn("error with auth request", err)
        enqueueSnackbar(`${t("Incorrect passphrase.")}`, {
          variant: "error",
        })
        // if (!srcLocked)
        //   enqueueSnackbar(`${t("Are you sure you're logging into the right Spark server?")}`, { variant: "info" })
        setLoginClick(false)
      })
  }
  const timezoneVal = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return timezone
  }

  const handleJoinStudy = (studyId) => {
    const baseUrl = (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + state.serverAddress
    // Make request to join study here and put it in state.
    // FIXME: do this using patched spark sdk
    fetch(baseUrl + `/study/${studyId}/join`)
      .then((res) => res.json())
      .then((res) => {
        if (res.error === undefined) {
          setState({ ...state, password: res.data.passphrase })
          setDialogState("join")
        } else {
          setDialogState("error")
        }
      })
  }

  return (
    <Slide direction="right" in={true} mountOnEnter unmountOnExit>
      <ResponsiveMargin>
        <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.loginContainer}>
          <Grid item className={classes.loginInner}>
            <form onSubmit={(e) => handleLogin(e)}>
              <Box>
                <Box className={classes.logoLogin}>
                  <Logo />
                </Box>
                <Box className={classes.logoText}>
                  <Logotext />
                  <div
                    style={{
                      height: 6,
                      marginBottom: 30,
                      background: `linear-gradient(90deg, ${theme.palette.themeColor1.main} 0%, ${theme.palette.themeColor1.main} 25%, ${theme.palette.themeColor2.main} 25%, ${theme.palette.themeColor2.main} 50%, ${theme.palette.themeColor3.main} 50%, ${theme.palette.themeColor3.main} 75%, ${theme.palette.themeColor4.main} 75%, ${theme.palette.themeColor4.main} 100%)`,
                    }}
                  />
                </Box>
                <TextField
                  select
                  label={`${t("Select Language")}`}
                  style={{ width: "100%" }}
                  onChange={(event) => {
                    localStorage.setItem("lang", JSON.stringify(event.target.value))
                    i18n.changeLanguage(event.target.value)
                    setSelectedLanguage(event.target.value)
                  }}
                  variant="filled"
                  value={selectedLanguage || "en-US"}
                >
                  {Object.keys(locale_lang).map((key, value) => {
                    if (userLanguages.includes(key)) {
                      return (
                        <MenuItem key={key} value={key}>
                          {locale_lang[key].native + " (" + locale_lang[key].english + ")"}
                        </MenuItem>
                      )
                    }
                  })}
                </TextField>

                <TextField
                  required
                  name="passphrase"
                  margin="normal"
                  variant="outlined"
                  style={{ width: "100%", height: 50 }}
                  placeholder={`${t("Username")}`}
                  value={state.passphrase || ""}
                  onChange={handleChange}
                  InputProps={{
                    classes: {
                      root: classes.textfieldStyle,
                    },
                  }}
                />
                <TextField
                  required
                  name="password"
                  margin="normal"
                  type="password"
                  variant="outlined"
                  style={{ width: "100%", height: 50, marginBottom: 40 }}
                  placeholder={`${t("Password")}`}
                  value={state.password || ""}
                  onChange={handleChange}
                  InputProps={{
                    classes: {
                      root: classes.textfieldStyle,
                    },
                  }}
                />

                <Box className={classes.buttonNav} width={1} textAlign="center">
                  <Fab
                    variant="extended"
                    type="submit"
                    style={{ background: theme.palette.primary.main, color: "White" }}
                    onClick={handleLogin}
                    className={loginClick ? classes.loginDisabled : ""}
                  >
                    {`${t("Login")}`}
                    <input
                      type="submit"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        width: "100%",
                        opacity: 0,
                      }}
                      disabled={loginClick}
                    />
                  </Fab>
                </Box>
                <Box textAlign="center" width={1} mt={4} mb={4}>
                  <Link
                    underline="none"
                    className={classes.linkBlue}
                    onClick={(event) => setTryitMenu(event.currentTarget)}
                  >
                    {`${t("Register")}`}
                  </Link>
                  <Menu
                    keepMounted
                    open={Boolean(tryitMenu)}
                    anchorPosition={tryitMenu?.getBoundingClientRect()}
                    anchorReference="anchorPosition"
                    onClose={() => setTryitMenu(undefined)}
                  >
                    <MenuItem disabled divider>
                      <b>{`${t("Join a study...")}`}</b>
                    </MenuItem>
                    {studies?.length > 0 ? (
                      studies.map((_, index) => (
                        <MenuItem
                          onClick={() => {
                            setTryitMenu(undefined)
                            setJoinableStudy(studies[index])
                            setDialogState("prompt")
                          }}
                        >
                          {`${t(studies[index].name)}`}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled={true}>{`${t("No studies available")}`}</MenuItem>
                    )}
                  </Menu>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
        <DialogItem
          dialogState={dialogState}
          setDialogState={setDialogState}
          joinableStudy={joinableStudy}
          setJoinableStudy={setJoinableStudy}
          handleJoinStudy={handleJoinStudy}
          passphrase={state.password}
        />
      </ResponsiveMargin>
    </Slide>
  )
}

function DialogItem({ dialogState, setDialogState, joinableStudy, setJoinableStudy, handleJoinStudy, passphrase }) {
  const { t, i18n } = useTranslation()
  const [viewState, setViewState] = useState({ title: null, content: null, actions: null })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    switch (dialogState) {
      case "prompt":
        setViewState({
          title: t("Would you like to join _?", { name: joinableStudy.name }),
          content: null,
          actions: (
            <>
              <Button
                onClick={() => {
                  handleJoinStudy(joinableStudy.id)
                  setIsLoading(true)
                }}
                color="primary"
              >
                {`${t("Yes!")}`}
              </Button>
              <Button
                onClick={() => {
                  setDialogState(null)
                  setJoinableStudy(undefined)
                }}
                color="primary"
              >
                {`${t("No")}`}
              </Button>
            </>
          ),
        })
        break
      case "join":
        setViewState({
          title: t(`You've joined _. This is your unique passphrase.`, { name: joinableStudy.name }),
          content: (
            <Card
              onClick={() => {
                navigator.clipboard.writeText(passphrase)
              }}
              style={{
                marginLeft: 50,
                marginRight: 50,
                marginTop: 15,
                padding: 18,
                fontSize: "large",
                boxShadow: "1px 2px 9px gray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                userSelect: "text",
              }}
            >
              {t(`${passphrase}`)}
            </Card>
          ),
          actions: (
            <>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(passphrase)
                }}
                color="primary"
              >
                {`${t("Copy passphrase")}`}
              </Button>
              <Button
                onClick={() => {
                  setDialogState(null)
                }}
                color="primary"
              >
                {`${t("Got it!")}`}
              </Button>
            </>
          ),
        })
        setIsLoading(false)
        break
      case "error":
        setViewState({
          title: t(`Something went wrong.`),
          content: null,
          actions: (
            <Button
              onClick={() => {
                setDialogState(null)
              }}
              color="primary"
            >
              {`${t("Close")}`}
            </Button>
          ),
        })
        setIsLoading(false)
        break
    }
  }, [dialogState])

  return (
    <Dialog
      open={!!dialogState}
      onClose={() => {
        setDialogState(null)
        setViewState({ title: null, content: null, actions: null })
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isLoading ? (
        <DialogContent>
          {/* TODO: What to show when loading? */}
          {t("Loading...")}
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: viewState.title }} />
            {viewState.content}
          </DialogContent>
          <DialogActions>{viewState.actions}</DialogActions>
        </>
      )}
    </Dialog>
  )
}
