// Core Imports
import { Container, Drawer, useMediaQuery, useTheme } from "@material-ui/core"
import React, { useEffect, useState } from "react"

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import locale_lang from "../../locale_map.json"
import { Service } from "../DBService/DBService"
import { ResponsivePaper } from "../Utils"
import DataPortal from "../data_portal/DataPortal"
import Organizations from "./organization/Index"
import Users from "./user/Index"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    researcherMenu: {
      background: "#ffffff",
      maxWidth: "100%",
      border: 0,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flexDirection: "row",
      },
      "& span": { fontSize: 12 },
      "& div.Mui-selected": {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        "& path": { fill: theme.palette.primary.main },
      },
    },
    menuItems: {
      display: "inline-block",
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.4)",
      paddingTop: 40,
      paddingBottom: 30,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 16,
        paddingBottom: 9,
      },
      [theme.breakpoints.down("xs")]: {
        padding: 6,
      },
    },
    menuIcon: {
      minWidth: "auto",
      [theme.breakpoints.down("xs")]: {
        top: 5,
        position: "relative",
      },
      "& path": { fill: "rgba(0, 0, 0, 0.4)", fillOpacity: 0.7 },
    },
    tableContainerWidth: {
      paddingTop: "24px",
      paddingBottom: "24px",
    },
    tableContainerDataPortalWidth: {
      height: "calc(100vw - 100px)",
      width: "calc(100vh - 50px)",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "100%",
      maxHeight: "100vh",
      top: "0px",
      left: "100px",
      overflow: "scroll",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        left: "0px",
        width: "100%",
        height: "calc(100vh - 150px)",
      },
    },
    dataPortalPaper: {
      height: "100%",
    },
    menuOuter: {
      paddingTop: 0,
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        padding: 0,
      },
    },
    logResearcher: {
      zIndex: 1111,
      [theme.breakpoints.up("md")]: {
        height: "50px",
        width: "100%",
        flexDirection: "row",
        marginTop: 51,
      },
      [theme.breakpoints.down("sm")]: {
        height: "50px",
        width: "100%",
        flexDirection: "row",
        marginBottom: 0,
      },
      outline: "#D0D5DD solid 1px",
    },
    btnCursor: {
      "&:hover div": {
        cursor: "pointer !important",
      },
      "&:hover div > svg": {
        cursor: "pointer !important",
      },
      "&:hover div > svg > g > rect": {
        cursor: "pointer !important",
      },
      "&:hover div > svg > g > g > path": {
        cursor: "pointer !important",
      },
      "&:hover div > svg > g > g > circle": {
        cursor: "pointer !important",
      },
      "&:hover div > span": {
        cursor: "pointer !important",
      },
    },
  })
)

export default function Root({ updateStore, adminType, initialTab = null, ...props }) {
  const { t, i18n } = useTranslation()
  const [currentTab, setCurrentTab] = useState(initialTab ? initialTab : 0)
  const classes = useStyles()
  const supportsSidebar = useMediaQuery(useTheme().breakpoints.up("md"))

  const getSelectedLanguage = () => {
    const matched_codes = Object.keys(locale_lang).filter((code) => code.startsWith(navigator.language))
    const lang = matched_codes.length > 0 ? matched_codes[0] : "en-US"
    return i18n.language ? i18n.language : lang ? lang : "en-US"
  }

  useEffect(() => {
    Service.deleteDB()
    localStorage.setItem("participants", JSON.stringify({ page: 0, rowCount: 40 }))
    localStorage.setItem("activities", JSON.stringify({ page: 0, rowCount: 40 }))
    if (SPARK.Auth._type !== "admin") return
  }, [])

  useEffect(() => {
    let authId = SPARK.Auth._auth.id
    let language = !!localStorage.getItem("SPARK_user_" + authId)
      ? JSON.parse(localStorage.getItem("SPARK_user_" + authId)).language
      : getSelectedLanguage()
      ? getSelectedLanguage()
      : "en"
    i18n.changeLanguage(language)
  }, [])

  return (
    <Container
      maxWidth={"xl"}
      className={currentTab !== 1 ? classes.tableContainerWidth : classes.tableContainerDataPortalWidth}
    >
      <ResponsivePaper className={currentTab === 1 ? classes.dataPortalPaper : null} elevation={0}>
        <Drawer
          anchor={supportsSidebar ? "left" : "bottom"}
          variant="permanent"
          classes={{
            paper: classes.researcherMenu + " " + classes.logResearcher,
          }}
          style={{ msFlexDirection: "row", flexDirection: "row" }}
        >
          <Button
            variant="contained"
            disableElevation={true}
            style={{
              marginLeft: 10,
              marginRight: 10,
              color: "#000",
              backgroundColor: currentTab === 0 ? "#fafafa" : "#fff",
              marginTop: 6.75,
              marginBottom: 6.75,
            }}
            onClick={(event) => setCurrentTab(0)}
          >
            {`${t("Organizations")}`}
          </Button>
          {adminType === "admin" && (
            <Button
              variant="contained"
              disableElevation={true}
              style={{
                marginLeft: 10,
                marginRight: 10,
                color: "#000",
                backgroundColor: currentTab === 1 ? "#fafafa" : "#fff",
                marginTop: 6.75,
                marginBottom: 6.75,
              }}
              onClick={(event) => setCurrentTab(1)}
            >
              {`${t("Data Portal")}`}
            </Button>
          )}
          {adminType === "admin" && (
            <Button
              variant="contained"
              disableElevation={true}
              style={{
                marginLeft: 10,
                marginRight: 10,
                color: "#000",
                backgroundColor: currentTab === 1 ? "#fafafa" : "#fff",
                marginTop: 6.75,
                marginBottom: 6.75,
              }}
              onClick={(event) => setCurrentTab(2)}
            >
              {`${t("Users")}`}
            </Button>
          )}
        </Drawer>
        {currentTab === 0 && <Organizations history={props.history} updateStore={updateStore} adminType={adminType} />}
        {currentTab === 1 && (
          <DataPortal
            onLogout={null}
            token={{
              username: SPARK.Auth._auth.id,
              password: SPARK.Auth._auth.password,
              server: SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.lamp.digital",
              type: "Administrator",
              name: "Administrator",
            }}
          />
        )}
        {currentTab === 2 && <Users history={props.history} updateStore={updateStore} adminType={adminType} />}
      </ResponsivePaper>
    </Container>
  )
}
