import { Fab, Icon } from "@material-ui/core"
import { GridActionsCellItemProps, GridColDef, GridRowParams, useGridApiRef } from "@mui/x-data-grid"
import SPARK from "spark-core"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ConfirmationDialog from "../../../ConfirmationDialog"
import TableComponent from "../../../shared/TableComponent"
import SensorDialog from "./SensorDialog"

const buttonStyle = {
  background: "transparent",
  boxShadow: "none",
  color: "#475467",
}

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Sensor",
  },
  {
    field: "spec",
    headerName: "Identifier",
    sortable: false,
  },
  {
    field: "description",
    headerName: "Description",
    sortable: false,
  },
  {
    field: "actions",
    headerName: "",
    type: "actions",
    sortable: false,
    align: "right",
    getActions: (params: GridRowParams) => [
      <DeleteSensorActionItem
        label="Delete"
        icon={<Icon>delete_outline</Icon>}
        sensor={params.row}
        setSensors={params.row.refreshData}
      />,
      <EditSensorActionItem
        label="Edit"
        icon={<Icon>edit</Icon>}
        sensor={params.row}
        setSensors={params.row.refreshData}
      />,
    ],
  },
]

function DeleteSensorActionItem({
  sensor,
  setSensors,
}: GridActionsCellItemProps & { sensor: any; setSensors: () => void }) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [confirmationDialog, setConfirmationDialog] = useState(0)
  let handleConfirmAction = async (status) => {
    if (status === "Yes") {
      await SPARK.Sensor.delete(sensor.id)
        .then((res) => {
          enqueueSnackbar(`${t("Successfully deleted the selected sensor.")}`, {
            variant: "success",
          })
        })
        .catch((error) => {
          enqueueSnackbar(`${t("An error occured while deleting. Please try again.")}`, { variant: "error" })
        })
      setSensors()
    }
    setConfirmationDialog(0)
  }
  return (
    <React.Fragment>
      <Fab variant="round" size="small" style={buttonStyle} onClick={(event) => setConfirmationDialog(7)}>
        <Icon fontSize="medium">delete_outline</Icon>
      </Fab>
      <ConfirmationDialog
        open={confirmationDialog > 0 ? true : false}
        onClose={() => setConfirmationDialog(0)}
        confirmAction={handleConfirmAction}
        confirmationMsg={`${t("Are you sure you want to delete this Sensor?")}`}
      />
    </React.Fragment>
  )
}

function EditSensorActionItem({
  sensor,
  setSensors,
}: GridActionsCellItemProps & { sensor: any; setSensors: () => void }) {
  const [sensorDialog, setSensorDialog] = useState(false)
  return (
    <React.Fragment>
      <Fab
        variant="round"
        size="small"
        style={{
          ...buttonStyle,
          marginRight: "18px",
        }}
        onClick={(event) => {
          event.stopPropagation()
          setSensorDialog(true)
        }}
      >
        <Icon fontSize="medium">edit</Icon>
      </Fab>
      <SensorDialog
        sensor={sensor}
        onClose={() => setSensorDialog(false)}
        open={sensorDialog}
        type="edit"
        projectId={sensor.projectId}
        addOrUpdateSensor={() => {
          setSensorDialog(false)
          setSensors()
        }}
        allSensors={[sensor]}
      />
    </React.Fragment>
  )
}

export default function SensorList({ sensors, setSensors, projectId }) {
  const apiRef = useGridApiRef()

  const _sensors = sensors.map((element) => {
    return {
      ...element,
      projectId: projectId,
    }
  })

  return (
    <TableComponent
      rows={_sensors}
      refreshData={setSensors}
      columns={columns}
      autosizeColumns={["name", "spec", "description", "actions"]}
    />
  )
}
