import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import ConfirmationDialog from "../../ConfirmationDialog"
import { EmptyIndicator } from "../../shared/EmptyIcon"
import useInterval from "../../useInterval"
import PatientProjectCreator from "../ProjectView/ParticipantList/PatientProjectCreator"
import { fetchPostData } from "../SaveResearcherData"
import ProjectDialog from "./ProjectDialog"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: "#fff",
      },
    },
    tableContainer: {
      "& div.MuiInput-underline:before": { borderBottom: "0 !important" },
      "& div.MuiInput-underline:after": { borderBottom: "0 !important" },
      "& div.MuiInput-underline": {
        "& span.material-icons": {
          width: 21,
          height: 19,
          fontSize: 27,
          lineHeight: "23PX",
          color: "rgba(0, 0, 0, 0.4)",
        },
        "& button": { display: "none" },
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 80,
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    projectMain: {
      background: "#fff",
      borderRadius: 12,
      width: "312.25px",
      height: "100px",
      border: "1px solid #EEEEEE",
    },
    norecords: {
      "& span": { marginRight: 5 },
    },
    disabledButton: {
      color: "#4C66D6 !important",
      opacity: 0.5,
    },
    btnWhite: {
      background: "#fff",
      borderRadius: "40px",
      boxShadow: "none",
      cursor: "pointer",
      textTransform: "capitalize",
      fontSize: "14px",
      color: theme.palette.primary.main,

      "&:hover": {
        color: theme.palette.primary.main,
        background: "#fff",
        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
      },
    },
  })
)

export default function ProjectsList({
  title,
  researcherId,
  projects,
  updatedDataProject,
  deletedDataProject,
  searchData,
  getAllProjects,
  newAddedProject,
  organizationId,
  role,
  ...props
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [search, setSearch] = useState(null)
  const [allProjects, setAllProjects] = useState(null)
  const [newProject, setNewProject] = useState(null)
  const [loading, setLoading] = useState(true)
  const [addParticipantProject, setAddParticipantProject] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleNewProjectData = (data) => {
    //setParticipants()
    setNewProject(data)
  }

  const handleClosePopUp = (data) => {
    if (data === 1) {
      setAddParticipantProject(false)
    }
  }

  useInterval(
    () => {
      setLoading(true)
      getAllProjects()
    },
    projects !== null && (projects || []).length > 0 ? null : 2000,
    true
  )

  useEffect(() => {
    getAllProjects()
    newAddedProject(newProject)
  }, [newProject])

  useEffect(() => {
    // if ((projects || []).length > 0) setAllProjects(projects)
    if (!!projects) setAllProjects(projects)
    else setAllProjects(null)
  }, [projects])

  const searchFilterProjects = async () => {
    if (!!search && search !== "") {
      let authString = SPARK.Auth._auth.id + ":" + SPARK.Auth._auth.password
      let projectsList: any = (
        await fetchPostData(authString, organizationId, "study", "organization", "GET", undefined)
      ).data
      let newProjects = projectsList.filter((i) => i.name?.toLowerCase()?.includes(search?.toLowerCase()))
      setAllProjects(newProjects)
    } else {
      getAllProjects()
    }
  }

  useEffect(() => {
    if (allProjects !== null) setLoading(false)
  }, [allProjects])

  useEffect(() => {
    searchFilterProjects()
  }, [search])

  const handleUpdatedProjectObject = (data) => {
    updatedDataProject(data)
  }

  const handleDeletedProject = (data) => {
    deletedDataProject(data)
    searchData(search)
  }

  const handleSearchData = (val) => {
    setSearch(val)
  }

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={loading || allProjects === null}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {allProjects !== null && allProjects !== null && (allProjects || []).length > 0 && (
        <Box>
          <Box className={classes.tableContainer} py={4}>
            <Grid container spacing={0} lg="auto" direction="row" justifyContent="center">
              {(allProjects || []).map((project) => (
                <ProjectItem
                  project={project}
                  projects={allProjects}
                  researcherId={researcherId}
                  handleRefresh={searchFilterProjects}
                />
              ))}
            </Grid>
          </Box>
        </Box>
      )}
      {allProjects === null ||
        ((allProjects || []).length == 0 && (
          <Box
            py={4}
            style={{
              position: "relative",
              top: "50%",
              textAlign: "center",
              display: "block",
            }}
          >
            <EmptyIndicator
              title={t("No Projects Found")}
              description={t("There are no projects here yet. Create a new project and start your project.")}
              //action={{ label: t("Add project"), onClick: () => setAddParticipantProject(true) }}
            />
          </Box>
        ))}

      <PatientProjectCreator // TODO: Further update this once more info is available
        projects={projects}
        researcherId={researcherId}
        onClose={() => setAddParticipantProject(false)}
        open={addParticipantProject}
        handleNewProject={handleNewProjectData}
        closePopUp={handleClosePopUp}
        organizationId={organizationId}
      />
    </React.Fragment>
  )
}

const ProjectItem = ({ project, projects, researcherId, handleRefresh, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [activityDialog, setActivityDialog] = useState(false)
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false)

  const onUpdateProject = () => {
    handleRefresh()
    setActivityDialog(false)
  }

  const deleteProject = (action) => {
    if (action === "Yes") {
      SPARK.Study.delete(project.id).then((res: any) => {
        if (res.error) {
          enqueueSnackbar(`${t("Something went wrong when deleting the Project.")}`, { variant: "error" })
        } else {
          enqueueSnackbar(`${t("Successfully deleted the Project.")}`, { variant: "success" })
          handleRefresh()
        }
      })
    }
    setDeleteConfirmationDialog(false)
  }

  const onClickEdit = () => {
    handleCloseMenu()
    setActivityDialog(true)
  }

  const onClickDelete = () => {
    handleCloseMenu()
    setDeleteConfirmationDialog(true)
  }

  const menuOptions = [
    { label: t("Edit Project Metadata"), onClick: onClickEdit },
    { label: t("Delete Project and all included activities"), onClick: onClickDelete },
  ]

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const displayMenu = Boolean(anchorEl)
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)

  return (
    <>
      <Grid key={project.id} style={{ margin: 6 }}>
        <Box display="flex" p={1} className={classes.projectMain} sx={{ alignItems: "center" }}>
          <Box
            display="flex"
            flexGrow={1}
            sx={{ flexDirection: "column", justifyContent: "space-between" }}
            onClick={(event) => (window.location.href = `/#/researcher/${researcherId}/project/${project.id}/overview`)}
            height="100%"
          >
            <div
              style={{
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              {project.name}
            </div>
          </Box>
          <Box
            style={{
              textAlign: "right",
            }}
          >
            <IconButton
              style={{
                marginLeft: "auto",
              }}
              onClick={handleClickMenu}
            >
              <Icon>more_vert</Icon>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={displayMenu}
              onClose={handleCloseMenu}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {menuOptions.map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={() => {
                    option.onClick?.()
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
            {/* <JoinableProject
              project={project}
              deletedProject={handleDeletedProject}
              researcherId={researcherId}
            /> */}
          </Box>
        </Box>
      </Grid>

      <ProjectDialog
        project={project}
        projects={projects}
        onClose={() => setActivityDialog(false)}
        open={activityDialog}
        onAddOrUpdateProject={onUpdateProject}
        researcherId={researcherId}
      />
      <ConfirmationDialog
        open={deleteConfirmationDialog}
        confirmAction={deleteProject}
        confirmationMsg={`Are you sure you want to delete ${project.name}?`}
      />
    </>
  )
}
