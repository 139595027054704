import { Box, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import SearchBox from "../../../SearchBox"
import AddButton from "./AddSensor"
import DeleteSensor from "./DeleteSensor"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
)
export default function Header({ projectId, selectedSensors, searchData, setSensors, allSensors, ...props }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.header}>
      <Typography variant="h5" style={{ fontSize: "30px", fontWeight: "bold" }}>{`${t("Sensors")}`}</Typography>
      <AddButton projectId={projectId} setSensors={setSensors} allSensors={allSensors} />
    </Box>
    // TODO:
    // <SearchBox searchData={searchData} />
    //
    // {(selectedSensors || []).length > 0 && (
    //   <Box className={classes.optionsMain}>
    //     <Box className={classes.optionsSub}>
    //       <DeleteSensor sensors={selectedSensors} setSensors={setSensors} />
    //     </Box>
    //   </Box>
    // )}
  )
}
