import { Box, IconButton, MenuItem, Paper, Select, Switch, TextField, Tooltip, Typography } from "@material-ui/core"
import QuestionMarkIcon from "@mui/icons-material/QuestionMark"
import { Stack } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import QuestionTypesData, { FORM_STRUCTURE_TYPE_KEY, QUESTION_TYPE_KEY } from "../../../../data/questionTypes"
import { Question } from "../../../../types/question"
import { validateExpression } from "./ExpressionMethods"
import QuestionTypeIconBox from "./QuestionTypeIcon"

export type formData = {
  question_type_id: number
  required: boolean
  conditional_logic: boolean
  condition: string | null
}

export interface IQuestionItemSettingsProps {
  question: Question
  onChange?: (question?: Question) => void
  questions?: string[]
}

export default function QuestionItemSettings(props: IQuestionItemSettingsProps) {
  const { question, onChange, questions } = props
  const { t } = useTranslation()
  const [data, setData] = useState<Question>(question)

  const updateDataField = (field: string, value: any) => {
    if (field === "type") {
      //FIXME: temp fix for setting the type field
      // Check if value matches any id in QUESTION_TYPE_KEY or FORM_STRUCTURE_TYPE_KEY fields
      const fieldToUpdate = Object.values(QuestionTypesData)
        .flatMap((data) => data.fields)
        .find((field) => field.id === value)

      if (fieldToUpdate) {
        value = fieldToUpdate.type
      }
    }
    // Update form data
    setData((prev) => ({ ...prev, [field]: value }))
    // Fire onChange event
    onChange?.({ ...data, [field]: value })
  }

  const validateLogicalExpression = () => {
    const logicalExpression = data.condition

    if (logicalExpression === "") return false

    const variables = [
      //TODO: lamp.steps or spark.steps ??
      // FIXME: system ids
      { id: undefined, human_id: "spark.steps", type: "sensor" },
      { id: undefined, human_id: "spark.gps", type: "sensor" },
      ...questions,
    ]

    return validateExpression([logicalExpression], variables)
  }

  // Update form data on question change
  React.useEffect(() => setData(question), [question])

  return (
    <Paper elevation={4}>
      <form action="post" method="#">
        <Box p={4}>
          <Typography variant="h5">{t("Question settings")}</Typography>
          <Typography>{t("General settings for this element of the question set")}</Typography>
          {/* 
          <Box my={3}>
            <Typography>{t("Question type")}</Typography>
            <Box position="relative" top={0}>
              <QuestionTypesSelect
                onChange={(e) => updateDataField("type", e.target.value)}
                value={data.question_type_id}
              />
            </Box>
          </Box> */}

          <Stack>
            <Box>
              <Box display="flex" justifyContent="space-between">
                {t("Required")}
                <Switch
                  color="primary"
                  name="required"
                  checked={data.required}
                  onChange={(e) => updateDataField(e.target.name, e.target.checked)}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                {t("Conditional logic")}
                <Switch
                  color="primary"
                  name="conditional_logic"
                  checked={data.conditional_logic}
                  onChange={(e) => {
                    updateDataField("condition", "")
                    updateDataField(e.target.name, e.target.checked)
                  }}
                />
              </Box>
              {data.conditional_logic && (
                <Box>
                  <Tooltip title="Visit the docs.">
                    <IconButton
                      size={"small"}
                      onClick={() => window.open("https://spark.opens.science/expressions.html", "_blank")}
                    >
                      <QuestionMarkIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Box mt={1}>
                    <TextField
                      variant="outlined"
                      placeholder={t("Type your condition here...")}
                      multiline
                      error={!validateLogicalExpression()}
                      helperText={
                        !validateLogicalExpression()
                          ? t("Invalid or empty logic. Check the syntax and variables of the condition")
                          : ""
                      }
                      value={data.condition}
                      onChange={(e) => updateDataField("condition", e.target.value)}
                      minRows={4}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Stack>
        </Box>
      </form>
    </Paper>
  )
}

interface IQuestionTypesSelectProps {
  onChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  value: number
}

function QuestionTypesSelect(props: IQuestionTypesSelectProps) {
  const { value, onChange } = props

  // Load data
  const questionTypes = [
    ...QuestionTypesData[QUESTION_TYPE_KEY].fields,
    ...QuestionTypesData[FORM_STRUCTURE_TYPE_KEY].fields,
  ]

  return (
    <Select fullWidth variant="outlined" value={value} onChange={onChange}>
      {questionTypes.map((field) => (
        // Render select options
        <MenuItem key={field.id} value={field.id} selected={field.id === value}>
          <Box display="flex" alignItems="center" gridGap={10}>
            <QuestionTypeIconBox
              backgroundColor={field.backgroundColor}
              borderColor={field.borderColor}
              icon={field.icon}
              style={{ paddingInline: 0 }}
            />
            <Typography>{field.label}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  )
}
