import { Box, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      "& h5": {
        fontSize: "30px",
        fontWeight: "bold",
      },
      backgroundColor: "#F9FAFB",
    },
  })
)
export default function Header({ title, ...props }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box>
      <Box display="flex" className={classes.header}>
        <Box flexGrow={1} pt={1}>
          <Typography variant="h5" style={{ marginTop: 15 }}>
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
