import { Box, Paper } from "@material-ui/core"
import React from "react"
import * as questionTypes from "../../../../data/questionTypes"
import { Question } from "../../../../types/question"
import EndForm from "./forms/EndForm"
import GroupForm from "./forms/GroupForm"
import ListForm from "./forms/ListForm"
import MultiSelectForm from "./forms/MultiSelectForm"
import RatingForm from "./forms/RatingForm"
import RegularForm from "./forms/RegularForm"
import SliderForm from "./forms/SliderForm"
import StatementForm from "./forms/StatementForm"
import TimeForm from "./forms/TimeForm"

export interface IBuildSurveyFormProps {
  question: Question
  onEditedQuestion?: (question?: Question) => void
  onEditedQuestionEntry?: (question?: Question) => void
}

export default function EditQuestionForm(props: IBuildSurveyFormProps) {
  const { question, onEditedQuestion, onEditedQuestionEntry } = props

  // Render correct form to question type
  function loadForm() {
    const formTemplate = {
      [questionTypes.QUESTION_TYPE_TEXT_ID]: RegularForm,
      [questionTypes.QUESTION_TYPE_BOOLEAN_ID]: RegularForm,
      [questionTypes.QUESTION_TYPE_SHORT_ANSWER_ID]: RegularForm,
      [questionTypes.QUESTION_TYPE_MATRIX_ID]: RegularForm,
      [questionTypes.QUESTION_TYPE_LIKE_ID]: RegularForm,
      [questionTypes.QUESTION_GROUP_TYPE_ID]: GroupForm,
      [questionTypes.QUESTION_TYPE_END_SCREEN_ID]: EndForm,
      [questionTypes.QUESTION_TYPE_STATEMENT_ID]: StatementForm,
      [questionTypes.QUESTION_TYPE_RATING_ID]: RatingForm,
      [questionTypes.QUESTION_TYPE_MULTI_SELECT_ID]: MultiSelectForm,
      [questionTypes.QUESTION_TYPE_SLIDER_ID]: SliderForm,
      [questionTypes.QUESTION_TYPE_TIME_ID]: TimeForm,
      [questionTypes.QUESTION_TYPE_LIST_ID]: ListForm,
    }[question.question_type_id]

    // Report if the question type is not supported
    if (!formTemplate) {
      throw Error(`Question type ${question.question_type_id} not supported, template could not be found`)
    }

    return formTemplate
  }

  return (
    <Paper elevation={4}>
      <Box p={4}>{React.createElement(loadForm(), { question, onEditedQuestion, onEditedQuestionEntry })}</Box>
    </Paper>
  )
}
