import { Box, Fab, MenuItem, Popover, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggleBtn: {
      padding: "5px 15px",
      borderRadius: "30px",
      color: theme.palette.primary.main + " !important",
      fontSize: "13px",
      textTransform: "capitalize",
      background: "#fff !important",
      fontWeight: 600,
      boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
    },
    toggletxt: {
      color: theme.palette.primary.main + " !important",
      fontSize: "13px",
      textTransform: "capitalize",
      background: "#fff !important",
      fontWeight: 600,
    },
    customPopover: { backgroundColor: "rgba(0, 0, 0, 0.4)" },
    customPaper: {
      maxWidth: 380,
      maxHeight: 600,
      marginTop: 75,
      marginLeft: 100,
      borderRadius: 10,
      padding: "10px 0",
      "& h6": { fontSize: 16 },
      "& li": {
        display: "inline-block",
        width: "100%",
        padding: "8px 30px",
        "&:hover": { backgroundColor: "#ECF4FF" },
      },
    },
    popoverTitle: { padding: "0 30px" },
    popexpand: {
      backgroundColor: "#fff",
      color: theme.palette.primary.main,
      zIndex: 11111,
      "& path": { fill: theme.palette.primary.main },
      "&:hover": { backgroundColor: "#f3f3f3" },
    },
    popoverItem: { "& *": { cursor: "pointer" } },
  })
)

export default function ModeToggleButton({ changeMode, ...props }) {
  const [selected, setSelected] = useState(0)
  const classes = useStyles()
  const { t } = useTranslation()
  const [popover, setPopover] = useState(null)

  const modes = [
    { type: "simple", name: t("ESM/EMA Mode") },
    { type: "advanced", name: t("Advanced Mode") },
    { type: "other", name: t("JITAI") },
  ]

  useEffect(() => {
    const mode = localStorage.getItem("mode") !== null ? localStorage.getItem("mode") : "simple"
    setSelected(modes.findIndex((item) => item.type === mode))
    changeMode(mode)
  }, [])

  useEffect(() => {
    const mode = modes[selected].type
    changeMode(mode)
    localStorage.setItem("mode", mode)
  }, [selected])

  return (
    <Box>
      <Fab
        variant="extended"
        size="small"
        className={classes.toggleBtn}
        onClick={(event) => setPopover(event.currentTarget)}
      >
        {modes[selected].name}
      </Fab>

      <Popover
        classes={{ root: classes.customPopover, paper: classes.customPaper }}
        open={!!popover ? true : false}
        anchorPosition={popover ? popover.getBoundingClientRect() : null}
        anchorReference="anchorPosition"
        onClose={() => setPopover(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <React.Fragment>
          <Typography className={classes.popoverTitle} variant="h6">{`${t("Set mode to...")}`}</Typography>
          {modes.map((mode) => (
            <MenuItem
              className={classes.popoverItem}
              onClick={() => {
                setSelected(modes.findIndex((item) => item.type === mode.type))
                setPopover(null)
              }}
            >
              <Typography variant="body1">{`${mode.name}`}</Typography>
            </MenuItem>
          ))}
        </React.Fragment>
      </Popover>
    </Box>
  )
}
