import React, { useState, useEffect } from "react"
import { Box, Backdrop, CircularProgress, makeStyles, Theme, createStyles } from "@material-ui/core"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"
import hi from "javascript-time-ago/locale/hi"
import es from "javascript-time-ago/locale/es"
import Header from "./Header"
import { useTranslation } from "react-i18next"
import ParticipantsTable from "./ParticipantsTable"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      backgroundColor: "#F9FAFB",
    },
    backdrop: {
      zIndex: 111111,
    },
    norecordsmain: {
      minHeight: "calc(100% - 114px)",
      position: "absolute",
    },
    norecords: {
      "& span": { marginRight: 5 },
    },
  })
)

function getCurrentLanguage(language) {
  let lang
  switch (language) {
    case "en_US":
      lang = "en-US"
      break
    case "hi_IN":
      lang = "hi-IN"
      break
    case "es_ES":
      lang = "es-ES"
      break
    default:
      lang = "en-US"
      break
  }
  return lang
}

function getCurrentLanguageCode(language) {
  let langCode
  switch (language) {
    case "en_US":
      langCode = en
      break
    case "hi_IN":
      langCode = hi
      break
    case "es_ES":
      langCode = es
      break
    default:
      langCode = en
      break
  }
  return langCode
}

export function getTimeAgo(language) {
  const currentLanguage = getCurrentLanguage(language)
  const currentLanguageCode = getCurrentLanguageCode(language)
  TimeAgo.addLocale(currentLanguageCode)
  return new TimeAgo(currentLanguage)
}

export default function ParticipantList({
  projectId,
  participants,
  onParticipantSelect,
  notificationColumn,
  getAllParticipants,
  mode,
  setOrder,
  order,
  ...props
}) {
  const classes = useStyles()
  // const [participants, setParticipants] = useState(null)
  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [loading, setLoading] = useState(false)
  const [paginatedParticipants, setPaginatedParticipants] = useState([])
  const [rowCount, setRowCount] = useState(40)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    let params = JSON.parse(localStorage.getItem("participants"))
    setPage(params?.page ?? 0)
    setRowCount(params?.rowCount ?? 40)
  }, [])

  useEffect(() => {
    setPaginatedParticipants(participants ?? [])
  }, [participants])

  const handleChange = (participant, checked) => {
    if (checked) {
      setSelectedParticipants((prevState) => [...prevState, participant])
    } else {
      let selected = selectedParticipants.filter((item) => item.id != participant.id)
      setSelectedParticipants(selected)
    }
  }

  const searchParticipants = (searchVal?: string) => {
    let searchTxt = searchVal ?? search
    getAllParticipants().then((participantData) => {
      if (!!searchTxt && searchTxt.trim().length > 0) {
        participantData = (participants || []).filter((i) => i.name?.includes(searchTxt) || i.id?.includes(searchTxt))
      }
      setPaginatedParticipants(participantData.slice(page * rowCount, page * rowCount + rowCount))
      setPage(page)
      setRowCount(rowCount)
      setLoading(false)
    })
    setSelectedParticipants([])
  }

  const handleSearchData = (val: string) => {
    setSearch(val)
    searchParticipants(val)
  }

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={classes.tableContainer}>
        <Header
          projectId={projectId}
          selectedParticipants={selectedParticipants}
          searchData={handleSearchData}
          setParticipants={searchParticipants}
          mode={mode}
          setOrder={setOrder}
          order={order}
        />

        <ParticipantsTable participants={paginatedParticipants} setParticipants={searchParticipants} />
      </Box>
    </React.Fragment>
  )
}
