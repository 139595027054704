import { TextField, TextFieldProps } from "@material-ui/core"
import React from "react"

export default function InputField(props: TextFieldProps) {
  const { label, style, ...restProps } = props

  return (
    <>
      {label}
      <TextField
        variant="outlined"
        style={{
          ...{
            marginTop: "10px",
          },
        }}
        {...restProps}
      />
    </>
  )
}
