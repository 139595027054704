import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  InputBase,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import { useSnackbar } from "notistack"

// Local Imports
import SPARK, { Activity } from "spark-core"

import { useTranslation } from "react-i18next"

const _qrLink = (credID, password) =>
  window.location.href.split("#")[0] +
  "#/?a=" +
  btoa([credID, password, SPARK.Auth._auth.serverAddress].filter((x) => !!x).join(":"))

const CssTextField = withStyles({
  root: {
    "label + &": {},
  },
  input: {
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.75)",
  },
})(InputBase)

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: `4px 0 0 4px`,
        },
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 16,
      color: "white",
    },
    textField: {
      width: "100%",
      ["& fieldset"]: {
        borderRadius: `10px`,
      },
    },
    PopupButton: {
      marginLeft: 10,
      color: "#fff",
      backgroundColor: "#EE9142",
      border: "1px solid #E0E0E0",
      marginTop: 6.75,
      marginBottom: 40,
      borderRadius: "8px",
      width: 168,
      height: 50,
      "&:hover": { background: "#EE9142" },
    },
    popWidth: { width: "95%", maxWidth: "500px", padding: "0 40px" },
    dialogTitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.75)",
      marginTop: 30,
    },
    disabled: {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  })
)

export interface Trigger {
  id?: string
  name?: string
  description?: string
  conditions: any[]
  activity: string
}
export default function ActivityDialog({
  activity,
  projectId,
  addOrUpdateActivity,
  activities,
  researcherId,
  ...props
}: {
  activity?: Activity
  projectId?: string
  type?: string
  addOrUpdateActivity?: Function
  activities?: Array<any>
  researcherId?: string
} & DialogProps) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [duplicateCnt, setDuplicateCnt] = useState(0)

  const [activityName, setActivityName] = useState(activity ? activity.name : "")
  const [activityID, setActivityID] = useState(activity ? activity.human_id : "")
  const [description, setDescription] = useState(activity ? activity.description : "")

  const sensorValues = {
    "spark.gps": ["longitude", "latitude", "radius"],
    "spark.steps": ["steps"],
  }

  useEffect(() => {
    let duplicateCount = 0
    if (
      !(typeof activityName === "undefined" || (typeof activityName !== "undefined" && activityName?.trim() === ""))
    ) {
      if (activities) {
        activity
          ? (duplicateCount = activities.filter((a) => {
              if (a.name !== activity.name) {
                return a.name?.trim().toLowerCase() === activityName?.trim().toLowerCase()
              }
            }).length)
          : (duplicateCount = activities.filter(
              (a) => a.name?.trim().toLowerCase() === activityName?.trim().toLowerCase()
            ).length)
      }
      setDuplicateCnt(duplicateCount)
    }
  }, [activityName])

  const validate = () => {
    return !(
      (duplicateCnt > 0 || typeof activityName == "undefined" || activityName === null || activityName.trim() === "") //||
    )
  }

  const handleCreateNewSurvey = () => {
    SPARK.Activity.create(projectId, {
      spec: "spark.survey",
      name: activityName,
      description: description,
      human_id: activityID,
    }).then((res: any) => {
      if (res.error) {
        enqueueSnackbar(`${t("Something went wrong when creating a new Survey.")}`, { variant: "error" })
      } else {
        const surveyId = res.data
        enqueueSnackbar(`${t("Successfully created a new Survey.")}`, { variant: "success" })
        window.location.href = "/#/" + `researcher/${researcherId}/project/${projectId}/activity/${surveyId}`
      }
    })
  }

  const handleUpdateSurvey = () => {
    SPARK.Activity.update(activity.id, {
      ...activity,
      name: activityName,
      description: description,
      human_id: activityID,
    }).then((res: any) => {
      if (res.error) {
        enqueueSnackbar(`${t("Something went wrong when updating the Survey.")}`, { variant: "error" })
      } else {
        enqueueSnackbar(`${t("Successfully updated the Survey.")}`, { variant: "success" })
        addOrUpdateActivity()
      }
    })
  }

  return (
    <Dialog {...props} classes={{ paper: classes.popWidth }} aria-labelledby="simple-dialog-title">
      <div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography className={classes.dialogTitle}>
          {activity ? `${t("Update Survey")}` : `${t("Let's start creating a survey")}`}
        </Typography>
        <Box mt={4}>
          {t("Give your survey a title")}
          <TextField
            error={duplicateCnt > 0 || typeof activityName == "undefined"}
            value={activityName}
            variant="outlined"
            onChange={(event) => setActivityName(event.target.value)}
            placeholder={`${t("Survey title")}`}
            className={classes.textField}
            style={{
              marginTop: "10px",
            }}
          />
        </Box>
        <Box mt={4}>
          {t("Give your survey an ID")}
          <TextField
            error={duplicateCnt > 0 || typeof activityID == "undefined"}
            value={activityID}
            variant="outlined"
            onChange={(event) => setActivityID(event.target.value)}
            placeholder={`${t("Survey ID")}`}
            className={classes.textField}
            style={{
              marginTop: "10px",
            }}
          />
        </Box>
        <Box mt={4}>
          {t("Description")}
          <TextField
            value={description}
            variant="outlined"
            onChange={(event) => setDescription(event.target.value)}
            placeholder={`${t("Enter a description")}`}
            className={classes.textField}
            multiline
            rows={6}
            style={{
              marginTop: "10px",
            }}
          />
        </Box>
        <Box textAlign="right" mt={2}>
          <Button
            onClick={activity ? handleUpdateSurvey : handleCreateNewSurvey}
            disabled={!validate()}
            className={!validate() ? classes.disabled + " " + classes.PopupButton : classes.PopupButton}
          >
            <Typography className={classes.buttonText}>{activity ? `${t("Update")}` : `${t("Continue")}`}</Typography>
          </Button>
        </Box>
      </div>
    </Dialog>
  )
}
