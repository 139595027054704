import { Fab, Icon, Link, Theme, createStyles, makeStyles } from "@material-ui/core"
import SPARK from "spark-core"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ConfirmationDialog from "../../../ConfirmationDialog"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnWhite: {
      background: "#fff",
      borderRadius: "40px",
      boxShadow: "none",
      cursor: "pointer",
      textTransform: "capitalize",
      fontSize: "14px",
      color: theme.palette.primary.main,
      "& svg": { marginRight: 8 },
      "&:hover": {
        color: theme.palette.primary.main,
        background: "#fff",
        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
      },
    },
  })
)

export default function UpdateActivity({ activity, setActivities, profile, projectId, ...props }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [confirmationDialog, setConfirmationDialog] = useState(0)
  const [participantCount, setParticipantCount] = useState(0)

  useEffect(() => {
    if (!!profile) {
      SPARK.Participant.allByStudy(activity.study_id).then((result) => {
        setParticipantCount(result.length)
      })
    }
  }, [])

  const confirmAction = (status: string) => {
    if (status === "Yes") {
      window.location.href = `/#/project/${projectId}/activity/${activity.id}`
    }
    setConfirmationDialog(0)
  }

  return (
    <span>
      {!!profile ? (
        <Fab
          size="small"
          color="primary"
          classes={{ root: classes.btnWhite }}
          onClick={(event) => {
            participantCount > 1
              ? setConfirmationDialog(3)
              : (window.location.href = `/#/project/${projectId}/activity/${activity.id}`)
          }}
        >
          <Icon>mode_edit</Icon>
        </Fab>
      ) : (
        <Link href={`/#/project/${projectId}/activity/${activity.id}`} underline="none">
          <Icon>mode_edit</Icon>
        </Link>
      )}
      <ConfirmationDialog
        open={confirmationDialog > 0 ? true : false}
        onClose={() => setConfirmationDialog(0)}
        confirmAction={confirmAction}
        confirmationMsg={
          !!profile && participantCount > 1
            ? t(
                "Changes done to this activity will reflect for all the participants under the study. Are you sure you want to proceed?."
              )
            : ""
        }
      />
    </span>
  )
}
