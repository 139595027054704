import { Box, Button, Grid, Icon, IconButton, InputAdornment, Typography } from "@material-ui/core"
import { Stack } from "@mui/material"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { specialCharacters } from "../../../../../data/specialChars"
import useValidation from "../../../../../hooks/useVal"
import { Question } from "../../../../../types/question"
import FormLayout from "./FormLayout"
import InputField from "./TextField"

export interface ITextFormProps {
  question: Question
  onEditedQuestion?: (question?: Question) => void
  onEditedQuestionEntry?: (question?: Question) => void
}

export default function MultiSelectForm(props: ITextFormProps) {
  const { question, onEditedQuestion, onEditedQuestionEntry } = props
  const { t } = useTranslation()
  const [formData, setFormData] = React.useState<Question>(question)
  const [tempForm, setTempForm] = React.useState<Question>()
  const { isValidated, addError, clearErrors } = useValidation()

  const handleSaveButtonClick = () => onEditedQuestion?.(formData)

  useEffect(() => {
    onEditedQuestionEntry?.({
      ...question,
      ...tempForm,
    })
  }, [tempForm])

  const handleAddChoiceButtonClick = () => {
    const newAnswer = {
      human_id: "",
      value: "",
    }

    setTempForm({
      ...formData,
      options: formData.options === undefined ? [newAnswer] : [...formData.options, newAnswer],
    })
  }

  const updateOption = (index: number, key: string, value: string) => {
    setTempForm(() => {
      return {
        ...formData,
        options: formData.options.map((a, i) => {
          if (i === index) {
            return { ...a, [key]: value }
          }

          return a
        }),
      }
    })
  }

  // Update form data on question change
  useEffect(() => setFormData(question), [question])

  useEffect(() => {
    clearErrors()

    if (!formData.human_id) {
      addError(t("Question ID is required"))
    }

    if (specialCharacters.test(formData.human_id)) {
      addError(t("Question ID contains illegal characters"))
    }

    if (!formData.text) {
      addError(t("Question is required"))
    }

    ;(formData.options || []).forEach((answer, index) => {
      if (!answer.human_id) {
        addError(t("Answer ID is required on answer {{index}}", { index: index + 1 }))
      }

      if (specialCharacters.test(answer.human_id)) {
        addError(t("Answer ID is contains illegal characters on answer {{index}}", { index: index + 1 }))
      }

      if (!answer.value) {
        addError(t("Answer is required on answer {{index}}", { index: index + 1 }))
      }

      if (formData.options.filter((a) => a.human_id === answer.human_id).length > 1) {
        addError(t("Answer ID must be unique on answer {{index}}", { index: index + 1 }))
      }
    })
  }, [formData])

  return (
    <FormLayout
      label={t("Specify your question")}
      description={t("Create a question with multiple answers. The user can select one or more answers.")}
    >
      <Stack spacing={2} mt={4}>
        <div>
          <InputField
            label={t("Your question")}
            placeholder={t("Type your question here..")}
            style={{
              marginTop: "10px",
            }}
            value={formData.text}
            onChange={(e) => setTempForm({ ...formData, text: e.target.value })}
          />
        </div>

        <div>
          <InputField
            label={t("Question ID")}
            placeholder={t("Type your question ID here..")}
            style={{
              marginTop: "10px",
            }}
            value={formData.human_id}
            onChange={(e) => setTempForm({ ...formData, human_id: e.target.value })}
          />
        </div>

        <div>
          <InputField
            label={t("Description (optional)")}
            placeholder={t("Enter a description...")}
            style={{
              marginTop: "10px",
            }}
            value={formData.description}
            onChange={(e) => setTempForm({ ...formData, description: e.target.value })}
          />
        </div>
      </Stack>

      <Box mt={4}>
        <Typography variant="h5">{t("Create the anwers")}</Typography>

        {(formData.options || []).map((answer, index) => {
          return (
            <Stack key={index}>
              <Grid container spacing={1}>
                <Grid item sm={9}>
                  <InputField
                    placeholder={t("Type your answer here..")}
                    style={{ marginTop: "10px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span
                            style={{
                              border: "1px solid #EAECF0",
                              backgroundColor: "#F9FAFB",
                              borderRadius: 8,
                              padding: "4px 8px",
                            }}
                          >
                            {String.fromCharCode(65 + index)}
                          </span>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => updateOption(index, "value", e.target.value)}
                    value={formData.options[index].value}
                  />
                </Grid>
                <Grid item sm={2}>
                  <InputField
                    placeholder={t("ID")}
                    style={{ marginTop: "10px" }}
                    value={formData.options[index].human_id}
                    onChange={(e) => updateOption(index, "human_id", e.target.value)}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <IconButton
                      onClick={() => {
                        setTempForm({
                          ...formData,
                          options: formData.options.filter((_, i) => i !== index),
                        })
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          )
        })}

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="outlined" size="large" onClick={handleAddChoiceButtonClick}>
            {t("Add choice")}
          </Button>
        </Box>
      </Box>
    </FormLayout>
  )
}
