import { Edge, Node } from "reactflow"

/**
 * Remove a node and all linked nodes and edges.
 *
 * @param nodeId The id of the node to remove.
 * @param nodes The current nodes in the diagram.
 * @param edges The current edges in the diagram.
 * @param setNodes The function to set the nodes.
 * @param setEdges The function to set the edges.
 *
 * @returns void
 */
export const removeNode = (nodeId: string, nodes: Node[], edges: Edge[], setNodes, setEdges): void => {
  const linkedEdges = edges.filter((edge) => edge.source === nodeId)
  const linkedNodes = nodes.filter((node) => linkedEdges.map((edge) => edge.target).includes(node.id))

  setNodes((nds) => nds.filter((node) => node.id !== nodeId))
  setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId))

  if (linkedNodes.length !== 0) {
    linkedNodes.forEach((node) => removeNode(node.id, nodes, edges, setNodes, setEdges))
  }
}
