import React, { useState, useEffect } from "react"
import { Box, Backdrop, CircularProgress, makeStyles, Theme, createStyles } from "@material-ui/core"
import Header from "./Header"
import { useTranslation } from "react-i18next"
import SensorList from "./SensorList"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: theme.palette.background.default,
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
  })
)

export default function SensorsList({ sensors, getAllSensors, setOrder, order, mode, projectId }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [selectedSensors, setSelectedSensors] = useState([])
  const [shownSensors, setShownSensors] = useState([])
  const [search, setSearch] = useState(null)

  useEffect(() => {
    searchSensors()
  }, [sensors])

  const handleChange = (sensorData, checked) => {
    if (checked) {
      setSelectedSensors((prevState) => [...prevState, sensorData])
    } else {
      let selected = selectedSensors.filter((item) => item.id != sensorData.id)
      setSelectedSensors(selected)
    }
  }

  const searchSensors = (searchVal?: string) => {
    const searchTxt = searchVal ?? search
    let result = []
    getAllSensors().then((sensorData) => {
      result = result.concat(sensorData)
      if (!!searchTxt && searchTxt.trim().length > 0) {
        result = result.filter((i) => i.name?.toLowerCase()?.includes(searchTxt?.toLowerCase()))
      }
      setShownSensors(result)
      setLoading(false)
    })
    setSelectedSensors([])
  }

  const handleSearchData = (val: string) => {
    setSearch(val)
    searchSensors(val)
  }

  return (
    <Box className={classes.container}>
      <Backdrop className={classes.backdrop} open={loading || sensors === null}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header
        selectedSensors={selectedSensors}
        searchData={handleSearchData}
        setSensors={searchSensors}
        setOrder={setOrder}
        order={order}
        simpleMode={mode === "other"}
        projectId={projectId}
        allSensors={sensors}
      />
      <SensorList sensors={shownSensors} setSensors={searchSensors} projectId={projectId} />
    </Box>
  )
}
