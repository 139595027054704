import { Box } from "@material-ui/core"
import { Stack } from "@mui/material"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { specialCharacters } from "../../../../../data/specialChars"
import useValidation from "../../../../../hooks/useVal"
import { ActivityListFormProps } from "../../../../../types/activityListFormProps"
import { Question } from "../../../../../types/question"
import FormLayout from "./FormLayout"
import InputField from "./TextField"

export interface ITextFormProps {
  question: Question
  onEditedQuestion?: (question?: Question) => void
  onEditedQuestionEntry?: (question?: Question) => void
}

export default function EndForm(props: ActivityListFormProps) {
  const { question, onEditedQuestion, onEditedQuestionEntry } = props
  const { t } = useTranslation()
  const [formData, setFormData] = React.useState<Question>(question)
  const [tempForm, setTempForm] = React.useState<Question>()
  const { isValidated, addError, clearErrors } = useValidation()

  const handleSaveButtonClick = () => onEditedQuestion?.(formData as Question)

  useEffect(() => {
    onEditedQuestionEntry?.({
      ...question,
      ...tempForm,
    })
  }, [tempForm])

  // Update form data on question change
  useEffect(() => setFormData(question), [question])

  // Check if the form is validated
  useEffect(() => {
    clearErrors()

    if (!formData.human_id) {
      addError(t("Question ID is required"))
    }

    if (specialCharacters.test(formData.human_id)) {
      addError(t("Question ID contains illegal characters"))
    }

    if (!formData.text) {
      addError(t("Question is required"))
    }
  }, [formData])

  return (
    <FormLayout label={t("End screen")} description={t("a final screen")}>
      <Stack spacing={2} mt={4}>
        <div>
          <InputField
            label={t("Screen name")}
            placeholder={t("Type your screen name here..")}
            style={{
              marginTop: "10px",
            }}
            value={formData.text}
            onChange={(e) => setTempForm({ ...formData, text: e.target.value })}
          />
        </div>

        <div>
          <InputField
            label={t("ID")}
            placeholder={t("Type your group ID here..")}
            style={{
              marginTop: "10px",
            }}
            value={formData.human_id}
            onChange={(e) => setTempForm({ ...formData, human_id: e.target.value })}
          />
        </div>

        <div>
          <InputField
            label={t("Description (optional)")}
            placeholder={t("Enter a description...")}
            style={{
              marginTop: "10px",
            }}
            value={formData.description}
            onChange={(e) => setTempForm({ ...formData, description: e.target.value })}
          />
        </div>
      </Stack>

      <Box mt={4}>
        <Box display="flex" justifyContent="space-between" mt={2} width="100%"></Box>
      </Box>
    </FormLayout>
  )
}
