"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * An event generated by a participant interacting with the SPARK app.
 */
var SensorEvent = /** @class */ (function () {
    function SensorEvent() {
    }
    return SensorEvent;
}());
exports.SensorEvent = SensorEvent;
