import { Backdrop, Box, Button, CircularProgress, Theme, createStyles, makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"

import { Add } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import ActivityDialog from "./ActivityDialog"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbardashboard: {
      minHeight: 100,
      padding: "0 10px",
      "& h5": {
        color: "rgba(0, 0, 0, 0.75)",
        textAlign: "left",
        fontWeight: "600",
        fontSize: 30,
        width: "calc(100% - 96px)",
        [theme.breakpoints.down("sm")]: {
          fontSize: 25,
        },
      },
    },
    btnBlue: {
      marginTop: 15,
      background: theme.palette.primary.main,
      borderRadius: "40px",
      minWidth: 100,
      boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
      lineHeight: "38px",
      cursor: "pointer",
      textTransform: "capitalize",
      fontSize: "16px",
      color: "#fff",
      "& svg": { marginRight: 8 },
      "&:hover": { background: theme.palette.primary.main },
      [theme.breakpoints.up("md")]: {
        position: "absolute",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "auto",
      },
    },
    tableContainer: {
      "& div.MuiInput-underline:before": { borderBottom: "0 !important" },
      "& div.MuiInput-underline:after": { borderBottom: "0 !important" },
      "& div.MuiInput-underline": {
        "& span.material-icons": {
          width: 21,
          height: 19,
          fontSize: 27,
          lineHeight: "23PX",
          color: "rgba(0, 0, 0, 0.4)",
        },
        "& button": { display: "none" },
      },
    },
    customPopover: { backgroundColor: "rgba(0, 0, 0, 0.4)" },
    customPaper: {
      maxWidth: 380,
      maxHeight: 400,
      marginTop: 75,
      marginLeft: 100,
      borderRadius: 10,
      padding: "10px 0",
      "& h6": { fontSize: 16 },
      "& li": {
        display: "inline-block",
        width: "100%",
        padding: "8px 30px",
        minWidth: "300px",
        "&:hover": { backgroundColor: "#ECF4FF" },
      },
      "& *": { cursor: "pointer" },
      "& a": {
        display: "block",
        fontSize: "1rem",
        color: "rgba(0, 0, 0, 0.87)",
        padding: "8px 30px",
        textTransform: "capitalize",
        "&:hover": { backgroundColor: "#ECF4FF" },
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    popexpand: {
      backgroundColor: "#fff",
      color: theme.palette.primary.main,
      zIndex: 11111,
      "& path": { fill: theme.palette.primary.main },
      "&:hover": { backgroundColor: "#f3f3f3" },
    },
    addText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    dividerMain: {
      margin: 0,
    },
    borderTop: { borderTop: "1px solid rgba(0, 0, 0, 0.20)" },
  })
)

export default function AddActivity({
  projectId,
  simpleMode,
  activities,
  styleType = false,
  researcherId,
  ...props
}: {
  projectId?: string
  simpleMode?: boolean
  activities?: Array<any>
  styleType?: boolean
  researcherId?: string
}) {
  const [activitySpecs, setActivitySpecs] = useState([])
  const { t } = useTranslation()
  const classes = useStyles()
  const [popover, setPopover] = useState(null)
  const [loading, setLoading] = useState(true)
  const [activityDialog, setActivityDialog] = useState(false)

  const addOrUpdateActivities = () => {
    //refreshTriggers()
    setActivityDialog(false)
  }

  const activitiesObj = {
    "spark.journal": `${t("Journal")}`,
    "spark.scratch_image": `${t("Scratch card")}`,
    "spark.breathe": `${t("Breathe")}`,
    "spark.tips": `${t("Tip")}`,
    "spark.dbt_diary_card": `${t("DBT Diary Card")}`,
    "spark.cats_and_dogs": `${t("Cats and Dogs")}`,
    "spark.jewels_a": `${t("Jewels A")}`,
    "spark.jewels_b": `${t("Jewels B")}`,
    "spark.spatial_span": `${t("Spatial Span")}`,
    "spark.pop_the_bubbles": `${t("Pop the bubbles")}`,
    "spark.balloon_risk": `${t("Balloon Risk")}`,
    "spark.recording": `${t("Voice Recording")}`,
    "spark.survey": `${t("Survey Instrument")}`,
    "spark.group": `${t("Activity Group")}`,
  }

  const getActivitySpec = async (id) => {
    try {
      const spec = await SPARK.ActivitySpec.view(id)
      if (!!spec && (!!spec?.executable || !!spec?.settings)) {
        return spec
      }
      return null
    } catch (e) {
      return null
    }
  }

  useEffect(() => {
    ;(async () => {
      const allSpecs = await SPARK.ActivitySpec.all()
      let availableSpecs = allSpecs.filter((x: any) => Object.keys(activitiesObj).includes(x?.id))
      let otherSpecs = allSpecs.filter((x: any) => !Object.keys(activitiesObj).includes(x?.id))
      let i = 0
      if (otherSpecs.length > 0) {
        await otherSpecs.map(async (x: any, index: number) => {
          if (!!x.id) {
            await getActivitySpec(x.id).then((spec) => {
              if (!!spec) availableSpecs.push(spec)
            })
          }
          if (index === otherSpecs.length - 1) {
            setActivitySpecs(availableSpecs)
            setLoading(false)
          }
        })
      } else {
        setLoading(false)

        // Default activity specs
        setActivitySpecs([
          { id: "spark.survey" },
          // { id: "spark.balloon_risk" },
          // { id: "spark.cats_and_dogs" },
          // { id: "spark.jewels_a" },
          // { id: "spark.jewels_b" },
          // { id: "spark.pop_the_bubbles" },
          // { id: "spark.spatial_span" },
          { id: "spark.recording" },
          { id: "spark.group" },
          // { id: "spark.journal" },
          // { id: "spark.scratch_image" },
          // { id: "spark.breathe" },
          // { id: "spark.dbt_diary_card" },
          { id: "spark.tips" },
        ])
      }
    })()
  }, [])

  return (
    <Box
      style={{
        marginLeft: "auto",
      }}
    >
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
        variant="contained"
        tabIndex={-1}
        startIcon={styleType ? null : <Add />}
        endIcon={styleType ? <Add /> : null}
        disableElevation={true}
        style={
          styleType
            ? {
                marginTop: "20px",
                borderRadius: "10px",
                boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
                fontSize: "14px",
                color: "#fff",
                backgroundColor: "#EE9142",
              }
            : {
                marginLeft: 6,
                marginRight: 6,
                color: "#fff",
                backgroundColor: "#EE9142",
                border: "1px solid #E0E0E0",
                marginTop: 6.75,
                marginBottom: 6.75,
                borderRadius: "8px",
              }
        }
        onClick={(event) => {
          setActivityDialog(true)
        }}
      >
        {styleType ? t("New Question Set") : t("Add Question Set")}
      </Button>
      <ActivityDialog
        projectId={projectId}
        onClose={() => setActivityDialog(false)}
        open={activityDialog}
        type="add"
        addOrUpdateActivity={addOrUpdateActivities}
        activities={activities}
        researcherId={researcherId}
      />
      {/* <Popover
        open={!!popover ? true : false}
        //anchorPosition={!!popover && popover.getBoundingClientRect()}
        anchorPosition={popover ? popover.getBoundingClientRect() : null}
        anchorReference="anchorPosition"
        classes={{ root: classes.customPopover, paper: classes.customPaper }}
        onClose={() => setPopover(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <React.Fragment>
          {simpleMode ? (
            activitySpecs.filter((x) => ["spark.group"].includes(x.id)).length >= 0 && (
              <React.Fragment>
                <MenuItem disabled divider>
                  <b>{`${t("Create a new...")}`}</b>
                </MenuItem>
                {activitySpecs
                  .filter((x) => ["spark.survey"].includes(x.id))
                  .map((x) => (
                    <Link href={`/#/project/${projectId}/activity/add/${x?.id?.replace("spark.", "")}`} underline="none">
                      {activitiesObj[x.id]
                        ? `${t(activitiesObj[x.id])}`
                        : `${t(x?.id?.replace("spark.", "").replaceAll("_", " "))}`}
                    </Link>
                  ))}
              </React.Fragment>
            )
          ) : (
            <>
              {activitySpecs.filter((x) => ["spark.group", "spark.survey"].includes(x.id)).length >= 0 && (
                <React.Fragment>
                  <MenuItem disabled divider>
                    <b>{`${t("Create a new...")}`}</b>
                  </MenuItem>
                  {activitySpecs
                    // .filter((x) => ["spark.survey"].includes(x.id))
                    .map((x) => (
                      <Link
                        href={`/#/project/${projectId}/activity/add/${x?.id?.replace("spark.", "")}`}
                        underline="none"
                      >
                        {activitiesObj[x.id]
                          ? `${t(activitiesObj[x.id])}`
                          : `${t(x?.id?.replace("spark.", "").replaceAll("_", " "))}`}
                      </Link>
                    ))}
                </React.Fragment>
              )}
            </>
          )}
        </React.Fragment>
      </Popover> */}
    </Box>
  )
}
