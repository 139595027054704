// Core Imports
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Fab,
  Grid,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuItem,
  OutlinedInput,
  Slide,
  Theme,
  colors,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import SPARK from "spark-core"
import locale_lang from "../locale_map.json"
import { Service } from "./DBService/DBService"

// Local Imports
import { useTranslation } from "react-i18next"
import { ResponsiveMargin } from "./Utils"

import { DialogActions } from "@mui/material"
import { isMobile } from "react-device-detect"
import login1 from "../adaptable/Login1.jpeg"
import theme from "../adaptable/theme.json"
import { getSelectedLanguage } from "../i18n"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoLogin: {
      width: 90,
      margin: "0 auto 30px",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: 69,
        marginBottom: 30,
      },
    },
    logoText: {
      width: "100%",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        margin: "0 auto",
      },
      "& svg": { width: "100%", height: 41, marginBottom: 10 },
    },
    textfieldStyle: {
      "& input": { backgroundColor: "#fff", borderRadius: 8 },
      "& fieldset": { border: 1 },
    },
    buttonNav: {
      "& button": { width: "100%", "& span": { textTransform: "capitalize", fontSize: 16, fontWeight: "bold" } },
    },
    linkBlue: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      cursor: "pointer",
      "&:hover": { textDecoration: "underline" },
    },
    loginContainer: { height: "90vh", paddingTop: "3%" },
    loginInner: { maxWidth: 350 },
    loginDisabled: {
      opacity: 0.5,
    },
  })
)

export default function Login({ setIdentity, lastDomain, onComplete, ...props }) {
  const { t, i18n } = useTranslation()
  const [state, setState] = useState({ serverAddress: lastDomain, id: undefined, password: undefined })
  const [srcLocked, setSrcLocked] = useState(false)
  const [tryitMenu, setTryitMenu] = useState<Element>()
  const [helpMenu, setHelpMenu] = useState<Element>()
  const [loginClick, setLoginClick] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [studies, setStudies] = useState([])
  const [joinableStudy, setJoinableStudy] = useState({ name: null, id: null })
  const [dialogState, setDialogState] = useState(null)

  const getJoinableStudies = () => {
    // FIXME: do this using patched spark sdk
    const baseUrl = (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + state.serverAddress
    fetch(baseUrl + "/study")
      .then((res) => res.json())
      .then(
        (result) => {
          setStudies(result.data)
        },
        (error) => {
          enqueueSnackbar(`${error}`, {
            variant: "error",
          })
        }
      )
  }

  const [selectedLanguage, setSelectedLanguage]: any = useState(getSelectedLanguage(locale_lang))
  useEffect(() => {
    let query = window.location.hash.split("?")
    if (!!query && query.length > 1) {
      let src = Object.fromEntries(new URLSearchParams(query[1]))["src"]
      if (typeof src === "string" && src.length > 0) {
        setState((state) => ({ ...state, serverAddress: src }))
        setSrcLocked(true)
      }
    }
    getJoinableStudies()
  }, [])

  let handleServerInput = (value) => setState({ ...state, serverAddress: value })

  let handleChange = (event) =>
    setState({
      ...state,
      [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
    })

  let handleLogin = (event: any, mode?: string): void => {
    event.preventDefault()
    setLoginClick(true)
    if (mode === undefined && (!state.id || !state.password)) {
      enqueueSnackbar(`${t("Incorrect username, password, or server address.")}`, {
        variant: "error",
      })
      setLoginClick(false)
      return
    }
    setIdentity({
      id: !!mode ? `${mode}@demo.spark.digital` : state.id,
      password: !!mode ? "demo" : state.password,
      serverAddress: !!mode ? "demo.spark.digital" : state.serverAddress,
    })
      .then((res) => {
        if (res.authType === "participant") {
          localStorage.setItem("lastTab" + res.identity.id, JSON.stringify(new Date().getTime()))
          SPARK.SensorEvent.create(res.identity.id, {
            timestamp: Date.now(),
            sensor: "spark.analytics",
            data: {
              type: "login",
              device_type: "Dashboard",
              user_agent: `SPARK-dashboard/${process.env.REACT_APP_GIT_SHA} ${window.navigator.userAgent}`,
            },
          } as any).then((res) => console.dir(res))
          SPARK.Type.setAttachment(res.identity.id, "me", "spark.participant.timezone", timezoneVal())
        }
        if (res.authType === "researcher" && res.auth.serverAddress === "demo.spark.digital") {
          let studiesSelected =
            localStorage.getItem("studies_" + res.identity.id) !== null
              ? JSON.parse(localStorage.getItem("studies_" + res.identity.id))
              : []
          if (studiesSelected.length === 0) {
            let studiesList = [res.identity.name]
            localStorage.setItem("studies_" + res.identity.id, JSON.stringify(studiesList))
            localStorage.setItem("studyFilter_" + res.identity.id, JSON.stringify(1))
          }
        }
        localStorage.setItem(
          "SPARK_user_" + res.identity.id,
          JSON.stringify({
            language: selectedLanguage,
          })
        )
        ;(async () => {
          await Service.deleteDB()
        })()
        setLoginClick(false)
        onComplete()
      })
      .catch((err) => {
        console.warn("error with auth request", err)
        enqueueSnackbar(`${t("Incorrect username, password, or server address.")}`, {
          variant: "error",
        })
        if (!srcLocked)
          enqueueSnackbar(`${t("Are you sure you're logging into the right Spark server?")}`, { variant: "info" })
        setLoginClick(false)
      })
  }
  const timezoneVal = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return timezone
  }

  const handleJoinStudy = (studyId) => {
    const baseUrl = (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + state.serverAddress
    // Make request to join study here and put it in state.
    // FIXME: do this using patched spark sdk
    fetch(baseUrl + `/study/${studyId}/join`)
      .then((res) => res.json())
      .then((res) => {
        if (res.error === undefined) {
          setState({ ...state, password: res.data.passphrase, id: res.data.passphrase })
          setDialogState("join")
        } else {
          setDialogState("error")
        }
      })
  }

  return (
    <Slide direction="right" in={true} mountOnEnter unmountOnExit>
      <ResponsiveMargin>
        <Grid container direction="row" style={{ width: "100%", height: "100%" }}>
          <Box style={{ minWidth: isMobile ? "100%" : "45%" }}>
            <Box style={{ marginLeft: 25, marginTop: 25 }}>
              <Button
                variant="text"
                style={{ width: 150, justifyContent: "flex-start" }}
                onClick={(event) => (window.location.href = `/#/`)}
              >
                <IconButton
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: 8,
                    outline: 1,
                    outlineColor: "#D0D5DD",
                    outlineStyle: "solid",
                    marginRight: 5,
                  }}
                >
                  <Icon color="primary">bolt</Icon>
                </IconButton>
                <b>{`${t("Spark")}`}</b>
              </Button>
            </Box>
            <Menu
              id="simple-menu"
              anchorEl={helpMenu}
              keepMounted
              open={Boolean(helpMenu)}
              onClose={() => setHelpMenu(undefined)}
            >
              <MenuItem
                dense
                onClick={() => {
                  setHelpMenu(undefined)
                  window.open("https://spark.opens.science/", "_blank")
                }}
              >
                <b style={{ color: colors.grey["600"] }}>{`${t("Help & Support")}`}</b>
              </MenuItem>
              <MenuItem
                dense
                onClick={() => {
                  setHelpMenu(undefined)
                  window.open("https://community.lamp.digital", "_blank")
                }}
              >
                <b style={{ color: colors.grey["600"] }}>SPARK {`${t("Community")}`}</b>
              </MenuItem>
              <MenuItem
                dense
                onClick={() => {
                  setHelpMenu(undefined)
                  window.open("mailto:team@digitalpsych.org", "_blank")
                }}
              >
                <b style={{ color: colors.grey["600"] }}>{`${t("Contact Us")}`}</b>
              </MenuItem>
            </Menu>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.loginContainer}
            >
              <Grid item className={classes.loginInner}>
                <form onSubmit={(e) => handleLogin(e)}>
                  <Box>
                    <h1>{`${t("Welcome back")}`}</h1>
                    <span>{`${t("Welcome back! Please enter your details.")}`}</span>
                    <br />
                    <br />
                    <span>{`${t("Username")}`}</span>
                    <OutlinedInput
                      required
                      name="id"
                      type="email"
                      style={{
                        width: "100%",
                        height: 45,
                        backgroundColor: "#fff",
                        borderColor: "#fff",
                        border: 1,
                        borderRadius: 8,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                      placeholder={`${t("Enter your username")}`}
                      value={state.id || ""}
                      onChange={handleChange}
                    />
                    <span>{`${t("Password")}`}</span>
                    <OutlinedInput
                      required
                      name="password"
                      type="password"
                      style={{
                        width: "100%",
                        height: 45,
                        backgroundColor: "#fff",
                        borderColor: "#fff",
                        border: 1,
                        borderRadius: 8,
                        marginTop: 10,
                        marginBottom: 40,
                      }}
                      placeholder="•••••••••"
                      value={state.password || ""}
                      onChange={handleChange}
                    />

                    <Box className={classes.buttonNav} width="100%" textAlign="center">
                      <Fab
                        variant="extended"
                        type="submit"
                        style={{ background: theme.palette.primary.main, color: "White", borderRadius: 8 }}
                        onClick={handleLogin}
                        className={loginClick ? classes.loginDisabled : ""}
                      >
                        {`${t("Sign in")}`}
                        <input
                          type="submit"
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            width: "100%",
                            opacity: 0,
                          }}
                          disabled={loginClick}
                        />
                      </Fab>
                    </Box>

                    <Box textAlign="center" width={1} mt={4} mb={4}>
                      {`${t("Don't have an account? ")}`}
                      <Link
                        underline="none"
                        className={classes.linkBlue}
                        onClick={(event) => setTryitMenu(event.currentTarget)}
                      >
                        {`${t("Sign up")}`}
                      </Link>
                      <br />
                      <Menu
                        keepMounted
                        open={Boolean(tryitMenu)}
                        anchorPosition={tryitMenu?.getBoundingClientRect()}
                        anchorReference="anchorPosition"
                        onClose={() => setTryitMenu(undefined)}
                      >
                        <MenuItem disabled divider>
                          <b>{`${t("Join a study...")}`}</b>
                        </MenuItem>
                        {studies?.length > 0 ? (
                          studies.map((_, index) => (
                            <MenuItem
                              key={index}
                              onClick={() => {
                                setTryitMenu(undefined)
                                setJoinableStudy(studies[index])
                                setDialogState("prompt")
                              }}
                            >
                              {`${t(studies[index].name)}`}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled={true}>{`${t("No studies available")}`}</MenuItem>
                        )}
                      </Menu>
                    </Box>
                  </Box>
                </form>
              </Grid>
            </Grid>
            <DialogItem
              dialogState={dialogState}
              setDialogState={setDialogState}
              joinableStudy={joinableStudy}
              setJoinableStudy={setJoinableStudy}
              handleJoinStudy={handleJoinStudy}
              passphrase={state.password}
            />
          </Box>
          {isMobile ? null : (
            <Box
              style={{
                width: "55%",
                backgroundColor: "#fafafa",
                borderTopLeftRadius: 64,
                borderBottomLeftRadius: 64,
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `url(${login1})`,
                backgroundSize: "100% auto",
                backgroundPosition: "center",
              }}
            ></Box>
          )}
        </Grid>
      </ResponsiveMargin>
    </Slide>
  )
}

function DialogItem({ dialogState, setDialogState, joinableStudy, setJoinableStudy, handleJoinStudy, passphrase }) {
  const { t, i18n } = useTranslation()
  const [viewState, setViewState] = useState({ title: null, content: null, actions: null })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    switch (dialogState) {
      case "prompt":
        setViewState({
          title: t("Would you like to join _?", { name: joinableStudy.name }),
          content: null,
          actions: (
            <>
              <Button
                onClick={() => {
                  handleJoinStudy(joinableStudy.id)
                  setIsLoading(true)
                }}
                color="primary"
              >
                {`${t("Yes!")}`}
              </Button>
              <Button
                onClick={() => {
                  setDialogState(null)
                  setJoinableStudy(undefined)
                }}
                color="primary"
              >
                {`${t("No")}`}
              </Button>
            </>
          ),
        })
        break
      case "join":
        setViewState({
          title: t(`You've joined _. This is your unique passphrase.`, { name: joinableStudy.name }),
          content: (
            <Card
              style={{
                marginLeft: 50,
                marginRight: 50,
                marginTop: 15,
                padding: 18,
                fontSize: "large",
                boxShadow: "1px 2px 9px gray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                userSelect: "text",
              }}
            >
              {t(`${passphrase}`)}
            </Card>
          ),
          actions: (
            <>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(passphrase)
                }}
                color="primary"
              >
                {`${t("Copy passphrase")}`}
              </Button>
              <Button
                onClick={() => {
                  setDialogState(null)
                }}
                color="primary"
              >
                {`${t("Got it!")}`}
              </Button>
            </>
          ),
        })
        setIsLoading(false)
        break
      case "error":
        setViewState({
          title: t(`Something went wrong.`),
          content: null,
          actions: (
            <Button
              onClick={() => {
                setDialogState(null)
              }}
              color="primary"
            >
              {`${t("Close")}`}
            </Button>
          ),
        })
        setIsLoading(false)
        break
    }
  }, [dialogState])

  return (
    <Dialog
      open={!!dialogState}
      onClose={() => {
        setDialogState(null)
        setViewState({ title: null, content: null, actions: null })
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isLoading ? (
        <DialogContent>
          {/* TODO: What to show when loading? */}
          {t("Loading...")}
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: viewState.title }} />
            {viewState.content}
          </DialogContent>
          <DialogActions>{viewState.actions}</DialogActions>
        </>
      )}
    </Dialog>
  )
}
