import { Edge, Node } from "reactflow"
import { removeNode } from "./removeNode"
import { nodeTypeKeys } from "../data/node_type_keys"

/**
 * Change a node type to slot and remove all linked nodes if the node is an eval node or if the node has more than one linked edge.
 *
 * @param nodeId The id of the node to change.
 * @param nodes The current nodes in the diagram.
 * @param edges The current edges in the diagram.
 * @param setNodes The function to set the nodes.
 * @param setEdges The function to set the edges.
 *
 * @returns The node that was changed.
 */
export const changeNodeTypeToSlot = (
  nodeId: string,
  nodes: Node[],
  edges: Edge[],
  setNodes,
  setEdges
): Node | undefined => {
  const node = nodes.find((node) => node.id === nodeId)
  const linkedEdges = edges.filter((edge) => edge.source === nodeId)
  const linkedNodes = nodes.filter((node) => linkedEdges.map((edge) => edge.target).includes(node.id))

  if (!node) {
    return
  }

  // Find the node to Update, revert to slot type
  const newNodes = nodes.map((node) => {
    if (node.id !== nodeId) {
      return node
    }

    return { ...node, type: nodeTypeKeys.SLOT }
  })

  // Update the state
  setNodes(newNodes)

  // Remove all linked nodes if the node is an eval node or if the node has more than one linked edge
  if (node.type === nodeTypeKeys.EVAL || linkedEdges.length > 1) {
    linkedNodes.forEach((node) => removeNode(node.id, nodes, edges, setNodes, setEdges))
  }

  return node
}
