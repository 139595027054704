import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import ReactFlow, {
  Background,
  Node,
  ReactFlowProvider,
  addEdge,
  getConnectedEdges,
  useEdgesState,
  useNodesState,
} from "reactflow"
import "reactflow/dist/style.css"
import SPARK from "spark-core"
import questionTypes, { QUESTION_TYPE_KEY } from "../../../../data/questionTypes"
import { Activity } from "../../../../types/activity"
import { Question } from "../../../../types/question"
import { nodeTypeKeys } from "./nodes/data/node_type_keys"
import { NodeFactory } from "./nodes/factory/NodeFactory"
import { changeNodeTypeToSlot } from "./nodes/helpers/editNode"
import { fillSlotAsAction } from "./nodes/helpers/fillSlotAsActionNode"
import { fillSlotAsActivity } from "./nodes/helpers/fillSlotAsActivityNode"
import { fillSlotAsDataSource } from "./nodes/helpers/fillSlotAsDataSource"
import { fillSlotAsEval } from "./nodes/helpers/fillSlotAsEvalNode"
import { removeNode } from "./nodes/helpers/removeNode"
import ActionNode from "./nodes/nodes/ActionNode"
import ActivityNode from "./nodes/nodes/ActiveNode"
import DataSourceNode from "./nodes/nodes/DataSourceNode"
import EvalNode from "./nodes/nodes/EvalNode"
import InitialNode from "./nodes/nodes/InitialNode"
import SlotNode from "./nodes/nodes/SlotNode"
import { CustomNodeData } from "./nodes/types/CustomNodeData"

const nodeTypes = {
  [nodeTypeKeys.INITIAL]: InitialNode,
  [nodeTypeKeys.SLOT]: SlotNode,
  [nodeTypeKeys.EVAL]: EvalNode,
  [nodeTypeKeys.ACTIVITY]: ActivityNode,
  [nodeTypeKeys.ACTION]: ActionNode,
  [nodeTypeKeys.DATA_SOURCE]: DataSourceNode,
}

export default function DiagramBuilder({ projectId, trigger }: { projectId?: string; trigger?: any }) {
  const initialNodes: Node<CustomNodeData>[] = trigger.nodes ?? [
    NodeFactory.createNode(nodeTypeKeys.INITIAL, { id: "1", position: { x: 200, y: 50 } }),
    NodeFactory.createNode(nodeTypeKeys.SLOT, {
      id: "2",
      position: { x: 216, y: 180 },
      data: { onFillSlot: handleFillSlot },
    }),
  ]

  const initialEdges = trigger.edges ?? [{ id: "e1-2", type: "step", source: "1", target: "2", deletable: false }]

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const handleNodeEdit = (nodeId: string, type: string): Node | undefined => {
    // Revert node to default type
    const editedNode = changeNodeTypeToSlot(nodeId, nodes, edges, setNodes, setEdges)

    // Fill the previously reverted slot with the new type
    handleFillSlot(nodeId, type)

    return editedNode
  }

  // Update payload data
  const handleNodeChange = (nodeId, newValue) => {
    setNodes((nds) =>
      nds.map((node) => (node.id === nodeId ? { ...node, data: { ...node.data, payload: newValue } } : node))
    )
  }

  const handleNodeDelete = (node: Node) => {
    const slotNodeWidth = nodes.find((node) => node.type === nodeTypeKeys.SLOT)?.width

    // Replace the node to delete with a slot node
    const newNode = handleNodeEdit(node.id, nodeTypeKeys.SLOT)
    newNode.position.x = node.position.x + node.width / 2 - slotNodeWidth / 2

    const connectedEdges = getConnectedEdges([node], edges).filter((edge) => edge.source === node.id)
    const connectedNodes = connectedEdges.map((edge) => nodes.find((node) => node.id === edge.target))

    // Remove all connected nodes, the recursion in the function will take care of all the connected nodes
    connectedNodes.forEach((node) => removeNode(node.id, nodes, edges, setNodes, setEdges))
  }

  const handleNodesChange = (nds) => {
    const newNodes = nodes.map((node) => {
      node.data = {
        ...node.data,
        onChange: handleNodeChange,
        onEdit: (nodeType) => handleNodeEdit(node.id, nodeType),
        onDelete: () => handleNodeDelete(node),
      }

      if (node.type !== nodeTypeKeys.SLOT) {
        return node
      }

      return { ...node, data: { ...node.data, onFillSlot: handleFillSlot } }
    })

    setNodes(newNodes)
    onNodesChange(nds)

    saveSchema()
  }

  function saveSchema() {
    // save nodes and edges to trigger group in the backend
    trigger.nodes = nodes
    trigger.edges = edges
    updateTrigger(trigger)
  }

  const updateTrigger = async (trigger) => {
    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    try {
      const response = await fetch(`${baseUrl}/study/${projectId}/trigger_group`, {
        method: "Put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
        body: JSON.stringify(trigger),
      })
      const data = await response.json()
      // enqueueSnackbar(`${t("Successfully updated decision tree builder.")}`, {
      //   variant: "success",
      // })
    } catch (e) {
      console.dir(e)
    }
  }

  // Update node of type slot according to its new type
  function handleFillSlot(nodeId: string, type: string) {
    const node = nodes.find((node) => node.id === nodeId)

    if (!node) {
      return
    }

    switch (type) {
      case nodeTypeKeys.EVAL:
        var questionOptions: Question[] = []
        //get all surveys
        SPARK.Activity.allByStudy(projectId).then((activities) => {
          activities.forEach((activity) => {
            activity.settings.forEach((question) => {
              if (questionTypes[QUESTION_TYPE_KEY].fields.find((field) => field.id === question.question_type_id)) {
                questionOptions.push({
                  id: questionOptions.length,
                  human_id: question.human_id,
                  parent_id: activity.id,
                  type: question.type,
                  question_type_id: question.question_type_id,
                  text: activity.name + " : " + question.human_id,
                  description: question.description,
                  required: question.required,
                  conditional_logic: question.conditional_logic,
                  condition: question.condition,
                  options: question.options,
                })
              }
              if (question.children?.length > 0) {
                console.log(question.children)
                question.children.forEach((child) => {
                  if (questionTypes[QUESTION_TYPE_KEY].fields.find((field) => field.id === child.question_type_id)) {
                    questionOptions.push({
                      id: questionOptions.length,
                      human_id: child.human_id,
                      parent_id: activity.id,
                      type: child.type,
                      question_type_id: child.question_type_id,
                      text: activity.name + " : " + child.human_id,
                      description: child.description,
                      required: child.required,
                      conditional_logic: child.conditional_logic,
                      condition: child.condition,
                      options: child.options,
                    })
                  }
                })
              }
            })
          })
        })

        fillSlotAsEval(node, setNodes, setEdges, nodes, edges, questionOptions)
        break
      case nodeTypeKeys.ACTIVITY:
        var options: Activity[] = []
        //get all surveys
        SPARK.Activity.allByStudy(projectId).then((activities) => {
          activities.forEach((activity) => {
            if (activity["needs_trigger"]) {
              options.push({
                id: activity.id,
                name: activity.name,
              })
            }
          })
        })

        fillSlotAsActivity(node, setNodes, setEdges, nodes, edges, options)
        break
      case nodeTypeKeys.DATA_SOURCE:
        var questionOptions: Question[] = []
        //get all surveys
        SPARK.Activity.allByStudy(projectId).then((activities) => {
          activities.forEach((activity) => {
            activity.settings.forEach((question) => {
              questionOptions.push({
                id: questionOptions.length,
                human_id: question.human_id,
                parent_id: activity.id,
                type: question.type,
                question_type_id: question.question_type_id,
                text: activity.name + " : " + question.human_id,
                description: question.description,
                required: question.required,
                conditional_logic: question.conditional_logic,
                condition: question.condition,
                options: question.options,
              })
            })
          })
        })

        fillSlotAsDataSource(node, setNodes, setEdges, nodes, edges, questionOptions)
        break
      case nodeTypeKeys.ACTION:
        fillSlotAsAction(node, setNodes, setEdges, nodes, edges, [
          { id: "1", name: "Do nothing" },
          { id: "2", name: "Option 2" },
          // TODO: Add Options Dynamically
        ])
        break
      default:
        console.log("Not implemented")
        break
    }
  }

  // Collect all slot nodes without a onFillSlot callback, and add it
  React.useEffect(() => {
    const slotNodesWithoutCb = nodes.filter((node) => node.type === nodeTypeKeys.SLOT && !node.data?.onFillSlot)

    // Prevent infinite loop by checking if the slotNodesWithoutCb is not empty
    if (slotNodesWithoutCb?.length > 0) {
      const newNodes = nodes.map((node) => {
        if (node.type !== nodeTypeKeys.SLOT || node.data?.onFillSlot) {
          return node
        }

        return {
          ...node,
          data: {
            ...node.data,
            onFillSlot: handleFillSlot,
          },
        }
      })

      setNodes(newNodes)
    }
  }, [nodes])

  const onConnect = React.useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges])

  return (
    <ReactFlowProvider>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <Background />
      </ReactFlow>
    </ReactFlowProvider>
  )
}
