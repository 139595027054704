export function getUniqueElementId(prefix: string = "el-", suffix: string = ""): string {
  let id: string = ""

  do {
    id = prefix
    id += Math.random().toString(36).slice(2)
    id += suffix
  } while (document.getElementById(id))

  return id
}
