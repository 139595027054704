import React from "react"
import { Box } from "@material-ui/core"
import { Handle, Position } from "reactflow"
import OptionsToolbar, { TriggerButton } from "../OptionsToolbar"
import { Activity } from "../../../../../../types/activity"
import SlotField from "../SlotField"
import { Option } from "../SearchableSelect"
import { CustomNodeData } from "../types/CustomNodeData"

type ActivityNodeProps = {
  data: CustomNodeData<Activity>
  id: string
}

export default function ActivityNode(props: ActivityNodeProps) {
  const { data } = props
  const [displayToolbar, setDisplayToolbar] = React.useState(false)

  return (
    <>
      <Handle type="target" position={Position.Top} isConnectable={false} />
      <Box
        width={140}
        height={70}
        bgcolor="#ABEFC6"
        position="relative"
        top="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
        border={displayToolbar ? "1px #182230 dashed" : "1px transparent solid"}
        style={{ borderRadius: 4 }}
      >
        {displayToolbar && (
          <OptionsToolbar onEdit={data.onEdit} onDelete={data.onDelete} onCancel={() => setDisplayToolbar(false)} />
        )}
        <TriggerButton onClick={() => setDisplayToolbar((prev) => !prev)} />
        <Field activity={data.payload} activities={data.options} onChange={(val) => data.onChange(props.id, val)} />
      </Box>
      <Handle type="source" position={Position.Bottom} isConnectable={false} />
    </>
  )
}

function Field(props: { activity: Activity; activities: Activity[]; onChange?: (activity: Activity) => void }) {
  const { activity, activities, onChange } = props

  const options: Option[] = activities.map((option) => ({
    id: option.id as string,
    label: option.name,
  }))

  const handleChange = (option: Option) => {
    const selectedActivity = activities.find((activity) => activity.id === option.id)

    if (onChange && selectedActivity) {
      onChange(selectedActivity)
    }
  }

  return (
    <SlotField options={options} onChange={handleChange}>
      {activity?.name}
    </SlotField>
  )
}
