"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *
 */
var Study = /** @class */ (function () {
    function Study() {
    }
    return Study;
}());
exports.Study = Study;
