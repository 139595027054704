import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  InputBase,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import { useSnackbar } from "notistack"

// Local Imports
import SPARK from "spark-core"

import { useTranslation } from "react-i18next"

const _qrLink = (credID, password) =>
  window.location.href.split("#")[0] +
  "#/?a=" +
  btoa([credID, password, SPARK.Auth._auth.serverAddress].filter((x) => !!x).join(":"))

const CssTextField = withStyles({
  root: {
    "label + &": {},
  },
  input: {
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.75)",
  },
})(InputBase)

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: `4px 0 0 4px`,
        },
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 16,
      color: "white",
    },
    textField: {
      width: "100%",
      ["& fieldset"]: {
        borderRadius: `10px`,
      },
    },
    PopupButton: {
      marginLeft: 10,
      color: "#fff",
      backgroundColor: "#EE9142",
      border: "1px solid #E0E0E0",
      marginTop: 6.75,
      marginBottom: 40,
      borderRadius: "8px",
      width: 168,
      height: 50,
      "&:hover": { background: "#EE9142" },
    },
    popWidth: { width: "95%", maxWidth: "500px", padding: "0 40px" },
    dialogTitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.75)",
      marginTop: 30,
    },
    disabled: {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  })
)

export interface Trigger {
  id?: string
  name?: string
  description?: string
  conditions: any[]
  trigger: string
}
export default function TriggerDialog({
  trigger,
  projectId,
  type,
  triggers,
  ...props
}: {
  trigger?: Trigger
  projectId?: string
  type?: string
  triggers?: Array<any>
} & DialogProps) {
  const classes = useStyles()
  const [projectName, setProjectName] = useState("")
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [selectedTrigger, setSelectedTrigger] = useState(trigger ?? null)
  const [loading, setLoading] = useState(false)
  const [duplicateCnt, setDuplicateCnt] = useState(0)
  const [oldTriggerName, setOldTriggerName] = useState(trigger ? trigger.name : "")

  //const [sensors, setSensors] = useState([])
  const [activities, setActivities] = useState([])
  const [triggerName, setTriggerName] = useState(trigger ? trigger.name : "")
  const [description, setDescription] = useState("")
  //const [selectedSensor, setSelectedSensor] = useState(null)
  const [selectedActivityToTrigger, setSelectedActivityToTrigger] = useState(null)
  const [value, setValue] = useState(null)

  const sensorValues = {
    "spark.gps": ["longitude", "latitude", "radius"],
    "spark.steps": ["steps"],
  }

  useEffect(() => {
    //    SPARK.Sensor.allByStudy(projectId).then(setSensors)
    SPARK.Activity.allByStudy(projectId).then((activities) => {
      setActivities(activities.filter((a) => a.spec !== "group.survey.question"))
    })
  }, [])

  useEffect(() => {
    const resetFormState = () => {
      setSelectedTrigger(null)
      setTriggerName("")
      setDescription("")
      //      setSelectedSensor(null)
      setSelectedActivityToTrigger(null)
      setValue(null)
    }

    const updateFormState = (trigger) => {
      setSelectedTrigger(trigger ?? null)
      setTriggerName(trigger?.name || "")
      setDescription(trigger?.description || "")

      if (trigger) {
        // const sensor = sensors.find((s) => s.id === trigger.conditions[0]?.id)
        // if (sensor) {
        //   setSelectedSensor(sensor)
        setValue(trigger.conditions[0].value)
        //        }
      }

      setSelectedActivityToTrigger(trigger?.trigger || "")
    }

    type === "add" ? resetFormState() : updateFormState(trigger)
  }, [props.open])

  useEffect(() => {
    let duplicateCount = 0
    if (!(typeof triggerName === "undefined" || (typeof triggerName !== "undefined" && triggerName?.trim() === ""))) {
      if (triggers) {
        selectedTrigger
          ? (duplicateCount = triggers.filter((triggerData) => {
              if (triggerData.name !== oldTriggerName) {
                return triggerData.name?.trim().toLowerCase() === triggerName?.trim().toLowerCase()
              }
            }).length)
          : (duplicateCount = triggers.filter(
              (triggerData) => triggerData.name?.trim().toLowerCase() === triggerName?.trim().toLowerCase()
            ).length)
      }
      setDuplicateCnt(duplicateCount)
    }
  }, [triggerName])

  const validate = () => {
    return !(
      (duplicateCnt > 0 || typeof triggerName == "undefined" || triggerName === null || triggerName.trim() === "") //||
      //      typeof selectedSensor == "undefined" ||
      //      selectedSensor === null ||
      //      selectedSensor === "" ||
      // typeof selectedActivityToTrigger == "undefined" ||
      // selectedActivityToTrigger === null ||
      // selectedActivityToTrigger === "" ||
      // typeof value == "undefined" ||
      // value === null ||
      // value === "" //||
      // (selectedSensor?.spec === "spark.gps"
      //   ? !(!!value.longitude && !!value.latitude && !!value.radius)
      //   : !!!value.steps)
    )
  }

  const updateTrigger = async () => {
    setLoading(true)

    const triggerObj = {
      name: triggerName,
      description: description,
      conditions: [
        {
          // id: selectedSensor?.id,
          // item: selectedSensor?.spec,
          value: value,
        },
      ],
      trigger: selectedActivityToTrigger,
    }

    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    try {
      const response = await fetch(`${baseUrl}/trigger/${trigger.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
        body: JSON.stringify(triggerObj),
      })
      const data = await response.json()
      enqueueSnackbar(`${t("Successfully updated a trigger.")}`, {
        variant: "success",
      })
      setLoading(false)
    } catch (e) {
      console.dir(e)
      setLoading(false)
    }
  }

  // const setSensorValues = (x) => {
  //   if (selectedSensor?.spec === "spark.gps") {
  //     setValue({
  //       longitude: x.longitude ?? (!!value && !!value.longitude ? value.longitude : ""),
  //       latitude: x.latitude ?? (!!value && !!value.latitude ? value.latitude : ""),
  //       radius: x.radius ?? (!!value && !!value.radius ? value.radius : ""),
  //     })
  //   } else if (selectedSensor?.spec === "spark.steps") {
  //     setValue({
  //       steps: x.steps ?? (!!value && !!value.steps ? value.steps : ""),
  //     })
  //   }
  // }

  const saveTrigger = async () => {
    setLoading(true)

    const triggerObj = {
      name: triggerName,
      description: description,
      conditions: [
        {
          id: null,
          item: null,
          value: null,
        },
      ],
      trigger: null,
    }

    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    try {
      const response = await fetch(`${baseUrl}/study/${projectId}/trigger`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
        body: JSON.stringify(triggerObj),
      })
      const data = await response.json()
      enqueueSnackbar(`${t("Successfully created a decision tree builder.")}`, {
        variant: "success",
      })
      setLoading(false)
    } catch (e) {
      console.dir(e)
      setLoading(false)
    }
  }

  const saveTriggerGroup = async () => {
    setLoading(true)

    const triggerObj = {
      name: triggerName,
      description: description,
    }

    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    try {
      const response = await fetch(`${baseUrl}/study/${projectId}/trigger_group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
        body: JSON.stringify(triggerObj),
      })
      const data = await response.json()
      enqueueSnackbar(`${t("Successfully created a decision tree builder.")}`, {
        variant: "success",
      })
      setLoading(false)

      window.location.href =
        "/#/" + `researcher/${(SPARK.Auth._me as any)?.id}/project/${projectId}/trigger/${data.data}`
    } catch (e) {
      console.dir(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    SPARK.Study.view(projectId).then((project) => {
      setProjectName(project.name)
    })
  }, [])

  return (
    <Dialog {...props} classes={{ paper: classes.popWidth }} aria-labelledby="simple-dialog-title">
      <div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography className={classes.dialogTitle}>
          {selectedTrigger ? `${t("Update Trigger")}` : `${t("Let's start creating a decision tree builder")}`}
        </Typography>
        {/* <Box mt={4}>
          <TextField fullWidth variant="outlined" label={`${t("Study")}`} defaultValue={projectName} disabled={true} />
        </Box> */}
        <Box mt={4}>
          {t("Give your tree builder group a title")}
          <TextField
            error={duplicateCnt > 0 || typeof triggerName == "undefined"}
            value={triggerName}
            variant="outlined"
            onChange={(event) => setTriggerName(event.target.value)}
            placeholder={`${t("Decision tree builder title")}`}
            className={classes.textField}
            style={{
              marginTop: "10px",
            }}
            // helperText={
            //   duplicateCnt > 0
            //     ? `${t("Unique name required.")}`
            //     : typeof triggerName == "undefined" || triggerName === null || triggerName.trim() === ""
            //     ? `${t("Please enter name.")}`
            //     : ""
            // }
          />
        </Box>
        <Box mt={4}>
          {t("Description")}
          <TextField
            value={description}
            variant="outlined"
            onChange={(event) => setDescription(event.target.value)}
            placeholder={`${t("Enter a description")}`}
            className={classes.textField}
            style={{
              marginTop: "10px",
            }}
          />
        </Box>
        {/* <Box mt={4}>
          <TextField
            id="filled-select-currency"
            select
            label={`${t("Sensor")}`}
            value={selectedSensor}
            onChange={(e) => setSelectedSensor(e.target.value)}
            helperText={
              typeof selectedSensor == "undefined" || selectedSensor === null || selectedSensor === ""
                ? `${t("Please select the Sensor.")}`
                : ""
            }
          >
            {(sensors || []).map((option) => (
              <MenuItem key={option.id} value={option} data-selected-study-name={t(option.name)}>
                {t(option.name)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box mt={4}>
          <TextField
            id="filled-select-currency"
            select
            label={`${t("Activity to trigger")}`}
            value={selectedActivityToTrigger}
            onChange={(e) => setSelectedActivityToTrigger(e.target.value)}
            helperText={
              typeof selectedActivityToTrigger == "undefined" ||
              selectedActivityToTrigger === null ||
              selectedActivityToTrigger === ""
                ? `${t("Please select the Activity.")}`
                : ""
            }
          >
            {(activities || []).map((option) => (
              <MenuItem key={option.id} value={option.id} data-selected-study-name={t(option.name)}>
                {t(option.name)}
              </MenuItem>
            ))}
          </TextField>
        </Box> */}
        {/* {!!selectedSensor && sensorValues.hasOwnProperty(selectedSensor?.spec) && (
          <Box mt={4}>
            {sensorValues[selectedSensor?.spec].map((item, index) => (
              <TextField
                value={!!value && value.hasOwnProperty(item) ? value[item] : ""}
                variant="filled"
                label={`${t(item)}`}
                onChange={(e) => {
                  const value = {}
                  value[item] = e.target.value
                  setSensorValues(value)
                }}
                placeholder={`${t(item)}`}
                // helperText={!!value && (typeof value[item] == "undefined" || value[item] === null || value[item] === "")
                //   ? `Please enter a ${item} value.`
                //   : ""}
              />
            ))}
          </Box>
        )} */}
        <Box mt={4}></Box>
        <Box textAlign="right" mt={2}>
          <Button
            onClick={() => saveTriggerGroup()} //(selectedTrigger ? updateTrigger() : saveTrigger())}
            disabled={!validate()}
            className={!validate() ? classes.disabled + " " + classes.PopupButton : classes.PopupButton}
          >
            <Typography className={classes.buttonText}>
              {selectedTrigger ? `${t("Update")}` : `${t("Continue")}`}
            </Typography>
          </Button>
        </Box>
      </div>
    </Dialog>
  )
}
