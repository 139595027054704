import { Fab, Icon } from "@material-ui/core"
import { GridActionsCellItemProps, GridColDef, GridRowParams, useGridApiRef } from "@mui/x-data-grid"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import ConfirmationDialog from "../../ConfirmationDialog"

import TableComponent from "../../shared/TableComponent"

const buttonStyle = {
  background: "transparent",
  boxShadow: "none",
  color: "#475467",
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "id",
    minWidth: 200,
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
  },
  {
    field: "parent_organization",
    headerName: "Parent Organization",
    minWidth: 200,
  },
  {
    field: "actions",
    headerName: "",
    type: "actions",
    sortable: false,
    align: "right",
    getActions: (params: GridRowParams) => [
      <DeleteOrganizationActionItem
        label="Delete"
        icon={<Icon>delete_outline</Icon>}
        organization={params.row}
        setOrganizations={params.row.refreshData}
      />,
      <EditOrganizationActionItem label="Edit" icon={<Icon>edit</Icon>} organization={params.row} />,
    ],
  },
]

const deleteOrganization = async (id) => {
  // FIXME: do this with spark-js sdk
  let headers = new Headers()
  headers.set("Authorization", "Basic " + btoa(SPARK.Auth._auth.id + ":" + SPARK.Auth._auth.password))
  const url =
    (process.env.NODE_ENV === "production" ? "https" : "http") +
    "://" +
    (!!SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.lamp.digital") +
    `/organization/${id}`
  let res = await fetch(url, { method: "DELETE", headers: headers })
  return res.json()
}

function DeleteOrganizationActionItem({
  organization,
  setOrganizations,
}: GridActionsCellItemProps & { organization: any; setOrganizations: () => void }) {
  // TODO: This does nothing yet.
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [confirmationDialog, setConfirmationDialog] = useState(0)
  let handleConfirmAction = async (status) => {
    if (status === "Yes") {
      await deleteOrganization(organization.id)
      enqueueSnackbar(`${t("Successfully deleted the selected organization " + organization.name + ".")}`, {
        variant: "success",
      })
      setOrganizations()
    }
    setConfirmationDialog(0)
  }
  return (
    <React.Fragment>
      <Fab variant="round" size="small" style={buttonStyle} onClick={(event) => setConfirmationDialog(7)}>
        <Icon fontSize="medium">delete_outline</Icon>
      </Fab>
      <ConfirmationDialog
        open={confirmationDialog > 0 ? true : false}
        onClose={() => setConfirmationDialog(0)}
        confirmAction={handleConfirmAction}
        confirmationMsg={`${t("Are you sure you want to delete this Organization and its Researchers?")}`}
      />
    </React.Fragment>
  )
}

function EditOrganizationActionItem({ organization }: GridActionsCellItemProps & { organization: any }) {
  // TODO: This does nothing yet.
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [confirmationDialog, setConfirmationDialog] = useState(0)
  let editOrganization = async (status) => {
    if (status === "Yes") {
      enqueueSnackbar(`${t("Nothing happened. " + organization.name + " " + organization.id)}`, {
        variant: "success",
      })
    }
    setConfirmationDialog(0)
  }
  return (
    <React.Fragment>
      <Fab
        variant="round"
        size="small"
        style={{
          ...buttonStyle,
          marginRight: "18px",
        }}
        onClick={(event) => {
          event.stopPropagation()
          setConfirmationDialog(7)
        }}
      >
        <Icon fontSize="medium">edit</Icon>
      </Fab>
      <ConfirmationDialog
        open={confirmationDialog > 0 ? true : false}
        onClose={() => setConfirmationDialog(0)}
        confirmAction={editOrganization}
        confirmationMsg={`${"This does nothing yet."}`}
      />
    </React.Fragment>
  )
}

export default function OrganizationList({ organizations, setOrganizations }) {
  const apiRef = useGridApiRef()

  return (
    <TableComponent
      rows={organizations}
      refreshData={setOrganizations}
      columns={columns}
      autosizeColumns={["id", "name", "parent_organization", "actions"]}
    />
  )
}
