"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *
 */
var Researcher = /** @class */ (function () {
    function Researcher() {
    }
    return Researcher;
}());
exports.Researcher = Researcher;
