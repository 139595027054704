import { Box, Fab, Icon, MenuItem, Popover, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import AddUser from "./AddUser"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      background: theme.palette.primary.main,
      borderRadius: "8px",
      minWidth: 100,
      boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
      lineHeight: "38px",
      cursor: "pointer",
      textTransform: "capitalize",
      fontSize: "14px",
      color: "#fff",
      "& svg": { marginRight: 8 },
      "&:hover": { background: theme.palette.primary.main },
    },
    customPopover: { backgroundColor: "rgba(0, 0, 0, 0.4)" },
    customPaper: {
      maxWidth: 380,
      maxHeight: 600,
      marginTop: 75,
      marginLeft: 100,
      borderRadius: 10,
      padding: "10px 0",
      "& h6": { fontSize: 16 },
      "& li": {
        display: "inline-block",
        width: "100%",
        padding: "8px 30px",
        "&:hover": { backgroundColor: "#ECF4FF" },
      },
      "& *": { cursor: "pointer" },
    },
    popexpand: {
      backgroundColor: "#fff",
      color: theme.palette.primary.main,
      zIndex: 11111,
      "& path": { fill: theme.palette.primary.main },
      "&:hover": { backgroundColor: "#f3f3f3" },
    },
    addText: {
      padding: "0px 2px 0px 2px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
)

export default function AddButton({ projectId, setParticipants, mode, ...props }) {
  const [addUser, setAddUser] = useState(false)
  const { t } = useTranslation()
  const classes = useStyles()
  const [popover, setPopover] = useState(null)

  const handleClosePopUp = () => {
    setParticipants()
    setAddUser(false)
  }

  return (
    <Box>
      <Fab
        variant="extended"
        color="primary"
        classes={{ root: classes.btn + " " + (!!popover ? classes.popexpand : "") }}
        onClick={(event) => setPopover(event.currentTarget)}
      >
        <Icon>add</Icon> <span className={classes.addText}>{`${t("Add user")}`}</span>
      </Fab>

      <Popover
        classes={{ root: classes.customPopover, paper: classes.customPaper }}
        open={!!popover ? true : false}
        //anchorPosition={!!popover && popover.getBoundingClientRect()}
        anchorPosition={popover ? popover.getBoundingClientRect() : null}
        anchorReference="anchorPosition"
        onClose={() => setPopover(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            setPopover(null)
            setAddUser(true)
          }}
        >
          <Typography variant="h6">{`${t("Add a patient")}`}</Typography>
          <Typography variant="body2">{`${t("Create a new entry in this group.")}`}</Typography>
        </MenuItem>
      </Popover>
      <AddUser
        projectId={projectId}
        open={addUser}
        onClose={() => setAddUser(false)}
        setParticipants={setParticipants}
        closePopUp={handleClosePopUp}
      />
    </Box>
  )
}
