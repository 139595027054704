"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * The SensorSpec determines the parameters of generated SensorEvents.
 */
var SensorSpec = /** @class */ (function () {
    function SensorSpec() {
    }
    return SensorSpec;
}());
exports.SensorSpec = SensorSpec;
