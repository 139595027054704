import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import { Stack } from "@mui/material"
import dayjs from "dayjs"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import { EmptyIndicator } from "../../../shared/EmptyIcon"
import { validateTrigger } from "../ActivityList/ExpressionMethods"
import QuestionTypeIconBox from "../ActivityList/QuestionTypeIcon"
import TriggerSchedulerDialog from "../ActivityList/TriggerSchedulerDialog"
import ItemsStackLayout from "../Overview/ItemsStackLayout"
import DecisionTreeBuilder from "./DecisionTreeBuilder"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    researcherMenu: {
      background: "#ffffff",
      maxWidth: "100%",
      border: 0,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flexDirection: "row",
      },
      "& span": { fontSize: 12 },
      "& div.Mui-selected": {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        "& path": { fill: theme.palette.primary.main },
      },
    },
    logResearcher: {
      zIndex: 1111,
      [theme.breakpoints.up("md")]: {
        height: "50px",
        width: "100%",
        flexDirection: "row",
        marginTop: 51,
        marginBottom: 5,
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "50px",
        width: "100%",
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,
      },
      outline: "#D0D5DD solid 1px",
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    "@global": {
      body: {
        backgroundColor: "#fff",
      },
    },
    backbtnlink: {
      width: 48,
      height: 48,
      color: "rgba(0, 0, 0, 0.54)",
      padding: 12,
      borderRadius: "50%",
      "&:hover": { background: "rgba(0, 0, 0, 0.04)" },
    },
    toolbardashboard: {
      minHeight: 100,
      padding: "0 10px",
      "& h5": {
        color: "rgba(0, 0, 0, 0.75)",
        textAlign: "left",
        fontWeight: "600",
        fontSize: 30,
        width: "calc(100% - 96px)",
      },
    },
    dividerHeader: {
      marginTop: 0,
    },
  })
)

const toBinary = (string) => {
  const codeUnits = new Uint16Array(string.length)
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i)
  }
}
const defaultBase64 = toBinary("data:image/png;base64,")

export default function Trigger({
  id,
  type,
  projectId,
  researcherId,
  ...props
}: {
  id?: string
  type?: string
  projectId?: string
  researcherId?: string
}) {
  const [loading, setLoading] = useState(true)
  const supportsSidebar = useMediaQuery(useTheme().breakpoints.up("md"))
  const [trigger, setTrigger] = useState(null)
  const [study, setStudy] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()
  const [selectedActivityToTrigger, setSelectedActivityToTrigger] = useState(null)
  const [activities, setActivities] = useState([])
  const [triggers, setTriggers] = useState([])
  const [triggerName, setTriggerName] = useState(trigger ? trigger.name : "")
  const [duplicateCnt, setDuplicateCnt] = useState(0)
  const [selectedTrigger, setSelectedTrigger] = useState(trigger ?? null)
  const [oldTriggerName, setOldTriggerName] = useState(trigger ? trigger.name : "")
  const [triggerID, setTriggerID] = useState("")
  const [condition, setCondition] = useState("")
  const [editorMode, setEditorMode] = useState<"visual-builder" | "logical-expression">("visual-builder")
  const [triggerSchedulerDialog, setTriggerSchedulerDialog] = useState(false)
  const [options, setOptions] = useState([])

  const surveyNames = activities.map((activity) => {
    return activity.name
  }) // TODO: use human_id instead?

  useEffect(() => {
    //    SPARK.Sensor.allByStudy(projectId).then(setSensors)
    SPARK.Activity.allByStudy(projectId).then(setActivities)
  }, [])

  const extractHumanIds = (settings, prefix) => {
    return settings.flatMap((setting) => {
      if (setting.type === "group" && setting.children) {
        return extractHumanIds(setting.children, prefix) // Recursively extract human_ids from children
      } else {
        return {
          id: setting.id,
          human_id: `${prefix}.${setting.human_id}`,
          type: setting.type,
        }
      }
    })
  }

  useEffect(() => {
    const questionOptions = activities.flatMap((activity) => extractHumanIds(activity.settings, activity.name))
    questionOptions.push({
      //TODO: get this dynamically from back-end
      id: "spark.steps",
      human_id: "spark.steps",
      type: "sensor",
    })
    setOptions(questionOptions)
  }, [activities])

  useEffect(() => {
    let duplicateCount = 0
    if (!(typeof triggerName === "undefined" || (typeof triggerName !== "undefined" && triggerName?.trim() === ""))) {
      if (triggers) {
        selectedTrigger
          ? (duplicateCount = triggers.filter((triggerData) => {
              if (triggerData.name !== oldTriggerName) {
                return triggerData.name?.trim().toLowerCase() === triggerName?.trim().toLowerCase()
              }
            }).length)
          : (duplicateCount = triggers.filter(
              (triggerData) => triggerData.name?.trim().toLowerCase() === triggerName?.trim().toLowerCase()
            ).length)
      }
      setDuplicateCnt(duplicateCount)
    }
  }, [triggerName])

  const validate = () => {
    const validCondition = validateTrigger(condition, surveyNames, options)
    return !(
      duplicateCnt > 0 ||
      typeof triggerName == "undefined" ||
      triggerName === null ||
      triggerName.trim() === "" ||
      !validCondition
    )
  }

  const getAllTriggerGroups = async () => {
    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    try {
      const response = await fetch(`${baseUrl}/study/${projectId}/trigger_group`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
      })
      const data = await response.json()
      return data.data
    } catch (error) {
      throw error
    }
  }
  const editTrigger = async (trigger) => {
    setTriggerName(trigger.name)
    setCondition(trigger.conditions)
    setSelectedActivityToTrigger(trigger.trigger)
    setOldTriggerName(trigger.name)
    setSelectedTrigger(trigger)
    setTriggerID(trigger.id)
  }

  const deleteTrigger = async (triggerId) => {
    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    try {
      const response = await fetch(`${baseUrl}/trigger/${triggerId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
      })
      const data = await response.json()
      addOrUpdateTrigger()
      return data.data
    } catch (error) {
      throw error
    }
  }

  const getAllTriggersInGroup = async (groupId) => {
    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    try {
      const response = await fetch(`${baseUrl}/study/${projectId}/${groupId}/trigger`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
      })
      const data = await response.json()
      return data.data
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    setLoading(true)
    SPARK.Study.view(projectId).then((study) => {
      setStudy(study)
      getAllTriggerGroups().then((triggers) => {
        if (!!id) {
          let activity = triggers.filter((activity) => activity.id === id)
          if (!!activity && activity.length > 0) {
            activity = activity[0]
            activity.study_id = study.id
            setTrigger(activity)
            setLoading(false)
          }
        } else setLoading(false)
      })
      getAllTriggersInGroup(id).then((triggers) => {
        setTriggers(triggers)
      })
    })
  }, [])

  const addOrUpdateTrigger = async () => {
    getAllTriggersInGroup(id).then((triggers) => {
      setTriggers(triggers)
    })
    setTriggerName("")
    setCondition("")
    setSelectedActivityToTrigger(null)
    setOldTriggerName("")
    setTriggerID("")
  }

  const saveTrigger = async () => {
    setLoading(true)

    const triggerObj = {
      name: triggerName,
      conditions: condition,
      trigger: selectedActivityToTrigger,
      group: id,
    }

    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    try {
      const response = await fetch(`${baseUrl}/study/${projectId}/trigger`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
        body: JSON.stringify(triggerObj),
      })
      const data = await response.json()
      enqueueSnackbar(`${t("Successfully created a decision tree builder.")}`, {
        variant: "success",
      })
      setLoading(false)
      addOrUpdateTrigger()
    } catch (e) {
      console.dir(e)
      setLoading(false)
    }
  }

  const addOrUpdateTriggers = () => {
    //refreshTriggers()
    setTriggerSchedulerDialog(false)
  }

  const updateTrigger = async () => {
    setLoading(true)

    const triggerObj = {
      name: triggerName,
      conditions: condition,
      trigger: selectedActivityToTrigger,
      group: id,
    }

    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    try {
      const response = await fetch(`${baseUrl}/trigger/${selectedTrigger.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
        body: JSON.stringify(triggerObj),
      })
      const data = await response.json()
      enqueueSnackbar(`${t("Successfully updated a trigger.")}`, {
        variant: "success",
      })
      setLoading(false)
      addOrUpdateTrigger()
    } catch (e) {
      console.dir(e)
      setLoading(false)
    }
  }

  return (
    <Box pt={{ sm: "0px", md: "0px" }} pb={{ sm: "0px", md: "30px" }} width="100%" height="100%">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && !!study && (!!type || !!trigger) && (
        <Box height="100%" p={0}>
          <Drawer
            anchor={supportsSidebar ? "left" : "bottom"}
            variant="permanent"
            classes={{
              paper: classes.researcherMenu + " " + classes.logResearcher,
            }}
          >
            <p
              style={{
                fontSize: 18,
                marginLeft: 30,
                marginTop: 6.75,
                marginBottom: 6.75,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              onClick={() => {
                window.location.href = `/#/researcher/${researcherId}/project/${projectId}/triggers`
              }}
            >
              <u>{study.name}</u> /
            </p>
            <p
              style={{
                fontSize: 20,
                marginLeft: 6,
                marginTop: 6.75,
                marginBottom: 6.75,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                minWidth: 145,
              }}
            >
              <b>{trigger.name}</b>
            </p>

            {
              //TODO: check role?
              <Button
                variant={"text"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: editorMode === "visual-builder" ? "#F9FAFB" : "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                onClick={() => setEditorMode("visual-builder")}
              >
                {`${t("Visual Builder")}`}
              </Button>
            }
            {
              //TODO: check role?
              <Button
                variant={"contained"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: editorMode === "logical-expression" ? "#fafafa" : "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                onClick={() => setEditorMode("logical-expression")}
              >
                {`${t("Logical Expression")}`}
              </Button>
            }
            {
              //TODO: check role?
              <Button
                variant={"outlined"}
                disableElevation={false}
                disabled={true}
                style={{
                  marginLeft: "auto",
                  marginRight: 6,
                  height: 25,
                  marginTop: 12.5,
                  marginBottom: 12.5,
                  borderRadius: 15,
                  padding: "0px 10px",
                  fontSize: 14,
                  backgroundColor:
                    trigger.schedule &&
                    trigger.schedule.length > 0 &&
                    dayjs(trigger.schedule[0]?.start_date) <= dayjs(new Date()) &&
                    !trigger.schedule[0]?.end_date
                      ? "#ECFDF3"
                      : "#F9FAFB",
                  borderColor:
                    trigger.schedule &&
                    trigger.schedule.length > 0 &&
                    dayjs(trigger.schedule[0]?.start_date) <= dayjs(new Date()) &&
                    !trigger.schedule[0]?.end_date
                      ? "#ABEFC6"
                      : "#EAECF0",
                  outlineColor:
                    trigger.schedule &&
                    trigger.schedule.length > 0 &&
                    dayjs(trigger.schedule[0]?.start_date) <= dayjs(new Date()) &&
                    !trigger.schedule[0]?.end_date
                      ? "#ABEFC6"
                      : "#EAECF0",
                  color:
                    trigger.schedule &&
                    trigger.schedule.length > 0 &&
                    dayjs(trigger.schedule[0]?.start_date) <= dayjs(new Date()) &&
                    !trigger.schedule[0]?.end_date
                      ? "#067647"
                      : "#717BBC",
                  boxShadow: "0px 0px 0px 0px",
                }}
              >
                {!trigger.schedule || trigger.schedule.length <= 0
                  ? `${t("Concept")}`
                  : dayjs(trigger.schedule[0]?.start_date) <= dayjs(new Date()) && !trigger.schedule[0]?.end_date
                  ? `${t("Published")}`
                  : `${t("Scheduled")}`}
              </Button>
            }
            {/* {localStorage.getItem("mode") !== "other" && ( //TODO: check role?
              <Button
                variant="contained"
                tabIndex={-1}
                disableElevation={true}
                style={{
                  marginLeft: 6,
                  marginRight: 6,
                  color: "#fff",
                  backgroundColor: "#EE9142",
                  border: "1px solid #E0E0E0",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                  borderRadius: "8px",
                }}
                onClick={(event) => {
                  console.log("TODO: publish")
                }}
              >
                {`${t("Publish")}`}
              </Button>
            )} */}
            {localStorage.getItem("mode") !== "other" && ( //TODO: check role?
              <Button
                variant="contained"
                tabIndex={-1}
                disableElevation={true}
                style={{
                  marginLeft: 6,
                  marginRight: 35,
                  color: "#000",
                  backgroundColor: "#fff",
                  border: "1px solid #E0E0E0",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                  borderRadius: "8px",
                }}
                onClick={(event) => {
                  setTriggerSchedulerDialog(true)
                }}
              >
                {`${t("Schedule")}`}
              </Button>
            )}
          </Drawer>
          <TriggerSchedulerDialog
            projectId={projectId}
            onClose={() => setTriggerSchedulerDialog(false)}
            open={triggerSchedulerDialog}
            type=""
            addOrUpdateTrigger={addOrUpdateTriggers}
            closeDialog={() => setTriggerSchedulerDialog(false)}
            trigger={trigger}
            researcherId={researcherId}
          />
          <Divider className={classes.dividerHeader} />

          <Box mt={{ sm: 0, md: "-30px" }} height="100%">
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            {editorMode === "logical-expression" ? (
              <Box px="40px" pt="30px" height="100%">
                <Grid container spacing={4} style={{ height: "100%" }}>
                  <Grid item xs={12} md={3} style={{ padding: 0, height: "100%" }}>
                    <ItemsStackLayout title={t("Triggers")} onClickAddButton={() => console.log("Not implemented")}>
                      {triggers.length === 0 || triggers === null ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            paddingBlock: "50px",
                          }}
                        >
                          <EmptyIndicator
                            title={t("No triggers found")}
                            description={t(
                              "There are no triggers here yet. Create a new trigger and start your project."
                            )}
                            //action={{ label: t("New Triggers"), onClick: () => console.log("Not implemented") }}
                            width="100%"
                          />
                        </div>
                      ) : (
                        <ItemsStackLayout.Stack sortable={false} list={triggers} setList={setTriggers}>
                          {triggers.map((trigger, index) => {
                            // const questionType = getQuestionTypeById(question.question_type_id)

                            // if (!questionType) {
                            //   return null
                            // }

                            return (
                              <ItemsStackLayout.Item
                                key={index}
                                icon={
                                  <QuestionTypeIconBox
                                    backgroundColor="#D0D5DD"
                                    borderColor="#475467"
                                    icon=""
                                    text={String(index + 1)}
                                  />
                                }
                                label={trigger.name}
                                onEdit={() => editTrigger(trigger)} //console.log("edit" + trigger.id)}//onEditQuestion?.(question)}
                                onDelete={() => deleteTrigger(trigger.id)} //console.log("delete")}// onDeleteQuestion?.(question)}
                              />
                            )
                          })}
                        </ItemsStackLayout.Stack>

                        // triggers.map((trigger) => (
                        //   <Box
                        //     key={trigger.id}
                        //     style={{
                        //       display: "flex",
                        //       justifyContent: "space-between",
                        //       alignItems: "center",
                        //       padding: "10px",
                        //       borderBottom: "1px solid #E0E0E0",
                        //     }}
                        //   >
                        //     <div>
                        //       <Typography variant="h6">{trigger.name}</Typography>
                        //       <Typography variant="body1">{trigger.description}</Typography>
                        //     </div>
                        //     <div>
                        //       <Button
                        //         variant="outlined"
                        //         style={{ marginRight: 10 }}
                        //         onClick={(event) => {
                        //           console.log("TODO: edit trigger")
                        //         }}
                        //       >
                        //         {t("Edit")}
                        //       </Button>
                        //       <Button
                        //         variant="outlined"
                        //         style={{ marginRight: 10 }}
                        //         onClick={(event) => {
                        //           console.log("TODO: delete trigger")
                        //         }}
                        //       >
                        //         {t("Delete")}
                        //       </Button>
                        //     </div>
                        //   </Box>
                        // ))
                      )}
                    </ItemsStackLayout>
                  </Grid>

                  {/* Main content */}
                  <Grid item sm={6}>
                    <Paper elevation={4}>
                      <Box p={2}>
                        <Box>
                          <Typography variant="h5">{t("Creating a trigger")}</Typography>
                          <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.{" "}
                          </Typography>

                          <Stack spacing={2} mt={2}>
                            <Box>
                              {t("Trigger ID")}
                              <TextField
                                variant="outlined"
                                placeholder={t("Type your trigger ID here..")}
                                value={triggerID}
                                disabled={true}
                              />
                            </Box>

                            <Box>
                              {t("Trigger name")}
                              <TextField
                                value={triggerName}
                                variant="outlined"
                                placeholder={t("This is where the trigger name comes in")}
                                onChange={(event) => setTriggerName(event.target.value)}
                              />
                            </Box>

                            <Box>
                              {t("Write your conditional logic")}
                              <TextField
                                value={condition}
                                variant="outlined"
                                multiline
                                rows={6}
                                onChange={(event) => setCondition(event.target.value)}
                              />
                            </Box>

                            <Box display="flex" width="100%">
                              <Button
                                style={{
                                  marginLeft: "auto",
                                  backgroundColor: validate() ? "#FD8B28" : "rgba(0, 0, 0, 0.12)",
                                  color: "white",
                                }}
                                disabled={!validate()}
                                onClick={() => (selectedTrigger ? updateTrigger() : saveTrigger())}
                              >
                                {t(selectedTrigger ? "Update" : "Save")}
                              </Button>
                            </Box>
                          </Stack>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box height="100%">
                <Grid container spacing={4} style={{ height: "100%" }}>
                  <DecisionTreeBuilder projectId={projectId} trigger={trigger} />
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}
