import { Activity } from "../../../../../../types/activity"
import { NodeFactory } from "../factory/NodeFactory"
import { Edge, MarkerType, Node } from "reactflow"
import { calcNodePositionRelativeToParent } from "./calcNodePosition"
import { nodeTypeKeys } from "../data/node_type_keys"

/**
 * Fill a slot node with an activity node and a slot node.
 *
 * @param node The slot node to fill.
 * @param setNodes The function to set the nodes.
 * @param setEdges The function to set the edges.
 * @param nodes The current nodes in the diagram.
 * @param edges The current edges in the diagram.
 * @param options The options for the activity node.
 *
 * @returns void
 */
export const fillSlotAsActivity = (
  node: Node,
  setNodes,
  setEdges,
  nodes: Node[],
  edges: Edge[],
  options: Activity[]
): void => {
  const originalNode = node

  const activityNodeId = node.id
  const activityNode = NodeFactory.createNode(nodeTypeKeys.ACTIVITY, {
    id: activityNodeId,
    position: {
      x: originalNode.position.x,
      y: originalNode.position.y + 100,
    },
    height: 70,
    width: 140,
  })
  activityNode.data.options = options
  activityNode.position = calcNodePositionRelativeToParent(activityNode, originalNode)

  const nodeSlot = NodeFactory.createNode(nodeTypeKeys.SLOT, {
    id: `${activityNodeId}a`,
    position: {
      x: activityNode.position.x + originalNode.width - 4,
      y: activityNode.position.y + activityNode.height,
    },
    width: 48,
    height: 48,
  })
  nodeSlot.position = calcNodePositionRelativeToParent(nodeSlot, originalNode)
  nodeSlot.position.y += 100

  setNodes((nds) => nds.filter((node) => node.id !== activityNodeId).concat([activityNode, nodeSlot]))
  setEdges((eds) =>
    eds.concat([
      {
        id: `${activityNodeId}-${nodeSlot.id}`,
        type: "step",
        source: activityNodeId,
        target: nodeSlot.id,
        deletable: false,
        markerEnd: {
          type: MarkerType.Arrow,
          width: 30,
          height: 30,
        },
      },
    ])
  )
}
