import React, { HTMLProps } from "react"

export default React.forwardRef(function NodeTextField(
  props: HTMLProps<HTMLDivElement>,
  ref: React.Ref<HTMLDivElement>
) {
  const { style = {}, children, ...restProps } = props

  return (
    <div
      ref={ref}
      style={{
        borderRadius: "10px",
        backgroundColor: "white",
        position: "relative",
        border: "2px lightgray solid",
        padding: "2px 4px",
        width: "fit-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
      {...restProps}
    >
      <div>{children}</div>
    </div>
  )
})
