import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import React, { useState } from "react"

import SPARK, { Participant } from "spark-core"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    activityContent: {
      padding: "0px 25px 0",
    },
    addNewDialog: {
      maxWidth: 350,
      [theme.breakpoints.up("sm")]: {
        maxWidth: "auto",
        minWidth: 400,
      },
    },
  })
)

// TODO: improve UI styling according to Figma
export default function AddUser({
  projectId,
  setParticipants,
  closePopUp,
  ...props
}: {
  projectId: string
  setParticipants?: Function
  closePopUp: Function
} & DialogProps) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")

  const handleUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }
  const reset = () => {
    setUsername("")
    setEmail("")
  }

  const validate = () => {
    return typeof username !== "undefined" && username?.trim() !== ""
  }

  const addParticipant = () => {
    let participant = new Participant()
    if (email.trim().length > 0) {
      participant.email = email
    }

    SPARK.Participant.create(projectId, participant)
      .then((res) => {
        enqueueSnackbar(`${t("Successfully created a Participant.")}`, {
          variant: "success",
        })
        reset()
        closePopUp(1)
      })
      .catch((e) => {
        console.dir(e)
      })
  }

  return (
    <React.Fragment>
      <Dialog
        {...props}
        scroll="paper"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        classes={{ paper: classes.addNewDialog }}
      >
        <DialogTitle id="alert-dialog-slide-title" disableTypography>
          <Typography variant="h2" style={{ fontSize: "30px", fontWeight: "600" }}>{`${t("Add a user")}`}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose as any}>
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={false} classes={{ root: classes.activityContent }}>
          <Box mt={2} mb={3}>
            <TextField
              fullWidth
              variant="outlined"
              label={`${t("Username")}`}
              value={username}
              onChange={handleUsernameChange}
            />
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            label={`${t("Email (optional)")}`}
            value={email}
            onChange={handleEmailChange}
          />
        </DialogContent>
        <DialogActions>
          <Box textAlign="right" width={1} mt={3} mb={3} mx={3}>
            <Button
              color="primary"
              onClick={() => {
                reset()
                closePopUp()
              }}
            >
              {`${t("Cancel")}`}
            </Button>
            <Button onClick={() => addParticipant()} color="primary" autoFocus disabled={!validate()}>
              {`${t("Save")}`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
