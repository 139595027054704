import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  InputBase,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import { useSnackbar } from "notistack"

// Local Imports
import SPARK from "spark-core"

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useTranslation } from "react-i18next"

import dayjs from "dayjs"
import schema from "../../../../adaptable/theme.json"
import { updateSchedule } from "./ActivityMethods"

const _qrLink = (credID, password) =>
  window.location.href.split("#")[0] +
  "#/?a=" +
  btoa([credID, password, SPARK.Auth._auth.serverAddress].filter((x) => !!x).join(":"))

const CssTextField = withStyles({
  root: {
    "label + &": {},
  },
  input: {
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.75)",
  },
})(InputBase)

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: `4px 0 0 4px`,
        },
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 16,
      color: "white",
    },
    textField: {
      width: "100%",
      ["& fieldset"]: {
        borderRadius: `10px`,
      },
    },
    PopupButton: {
      marginLeft: 10,
      color: "#fff",
      backgroundColor: "#EE9142",
      border: "1px solid #E0E0E0",
      marginTop: 6.75,
      marginBottom: 40,
      borderRadius: "8px",
      width: 168,
      height: 50,
      "&:hover": { background: "#EE9142" },
    },
    popWidth: { width: "95%", maxWidth: "500px", padding: "0 40px" },
    dialogTitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.75)",
      marginTop: 30,
    },
    disabled: {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  })
)

export default function ActivitySchedulerDialog({
  activity,
  projectId,
  type,
  addOrUpdateActivity,
  closeDialog,
  closeActivity,
  researcherId,
  ...props
}: {
  activity?: any
  projectId?: string
  type?: string
  addOrUpdateActivity?: Function
  closeDialog?: Function
  closeActivity?: Function
  researcherId?: string
} & DialogProps) {
  const classes = useStyles()
  const [projectName, setProjectName] = useState("")
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [selectedSchedule, setSelectedSchedule] = useState(activity.schedule ?? null)
  const [loading, setLoading] = useState(false)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [repeatInterval, setRepeatInterval] = useState("none")

  const [hasEndDate, setHasEndDate] = useState(false)
  const [beeped, setBeeped] = useState(false)
  const [minBeepInterval, setMinBeepInterval] = useState(0)
  const [maxBeepInterval, setMaxBeepInterval] = useState(0)
  const [beepCount, setBeepCount] = useState(0)
  const [timeForQuestion, setTimeForQuestion] = useState(0)
  const [monday, setMonday] = useState(false)
  const [tuesday, setTuesday] = useState(false)
  const [wednesday, setWednesday] = useState(false)
  const [thursday, setThursday] = useState(false)
  const [friday, setFriday] = useState(false)
  const [saturday, setSaturday] = useState(false)
  const [sunday, setSunday] = useState(false)
  const [weeksPostAdmission, setWeeksPostAdmission] = useState(0)

  useEffect(() => {
    if (activity.schedule) {
      if (activity.schedule.length > 0) {
        setStartDate(dayjs(activity.schedule[0].start_date))
        if (activity.schedule[0].end_date) {
          setHasEndDate(true)
          setEndDate(dayjs(activity.schedule[0].end_date))
          setEndTime(activity.schedule[0].end_time)
        } else {
          setHasEndDate(false)
          setEndDate(null)
          setEndTime(null)
        }
        setBeeped(activity.schedule[0].beeped)
        if (beeped) {
          setMonday(activity.schedule[0].monday)
          setTuesday(activity.schedule[0].tuesday)
          setWednesday(activity.schedule[0].wednesday)
          setThursday(activity.schedule[0].thursday)
          setFriday(activity.schedule[0].friday)
          setSaturday(activity.schedule[0].saturday)
          setSunday(activity.schedule[0].sunday)
          setEndTime(activity.schedule[0].end_time)
          setMinBeepInterval(activity.schedule[0].min_beep_interval)
          setMaxBeepInterval(activity.schedule[0].max_beep_interval)
          setBeepCount(activity.schedule[0].beep_count)
          setTimeForQuestion(activity.schedule[0].time_for_question)
          setWeeksPostAdmission(activity.schedule[0].weeks_post_admission)
        }
        setStartTime(activity.schedule[0].time)
        setRepeatInterval(activity.schedule[0].repeat_interval)
        setSelectedSchedule(activity.schedule[0])
        return
      }
    }
    setStartDate(null)
    setEndDate(null)
    setStartTime(null)
    setEndTime(null)
    setRepeatInterval("none")
    setSelectedSchedule(null)
  }, [props.open])

  const validate = () => {
    return !(
      (!beeped &&
        (startDate === null ||
          startTime === null ||
          (hasEndDate &&
            (endDate === null ||
              endTime === null ||
              endDate < startDate ||
              (endDate.date() === startDate.date() && endTime < startTime))))) ||
      (beeped &&
        (startTime === null ||
          endTime === null ||
          minBeepInterval === null ||
          maxBeepInterval === null ||
          beepCount === null ||
          timeForQuestion === null ||
          weeksPostAdmission === null ||
          minBeepInterval > maxBeepInterval ||
          minBeepInterval < 0 ||
          maxBeepInterval < 0 ||
          beepCount < 0 ||
          timeForQuestion < 0 ||
          weeksPostAdmission < 0 ||
          endTime < startTime))
    )
  }

  const updateActivitySchedule = async () => {
    setLoading(true)
    if (beeped) {
      activity.schedule = [
        {
          repeat_interval: repeatInterval,
          beeped: beeped,
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          saturday: saturday,
          sunday: sunday,
          time: startTime,
          end_time: endTime,
          min_beep_interval: minBeepInterval,
          max_beep_interval: maxBeepInterval,
          beep_count: beepCount,
          time_for_question: timeForQuestion,
          weeks_post_admission: weeksPostAdmission,
        },
      ]
    } else if (startDate !== null && startTime !== null) {
      if (hasEndDate && endDate !== null && endTime !== null) {
        activity.schedule = [
          {
            start_date: startDate,
            time: startTime,
            end_date: endDate,
            end_time: endTime,
            repeat_interval: repeatInterval,
          },
        ]
      } else {
        activity.schedule = [
          {
            start_date: startDate,
            time: startTime,
            repeat_interval: repeatInterval,
          },
        ]
      }
    }

    activity["needs_trigger"] = false
    activity["is_hidden"] = false
    console.log("activity", activity)

    updateSchedule(activity)
    setLoading(false)
  }

  useEffect(() => {
    SPARK.Study.view(projectId).then((project) => {
      setProjectName(project.name)
    })
  }, [])

  return (
    <Dialog {...props} classes={{ paper: classes.popWidth }} aria-labelledby="simple-dialog-title">
      <div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography className={classes.dialogTitle}>{`${t("Schedule this survey")}`}</Typography>
        <Box mt={4} style={{ width: "100%", alignItems: "center" }}>
          <div style={{ width: "50%", display: "inline-block" }}>
            <Typography>{`${t("Beeped")}`}</Typography>
          </div>
          <div style={{ width: "50%", display: "inline-block" }}>
            <Switch
              checked={beeped}
              onChange={(event) => setBeeped(event.target.checked)}
              name="checkedC"
              inputProps={{ "aria-label": "secondary checkbox" }}
              style={{
                marginLeft: "auto",
                marginRight: "0px",
              }}
            />
          </div>
        </Box>
        {!beeped && (
          <Box mt={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: "50%", display: "inline-block" }}>
                {t("Date start")}
                <DatePicker
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  slotProps={{
                    textField: {
                      sx: {
                        // borderRadius: "10px",
                        // borderColor: "#e91e63",
                        // border: "1px solid",
                        selectedColor: schema.palette.primary.main,
                        marginTop: "10px",
                      },
                    },
                    field: {
                      sx: {
                        // borderRadius: "10px",
                        // borderColor: "#e91e63",
                        // border: "1px solid",
                        selectedColor: schema.palette.primary.main,
                      },
                    },
                    layout: {
                      sx: {
                        ".MuiPickersToolbar-root": {
                          color: "#1565c0",
                          borderRadius: "12px",
                          borderWidth: "1px",
                          borderColor: "#2196f3",
                          border: "1px solid",
                          backgroundColor: "#90caf9",
                        },
                        input: schema.palette.primary.main,
                        borderRadius: 20,
                        width: "100%",
                        color: schema.palette.primary.main,
                        borderColor: schema.palette.primary.main,
                        accentColor: schema.palette.primary.main,
                        hoverColor: schema.palette.primary.main,
                        highlightColor: schema.palette.primary.main,
                        selectedColor: schema.palette.primary.main,
                        colorActive: schema.palette.primary.main,
                        colorSelected: schema.palette.primary.main,
                        colorDisabled: schema.palette.primary.main,
                        colorToday: schema.palette.primary.main,
                        "& input": {
                          color: schema.palette.primary.main,
                        },
                        "& .MuiPickersDay-root.Mui-selected": {
                          backgroundColor: schema.palette.primary.main,
                        },
                        "& .MuiPickersDay-root.Mui-select": {
                          backgroundColor: schema.palette.primary.main,
                        },
                        "& .MuiPickersYear-yearButton.Mui-selected": {
                          backgroundColor: schema.palette.primary.main,
                        },
                      },
                    },
                  }}
                />
              </div>
              <div style={{ width: "50%", display: "inline-block" }}>
                {t("Time")}
                <TextField
                  value={startTime}
                  variant="outlined"
                  type="time"
                  onChange={(event) => setStartTime(event.target.value)}
                  placeholder={`${t("Enter a time")}`}
                  className={classes.textField}
                  style={{
                    marginTop: "10px",
                  }}
                />
              </div>
            </LocalizationProvider>
          </Box>
        )}
        {beeped && (
          <Box mt={4} justifyContent="center" textAlign="center">
            <div style={{ width: "12%", display: "inline-block", marginLeft: "2px", marginRight: "2px" }}>
              <Button
                onClick={() => {
                  setMonday(!monday)
                }}
                style={{
                  color: monday ? "#fff" : "#000",
                  backgroundColor: monday ? "#EE9142" : "#E0E0E0",
                  border: "1px solid #EE9142",
                  borderRadius: "25px",
                  width: "100%",
                  height: 50,
                  minWidth: "30px",
                }}
              >
                {`${t("Mon")}`}
              </Button>
            </div>
            <div style={{ width: "12%", display: "inline-block", marginLeft: "2px", marginRight: "2px" }}>
              <Button
                onClick={() => {
                  setTuesday(!tuesday)
                }}
                style={{
                  color: tuesday ? "#fff" : "#000",
                  backgroundColor: tuesday ? "#EE9142" : "#E0E0E0",
                  border: "1px solid #EE9142",
                  borderRadius: "25px",
                  width: "100%",
                  height: 50,
                  minWidth: "30px",
                }}
              >
                {`${t("Tue")}`}
              </Button>
            </div>
            <div style={{ width: "12%", display: "inline-block", marginLeft: "2px", marginRight: "2px" }}>
              <Button
                onClick={() => {
                  setWednesday(!wednesday)
                }}
                style={{
                  color: wednesday ? "#fff" : "#000",
                  backgroundColor: wednesday ? "#EE9142" : "#E0E0E0",
                  border: "1px solid #EE9142",
                  borderRadius: "25px",
                  width: "100%",
                  height: 50,
                  minWidth: "30px",
                }}
              >
                {`${t("Wed")}`}
              </Button>
            </div>
            <div style={{ width: "12%", display: "inline-block", marginLeft: "2px", marginRight: "2px" }}>
              <Button
                onClick={() => {
                  setThursday(!thursday)
                }}
                style={{
                  color: thursday ? "#fff" : "#000",
                  backgroundColor: thursday ? "#EE9142" : "#E0E0E0",
                  border: "1px solid #EE9142",
                  borderRadius: "25px",
                  width: "100%",
                  height: 50,
                  minWidth: "30px",
                }}
              >
                {`${t("Thu")}`}
              </Button>
            </div>
            <div style={{ width: "12%", display: "inline-block", marginLeft: "2px", marginRight: "2px" }}>
              <Button
                onClick={() => {
                  setFriday(!friday)
                }}
                style={{
                  color: friday ? "#fff" : "#000",
                  backgroundColor: friday ? "#EE9142" : "#E0E0E0",
                  border: "1px solid #EE9142",
                  borderRadius: "25px",
                  width: "100%",
                  height: 50,
                  minWidth: "30px",
                }}
              >
                {`${t("Fri")}`}
              </Button>
            </div>
            <div style={{ width: "12%", display: "inline-block", marginLeft: "2px", marginRight: "2px" }}>
              <Button
                onClick={() => {
                  setSaturday(!saturday)
                }}
                style={{
                  color: saturday ? "#fff" : "#000",
                  backgroundColor: saturday ? "#EE9142" : "#E0E0E0",
                  border: "1px solid #EE9142",
                  borderRadius: "25px",
                  width: "100%",
                  height: 50,
                  minWidth: "30px",
                }}
              >
                {`${t("Sat")}`}
              </Button>
            </div>
            <div style={{ width: "12%", display: "inline-block", marginLeft: "2px", marginRight: "2px" }}>
              <Button
                onClick={() => {
                  setSunday(!sunday)
                }}
                style={{
                  color: sunday ? "#fff" : "#000",
                  backgroundColor: sunday ? "#EE9142" : "#E0E0E0",
                  border: "1px solid #EE9142",
                  borderRadius: "25px",
                  width: "100%",
                  height: 50,
                  minWidth: "30px",
                }}
              >
                {`${t("Sun")}`}
              </Button>
            </div>
          </Box>
        )}
        {beeped && (
          <Box mt={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: "50%", display: "inline-block" }}>
                <Typography>{t("Start time")}</Typography>
                <TextField
                  value={startTime}
                  variant="outlined"
                  type="time"
                  onChange={(event) => setStartTime(event.target.value)}
                  placeholder={`${t("Enter a time")}`}
                  className={classes.textField}
                  style={{
                    marginTop: "10px",
                  }}
                />
              </div>
              <div style={{ width: "50%", display: "inline-block" }}>
                <Typography>{t("End time")}</Typography>
                <TextField
                  value={endTime}
                  variant="outlined"
                  type="time"
                  onChange={(event) => setEndTime(event.target.value)}
                  placeholder={`${t("Enter a time")}`}
                  className={classes.textField}
                  style={{
                    marginTop: "10px",
                  }}
                />
              </div>
            </LocalizationProvider>
          </Box>
        )}
        {beeped && (
          <Box mt={4}>
            <div style={{ width: "50%", display: "inline-block" }}>
              <Typography>{`${t("Min beep interval")}`}</Typography>
              <TextField
                value={minBeepInterval}
                variant="outlined"
                type="number"
                onChange={(event) => setMinBeepInterval(Number.parseInt(event.target.value))}
                placeholder={`${t("Amount of minutes")}`}
                className={classes.textField}
                style={{
                  marginTop: "10px",
                }}
              />
            </div>
            <div style={{ width: "50%", display: "inline-block" }}>
              <Typography>{`${t("Max beep interval")}`}</Typography>
              <TextField
                value={maxBeepInterval}
                variant="outlined"
                type="number"
                onChange={(event) => setMaxBeepInterval(Number.parseInt(event.target.value))}
                placeholder={`${t("Amount of minutes")}`}
                className={classes.textField}
                style={{
                  marginTop: "10px",
                }}
              />
            </div>
          </Box>
        )}
        {beeped && (
          //beep count and time for question set to be available
          <Box mt={4}>
            <div style={{ width: "50%", display: "inline-block" }}>
              <Typography>{`${t("Beep count")}`}</Typography>
              <TextField
                value={beepCount}
                variant="outlined"
                type="number"
                onChange={(event) => setBeepCount(Number.parseInt(event.target.value))}
                placeholder={`${t("Amount of beeps")}`}
                className={classes.textField}
                style={{
                  marginTop: "10px",
                }}
              />
            </div>
            <div style={{ width: "50%", display: "inline-block" }}>
              <Typography>{`${t("Time for question")}`}</Typography>
              <TextField
                value={timeForQuestion}
                variant="outlined"
                type="number"
                onChange={(event) => setTimeForQuestion(Number.parseInt(event.target.value))}
                placeholder={`${t("Amount of minutes")}`}
                className={classes.textField}
                style={{
                  marginTop: "10px",
                }}
              />
            </div>
          </Box>
        )}
        {beeped && (
          <Box mt={4}>
            <div style={{ width: "50%", display: "inline-block" }}>
              <Typography>{`${t("Start weeks after admission date")}`}</Typography>
            </div>
            <div style={{ width: "50%", display: "inline-flex" }}>
              <TextField
                value={weeksPostAdmission}
                variant="outlined"
                type="number"
                onChange={(event) => setWeeksPostAdmission(Number.parseInt(event.target.value))}
                placeholder={`${t("Amount of weeks")}`}
                className={classes.textField}
                style={{
                  marginTop: "0px",
                  alignItems: "top",
                }}
              />
            </div>
          </Box>
        )}
        {!beeped && (
          <Box mt={4} style={{ width: "100%", alignItems: "center" }}>
            {`${t("Add an end date")}`}
            <Switch
              checked={hasEndDate}
              onChange={(event) => setHasEndDate(event.target.checked)}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
              style={{
                marginLeft: "auto",
                marginRight: "0px",
              }}
            />
            <Typography></Typography>
          </Box>
        )}
        {hasEndDate && !beeped && (
          <Box mt={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: "50%", display: "inline-block" }}>
                {t("Date end")}
                <DatePicker
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  slotProps={{
                    textField: {
                      sx: {
                        // borderRadius: "10px",
                        // borderColor: "#e91e63",
                        // border: "1px solid",
                        selectedColor: schema.palette.primary.main,
                        marginTop: "10px",
                      },
                    },
                    field: {
                      sx: {
                        // borderRadius: "10px",
                        // borderColor: "#e91e63",
                        // border: "1px solid",
                        selectedColor: schema.palette.primary.main,
                      },
                    },
                    layout: {
                      sx: {
                        ".MuiPickersToolbar-root": {
                          color: "#1565c0",
                          borderRadius: "12px",
                          borderWidth: "1px",
                          borderColor: "#2196f3",
                          border: "1px solid",
                          backgroundColor: "#90caf9",
                        },
                        input: schema.palette.primary.main,
                        borderRadius: 20,
                        width: "100%",
                        color: schema.palette.primary.main,
                        borderColor: schema.palette.primary.main,
                        accentColor: schema.palette.primary.main,
                        hoverColor: schema.palette.primary.main,
                        highlightColor: schema.palette.primary.main,
                        selectedColor: schema.palette.primary.main,
                        colorActive: schema.palette.primary.main,
                        colorSelected: schema.palette.primary.main,
                        colorDisabled: schema.palette.primary.main,
                        colorToday: schema.palette.primary.main,
                        "& input": {
                          color: schema.palette.primary.main,
                        },
                        "& .MuiPickersDay-root.Mui-selected": {
                          backgroundColor: schema.palette.primary.main,
                        },
                        "& .MuiPickersDay-root.Mui-select": {
                          backgroundColor: schema.palette.primary.main,
                        },
                        "& .MuiPickersYear-yearButton.Mui-selected": {
                          backgroundColor: schema.palette.primary.main,
                        },
                      },
                    },
                  }}
                />
              </div>
              <div style={{ width: "50%", display: "inline-block" }}>
                {t("Time")}
                <TextField
                  value={endTime}
                  variant="outlined"
                  type="time"
                  onChange={(event) => setEndTime(event.target.value)}
                  placeholder={`${t("Enter a time")}`}
                  className={classes.textField}
                  style={{
                    marginTop: "10px",
                  }}
                />
              </div>
            </LocalizationProvider>
          </Box>
        )}
        {!beeped && (
          <Box mt={4}>
            {`${t("Repeat")}`}
            <Select
              value={repeatInterval}
              variant="outlined"
              onChange={(event) => setRepeatInterval(event.target.value.toString())}
              style={{
                marginTop: "10px",
                width: "100%",
              }}
            >
              <MenuItem value="none">{`${t("None")}`}</MenuItem>
              <MenuItem value="daily">{`${t("Every day")}`}</MenuItem>
              <MenuItem value="weekly">{`${t("Every week")}`}</MenuItem>
              <MenuItem value="monthly">{`${t("Every month")}`}</MenuItem>
              <MenuItem value="yearly">{`${t("Every year")}`}</MenuItem>
            </Select>
          </Box>
        )}
        <Box textAlign="right" mt={2}>
          <Button
            onClick={() => {
              updateActivitySchedule()
              closeDialog()
              closeActivity()
            }}
            disabled={!validate()}
            className={!validate() ? classes.disabled + " " + classes.PopupButton : classes.PopupButton}
          >
            <Typography className={classes.buttonText}>
              {selectedSchedule ? `${t("Update")}` : `${t("Continue")}`}
            </Typography>
          </Button>
        </Box>
      </div>
    </Dialog>
  )
}
