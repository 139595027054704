import { NodeFactory } from "../factory/NodeFactory"
import { Edge, MarkerType, Node } from "reactflow"
import { calcNodePositionRelativeToParent } from "./calcNodePosition"
import { nodeTypeKeys } from "../data/node_type_keys"
import { Question } from "../../../../../../types/question"

/**
 * Fill a slot node with a data source node and a slot node.
 *
 * @param node The slot node to fill.
 * @param setNodes The function to set the nodes.
 * @param setEdges The function to set the edges.
 * @param nodes The current nodes in the diagram.
 * @param edges The current edges in the diagram.
 * @param options The options for the data source node.
 *
 * @returns void
 */
export const fillSlotAsDataSource = (
  node: Node,
  setNodes,
  setEdges,
  nodes: Node[],
  edges: Edge[],
  options: Question[]
) => {
  const originalNode = node
  const dataSourceNodeId = node.id
  const dataSourceNode = NodeFactory.createNode(nodeTypeKeys.DATA_SOURCE, {
    id: dataSourceNodeId,
    position: { x: originalNode.position.x, y: originalNode.position.y + 100 },
    width: 201,
    height: 149,
  })
  dataSourceNode.data.options = options
  dataSourceNode.position = calcNodePositionRelativeToParent(dataSourceNode, originalNode)

  const nodeSlot = NodeFactory.createNode(nodeTypeKeys.SLOT, {
    id: `${dataSourceNodeId}a`,
    position: {
      x: dataSourceNode.position.x + dataSourceNode.width / 2 - originalNode.width / 2,
      y: dataSourceNode.position.y + dataSourceNode.height + 100,
    },
    width: 48,
    height: 48,
  })
  nodeSlot.position = calcNodePositionRelativeToParent(nodeSlot, dataSourceNode)
  nodeSlot.position.y += 100
  nodeSlot.position.x += 0

  setNodes((nds) => nds.filter((node) => node.id !== dataSourceNodeId).concat([dataSourceNode, nodeSlot]))
  setEdges((eds) =>
    eds.concat([
      {
        id: `${dataSourceNodeId}-${nodeSlot.id}`,
        type: "step",
        source: dataSourceNodeId,
        target: nodeSlot.id,
        deletable: false,
        markerEnd: {
          type: MarkerType.Arrow,
          width: 30,
          height: 30,
        },
      },
    ])
  )
}
