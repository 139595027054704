import React from "react"
import { Handle, Position } from "reactflow"
import NodeTextField from "../NodeTextField"
import { useTranslation } from "react-i18next"

export default function InitialNode(props) {
  const { t } = useTranslation()

  return (
    <>
      <NodeTextField>
        <div style={{ padding: "4px 18px" }}>{t("Start")}</div>
      </NodeTextField>
      <Handle type="source" position={Position.Bottom} isConnectable={false} />
    </>
  )
}
