import { DataGrid } from "@mui/x-data-grid"
import React from "react"

export default function OrganizationList({ rows, refreshData, columns, autosizeColumns, ...props }) {
  const _rows = rows.map((element) => {
    return {
      ...element,
      refreshData: refreshData,
    }
  })

  return (
    <DataGrid
      apiRef={props.apiRef}
      rows={_rows}
      columns={columns}
      sx={{
        marginTop: "24px",
        borderRadius: "12px",
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: "#F9FAFB",
        },
        "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& :where(.MuiToolbar-root, .MuiDataGrid-footerContainer, .MuiDataGrid-cell)": {
          backgroundColor: "#ffffff",
        },
      }}
      rowHeight={72}
      checkboxSelection
      autosizeOnMount={true}
      autosizeOptions={{
        columns: autosizeColumns,
        expand: true,
      }}
      disableColumnSelector
      disableColumnMenu
      disableColumnResize
      disableRowSelectionOnClick
    />
  )
}
