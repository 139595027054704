import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Drawer,
  InputBase,
  Paper,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { Add, Search } from "@material-ui/icons"
import { IconButton } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import i18n from "../../i18n"
import { ResponsivePaper } from "../Utils"
import DataPortal from "../data_portal/DataPortal"
import PatientProjectCreator from "./ProjectView/ParticipantList/PatientProjectCreator"
import ProjectsList from "./Projects/Index"
import HeaderButtons from "./Users/HeaderButtons"
import UserList from "./Users/UserList"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    researcherMenu: {
      background: "#ffffff",
      maxWidth: "100%",
      border: 0,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flexDirection: "row",
      },
      "& span": { fontSize: 12 },
      "& div.Mui-selected": {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        "& path": { fill: theme.palette.primary.main },
      },
    },
    menuItems: {
      display: "inline-block",
      textAlign: "left",
      color: "rgba(0, 0, 0, 0.4)",
      paddingTop: 10,
      paddingBottom: 30,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 16,
        paddingBottom: 9,
      },
      [theme.breakpoints.down("xs")]: {
        padding: 6,
      },
    },
    menuIcon: {
      minHeight: "auto",
      [theme.breakpoints.down("xs")]: {
        top: 5,
        position: "relative",
      },
      "& path": { fill: "rgba(0, 0, 0, 0.4)", fillOpacity: 0.7 },
    },
    tableContainerWidth: {
      maxHeight: 1055,
      height: "80%",
      [theme.breakpoints.down("md")]: {
        padding: 0,
      },
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    tableContainerWidthPad: {
      maxWidth: 1055,
      paddingLeft: 0,
      paddingRight: 0,
    },
    tableContainerDataPortalWidth: {
      height: "calc(100vw - 100px)",
      width: "calc(100vh - 50px)",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxHeight: "100vw",
      maxWidth: "100vh",
      top: "0px",
      left: "100px",
      overflow: "scroll",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        left: "0px",
        height: "100vw",
        width: "calc(100vh - 150px)",
      },
    },
    dataPortalPaper: {
      height: "100%",
    },
    logResearcher: {
      zIndex: 1111,
      [theme.breakpoints.up("md")]: {
        height: "50px",
        width: "100%",
        flexDirection: "row",
        marginTop: 51,
      },
      [theme.breakpoints.down("sm")]: {
        height: "50px",
        width: "100%",
        flexDirection: "row",
        marginBottom: 0,
      },
      outline: "#D0D5DD solid 1px",
    },
    btnCursor: {
      "&:hover div": {
        cursor: "pointer !important",
      },
      "&:hover div > svg": {
        cursor: "pointer !important",
      },
      "&:hover div > svg > path": {
        cursor: "pointer !important",
      },
      "&:hover div > span": {
        cursor: "pointer !important",
      },
    },
  })
)
const sortProjects = (projects, order) => {
  return projects.sort((a, b) => {
    return !!order
      ? a["name"] > b["name"]
        ? 1
        : a["name"] < b["name"]
        ? -1
        : 0
      : a["name"] < b["name"]
      ? 1
      : a["name"] > b["name"]
      ? -1
      : 0
  })
}

export const sortData = (data, projects, key) => {
  let result = []
  ;(projects || []).map((project) => {
    let filteredData = data.filter((d) => d.study_name === project)
    if (projects.includes("hidden")) {
      filteredData = filteredData.concat(filteredData.filter((d) => d.spec == "spark.survey.hidden"))
    } else {
      filteredData = filteredData.filter((d) => d.spec != "spark.survey.hidden")
    }
    filteredData.sort((a, b) => {
      return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0
    })
    result = result.concat(filteredData)
  })
  return [...new Map(result.map((item) => [item["id"], item])).values()]
}

export default function Dashboard({ researcherId, mode, tab, role, authType, ...props }) {
  const [projects, setProjects] = useState(null)
  const [users, setUsers] = useState(null)
  const supportsSidebar = useMediaQuery(useTheme().breakpoints.up("md"))
  const [updatedData, setUpdatedData] = useState(null)
  const [deletedData, setDeletedData] = useState(null)
  const [newProject, setNewProject] = useState(null)
  const [search, setSearch] = useState("")
  const [researcher, setResearcher] = useState(null)
  const [order, setOrder] = useState(localStorage.getItem("order") ? JSON.parse(localStorage.getItem("order")) : true)
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [addParticipantProject, setAddParticipantProject] = useState(false)

  useEffect(() => {
    SPARK.Researcher.view(researcherId).then(setResearcher)
  }, [])

  useEffect(() => {
    refreshUsers()
  }, [researcher])

  useEffect(() => {
    getAllProjects()
    if (localStorage.getItem("lang")) {
      i18n.changeLanguage(JSON.parse(localStorage.getItem("lang")))
    } else if (researcher?.organization) {
      // FIXME: FIXME: do this with spark-core sdk
      let headers = new Headers()
      headers.set("Authorization", "Basic " + btoa(SPARK.Auth._auth.id + ":" + SPARK.Auth._auth.password))
      const url =
        (process.env.NODE_ENV === "production" ? "https" : "http") +
        "://" +
        (!!SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.lamp.digital") +
        "/organization/" +
        researcher.organization
      fetch(url, { method: "GET", headers: headers })
        .then((response) => response.json())
        .then((body) => {
          let data = body.data
          i18n.changeLanguage(data.defaultLanguage)
        })
    }
  }, [researcher])

  useEffect(() => {
    if (!!newProject) getAllProjects()
  }, [newProject])

  useEffect(() => {
    if (updatedData !== null) getAllProjects()
  }, [updatedData])

  useEffect(() => {
    if (deletedData !== null) getAllProjects()
  }, [deletedData])

  const getDBProjects = async () => {
    if (!!researcher) {
      // Check this professional's (organization) role, and fetch projects accordingly.
      if (researcher.role === "admin") {
        // FIXME: FIXME: do this with spark-core sdk
        let headers = new Headers()
        headers.set("Authorization", "Basic " + btoa(SPARK.Auth._auth.id + ":" + SPARK.Auth._auth.password))
        const url =
          (process.env.REACT_APP_ENV === "production" ? "https" : "http") +
          "://" +
          process.env.REACT_APP_API_URL +
          "/organization" +
          `/${researcher.organization}/study`
        fetch(url, { method: "GET", headers: headers })
          .then((response) => response.json())
          .then((body) => {
            let data = body.data
            setProjects(sortProjects(data, order))
            setLoading(false)
          })
      } else {
        // FIXME: FIXME: do this with spark-core sdk
        let headers = new Headers()
        headers.set("Authorization", "Basic " + btoa(SPARK.Auth._auth.id + ":" + SPARK.Auth._auth.password))
        const url =
          (process.env.REACT_APP_ENV === "production" ? "https" : "http") +
          "://" +
          process.env.REACT_APP_API_URL +
          "/researcher" +
          `/${researcher.id}/study`
        fetch(url, { method: "GET", headers: headers })
          .then((response) => response.json())
          .then((body) => {
            let data = body.data
            setProjects(sortProjects(data, order))
            setLoading(false)
          })
      }
    }
  }

  const getAllProjects = async () => {
    getDBProjects()
    // TODO: what does this even do?
    // Service.getAll("studies").then((studies) => {
    //   console.log("getAllStudies", studies)
    //   setStudies(sortStudies(studies, order))
    // })
  }

  useEffect(() => {
    localStorage.setItem("order", JSON.stringify(order))
    getAllProjects()
  }, [order])

  const fetchUsersPerOrganization = async (organizationId) => {
    let headers = new Headers()
    headers.set("Authorization", "Basic " + btoa(SPARK.Auth._auth.id + ":" + SPARK.Auth._auth.password))
    const url =
      (process.env.NODE_ENV === "production" ? "https" : "http") +
      "://" +
      (!!SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.lamp.digital") +
      "/organization/" +
      organizationId +
      "/members"
    return fetch(url, { method: "GET", headers: headers })
  }

  const refreshUsers = () => {
    setUsers([])

    if (researcher?.organization) {
      fetchUsersPerOrganization(researcher.organization)
        .then((response) => response.json())
        .then((body) => {
          let data = body.data

          data = data.map((element) => {
            // TODO: fill this with actual projects this user has access to
            return {
              ...element,
              projects: ["project"],
            }
          })

          if (search.trim().length > 0) {
            data = data.filter((user) => user.name?.toLowerCase()?.includes(search?.toLowerCase()))
            setUsers(data)
          } else {
            setUsers(data)
          }
        })
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ResponsivePaper className={tab === "portal" ? classes.dataPortalPaper : null} elevation={0}>
          <Drawer
            anchor={supportsSidebar ? "left" : "bottom"}
            variant="permanent"
            classes={{
              paper: classes.researcherMenu + " " + classes.logResearcher,
            }}
            style={{ msFlexDirection: "row", flexDirection: "row" }}
          >
            <span
              style={{
                fontSize: 20,
                marginLeft: 30,
                marginTop: 6.75,
                marginBottom: 6.75,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: 145,
              }}
            >
              <b>
                {tab === "portal"
                  ? `${t("Data Analytics")}`
                  : tab === "users"
                  ? `${t("Users")}`
                  : tab === "projects"
                  ? `${t("Studies")}`
                  : `${t(tab)}`}
              </b>
            </span>
            {(localStorage.getItem("mode") === "simple" || localStorage.getItem("mode") === "advanced") && ( //TODO: check role?
              <Button
                variant={tab === "projects" || tab === "users" ? "contained" : "text"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: tab === "projects" || tab === "users" ? "#fafafa" : "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                onClick={(event) => {
                  if (tab === "projects" || tab === "portal")
                    window.location.href = `/#/researcher/${researcherId}/projects`
                }}
              >
                {`${t("Overview")}`}
              </Button>
            )}
            {(tab === "projects" || tab === "portal") && localStorage.getItem("mode") === "advanced" && (
              <Button
                variant={tab === "portal" ? "contained" : "text"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: tab === "portal" ? "#fafafa" : "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                onClick={(event) => (window.location.href = `/#/researcher/${researcherId}/portal`)}
              >
                {"Data Analytics"}
              </Button>
            )}
            {tab === "projects" && (
              <>
                <Paper
                  component="form"
                  style={{
                    padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    maxWidth: 320,
                    marginLeft: "auto",
                    marginRight: 10,
                    marginTop: 6.75,
                    marginBottom: 6.75,
                    borderRadius: "8px",
                  }}
                >
                  <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                    <Search />
                  </IconButton>
                  <InputBase
                    style={{ flex: 1, marginLeft: 1 }}
                    placeholder="Search "
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </Paper>
                {/* <Button
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadOutlined />}
                  disableElevation={true}
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    color: "#000",
                    backgroundColor: "#fff",
                    border: "1px solid #E0E0E0",
                    marginTop: 6.75,
                    marginBottom: 6.75,
                    borderRadius: "8px",
                  }}
                  onClick={(event) => {
                    console.log("TODO: import")
                  }}
                >
                  {`${t("Import")}`}
                </Button> */}
                <Button
                  style={{
                    marginLeft: 10,
                    marginRight: 35,
                    color: "#fff",
                    backgroundColor: "#EE9142",
                    border: "1px solid #E0E0E0",
                    marginTop: 6.75,
                    marginBottom: 6.75,
                    borderRadius: "8px",
                  }}
                  startIcon={<Add />}
                  onClick={() => {
                    setAddParticipantProject(true)
                  }}
                >
                  {`${t("Add Project")}`}
                </Button>
              </>
            )}

            {tab === "users" && (
              <HeaderButtons
                users={users}
                refreshUsers={refreshUsers}
                organizationId={researcher ? researcher.organization : null}
              />
            )}
          </Drawer>
          {tab === "users" && <UserList users={users} setUsers={refreshUsers} />}
          {tab === "projects" && (
            <ProjectsList
              title={null}
              researcherId={researcherId}
              projects={projects}
              updatedDataProject={(data) => setUpdatedData(data)}
              deletedDataProject={(data) => setDeletedData(data)}
              searchData={(data) => setSearch(data)}
              newAddedProject={setNewProject}
              getAllProjects={getAllProjects}
              organizationId={researcher ? researcher.organization : null}
              role={role}
            />
          )}
          {tab === "portal" && (
            <DataPortal
              onLogout={null}
              token={{
                username: SPARK.Auth._auth.id,
                password: SPARK.Auth._auth.password,
                server: SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.lamp.digital",
                type: "Researcher",
                id: researcher.id,
                name: researcher.name,
              }}
              data={SPARK.Auth}
            />
          )}
        </ResponsivePaper>
      </Container>
      <PatientProjectCreator // TODO: Further update this once more info is available
        projects={projects}
        researcherId={researcherId}
        onClose={() => setAddParticipantProject(false)}
        open={addParticipantProject}
        handleNewProject={getAllProjects}
        closePopUp={() => setAddParticipantProject(false)}
        organizationId={researcher ? researcher.organization : null}
      />
    </>
  )
}
