import { Question } from "../../../../../../types/question"
import { NodeFactory } from "../factory/NodeFactory"
import { Edge, MarkerType, Node } from "reactflow"
import { calcNodePositionRelativeToParent } from "./calcNodePosition"
import { nodeTypeKeys } from "../data/node_type_keys"

/**
 * Fill a slot node with an eval node and two slot nodes.
 *
 * @param node The slot node to fill.
 * @param setNodes The function to set the nodes.
 * @param setEdges The function to set the edges.
 * @param nodes The current nodes in the diagram.
 * @param edges The current edges in the diagram.
 * @param options The options for the eval node.
 *
 * @returns void
 */
export const fillSlotAsEval = (
  node: Node,
  setNodes,
  setEdges,
  nodes: Node[],
  edges: Edge[],
  options: Question[]
): void => {
  const originalNode = node

  const evalNodeId = node.id
  const nodeSlotTrueId = `${evalNodeId}a`
  const nodeSlotFalseId = `${evalNodeId}b`

  const isFirstEvalNode = nodes.filter((node) => node.type === nodeTypeKeys.EVAL).length === 0

  // Setup eval node
  const evalNode = NodeFactory.createNode(nodeTypeKeys.EVAL, {
    id: evalNodeId,
    position: { x: originalNode.position.x, y: originalNode.position.y + 100 },
    height: 140,
    width: 280,
  })
  evalNode.data.options = options
  evalNode.position = calcNodePositionRelativeToParent(evalNode, originalNode)

  // Setup slot nodes
  const nodeSlotTrue = NodeFactory.createNode(nodeTypeKeys.SLOT, {
    id: nodeSlotTrueId,
    position: {
      x: evalNode.position.x - (isFirstEvalNode ? 300 : 90),
      y: evalNode.position.y + evalNode.height + 50,
    },
  })

  const nodeSlotFalse = NodeFactory.createNode(nodeTypeKeys.SLOT, {
    id: nodeSlotFalseId,
    position: {
      x: evalNode.position.x + evalNode.width + (isFirstEvalNode ? 300 : 90 - 20),
      y: evalNode.position.y + evalNode.height + 50,
    },
  })

  setNodes((nds) => nds.filter((node) => node.id !== evalNodeId).concat([evalNode, nodeSlotTrue, nodeSlotFalse]))

  setEdges((eds) =>
    eds.concat([
      {
        id: `${evalNodeId}-${nodeSlotTrueId}`,
        source: evalNodeId,
        target: nodeSlotTrueId,
        deletable: false,
        label: "true",
        type: "step",
        sourceHandle: "eval-handle-l",
        markerEnd: {
          type: MarkerType.Arrow,
          width: 30,
          height: 30,
        },
      },
      {
        id: `${evalNodeId}-${nodeSlotFalseId}`,
        source: evalNodeId,
        target: nodeSlotFalseId,
        label: "false",
        type: "step",
        sourceHandle: "eval-handle-r",
        deletable: false,
        markerEnd: {
          type: MarkerType.Arrow,
          width: 30,
          height: 30,
        },
      },
    ])
  )
}
