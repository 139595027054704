import { Fab, Icon } from "@material-ui/core"
import { DataGrid, GridActionsCellItemProps, GridColDef, GridRowParams, useGridApiRef } from "@mui/x-data-grid"
import SPARK, { Participant } from "spark-core"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ConfirmationDialog from "../../../ConfirmationDialog"

const buttonStyle = {
  background: "transparent",
  boxShadow: "none",
  color: "#475467",
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Name",
    minWidth: 200,
  },
  {
    field: "actions",
    headerName: "",
    type: "actions",
    sortable: false,
    align: "right",
    getActions: (params: GridRowParams) => [
      <DeleteParticipantActionItem
        label="Delete"
        icon={<Icon>delete_outline</Icon>}
        participant={params.row}
        setParticipants={params.row.setParticipants}
      />,
      <EditParticipantActionItem label="Edit" icon={<Icon>edit</Icon>} participantId={params.row} />,
    ],
  },
]

function DeleteParticipantActionItem({
  participant,
  setParticipants,
  ...props
}: GridActionsCellItemProps & { participant: Participant; setParticipants: () => void }) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [confirmationDialog, setConfirmationDialog] = useState(0)
  let deleteParticipant = async (status) => {
    if (status === "Yes") {
      SPARK.Credential.list(participant.id).then((cred) => {
        cred = cred.filter((c) => c.hasOwnProperty("origin"))
        cred.map(async (each) => {
          await SPARK.Credential.delete(participant.id, each["access_key"])
        })
      })
      await SPARK.Participant.delete(participant.id)
      enqueueSnackbar(`${t("Successfully deleted the selected participants." + participant.id)}`, {
        variant: "success",
      })
    }
    setParticipants()
    setConfirmationDialog(0)
  }
  return (
    <React.Fragment>
      <Fab variant="round" size="small" style={buttonStyle} onClick={(event) => setConfirmationDialog(7)}>
        <Icon fontSize="medium">delete_outline</Icon>
      </Fab>
      <ConfirmationDialog
        open={confirmationDialog > 0 ? true : false}
        onClose={() => setConfirmationDialog(0)}
        confirmAction={deleteParticipant}
        confirmationMsg={`${t("Are you sure you want to delete this Participant?")}`}
      />
    </React.Fragment>
  )
}

function EditParticipantActionItem({ participantId, ...props }: GridActionsCellItemProps & { participantId: String }) {
  // TODO: This does nothing yet.
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [confirmationDialog, setConfirmationDialog] = useState(0)
  let editParticipants = async (status) => {
    if (status === "Yes") {
      enqueueSnackbar(`${t("Nothing happened." + participantId)}`, {
        variant: "success",
      })
    }
    setConfirmationDialog(0)
  }
  return (
    <React.Fragment>
      <Fab
        variant="round"
        size="small"
        style={{
          ...buttonStyle,
          marginRight: "18px",
        }}
        onClick={(event) => {
          event.stopPropagation()
          setConfirmationDialog(7)
        }}
      >
        <Icon fontSize="medium">edit</Icon>
      </Fab>
      <ConfirmationDialog
        open={confirmationDialog > 0 ? true : false}
        onClose={() => setConfirmationDialog(0)}
        confirmAction={editParticipants}
        confirmationMsg={`${"This does nothing yet."}`}
      />
    </React.Fragment>
  )
}

export default function ParticipantsTable({ participants, setParticipants }) {
  const apiRef = useGridApiRef()

  const rows = participants.map((element) => {
    return {
      ...element,
      setParticipants: setParticipants,
    }
  })

  return (
    <DataGrid
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      sx={{
        marginTop: "24px",
        borderRadius: "12px",
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: "#F9FAFB",
        },
        "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& :where(.MuiToolbar-root, .MuiDataGrid-footerContainer, .MuiDataGrid-cell)": {
          backgroundColor: "#ffffff",
        },
      }}
      rowHeight={72}
      checkboxSelection
      autosizeOnMount={true}
      autosizeOptions={{
        columns: ["id", "actions"],
        expand: true,
      }}
      disableColumnSelector
      disableColumnMenu
      disableColumnResize
      disableRowSelectionOnClick
    />
  )
}
