// Core Imports
import { Box } from "@material-ui/core"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import locale_lang from "../../../locale_map.json"
import Header from "./Header"
import OrganizationList from "./OrganizationList"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: theme.palette.background.default,
    },
  })
)
export default function Organizations({ history, updateStore, adminType, ...props }) {
  const [organizations, setOrganizations] = useState([])
  const [search, setSearch] = useState("")
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  const getSelectedLanguage = () => {
    const matched_codes = Object.keys(locale_lang).filter((code) => code.startsWith(navigator.language))
    const lang = matched_codes.length > 0 ? matched_codes[0] : "en-US"
    return i18n.language ? i18n.language : lang ? lang : "en-US"
  }

  useEffect(() => {
    refreshOrganizations()
  }, [])

  const refreshOrganizations = () => {
    setOrganizations([])

    // FIXME: FIXME: do this with spark-core sdk
    let headers = new Headers()
    headers.set("Authorization", "Basic " + btoa(SPARK.Auth._auth.id + ":" + SPARK.Auth._auth.password))
    const url =
      (process.env.NODE_ENV === "production" ? "https" : "http") +
      "://" +
      (!!SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.lamp.digital") +
      "/organization"
    fetch(url, { method: "GET", headers: headers })
      .then((response) => response.json())
      .then((body) => {
        let data = body.data
        if (search.trim().length > 0) {
          data = data.filter((organization) => organization.name?.toLowerCase()?.includes(search?.toLowerCase()))
          setOrganizations(data)
        } else {
          setOrganizations(data)
        }
        setOrganizations(data)
      })
  }

  useEffect(() => {
    refreshOrganizations()
  }, [search])

  useEffect(() => {
    let authId = SPARK.Auth._auth.id
    let language = !!localStorage.getItem("SPARK_user_" + authId)
      ? JSON.parse(localStorage.getItem("SPARK_user_" + authId)).language
      : getSelectedLanguage()
      ? getSelectedLanguage()
      : "en-US"
    i18n.changeLanguage(language)
  }, [])

  return (
    <Box className={classes.container}>
      <Header
        organizations={organizations}
        searchData={(data) => setSearch(data)}
        refreshOrganizations={refreshOrganizations}
        adminType={adminType}
      />
      <OrganizationList organizations={organizations} setOrganizations={refreshOrganizations} />
    </Box>
  )
}
