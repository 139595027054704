import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Icon,
  MenuItem,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK, { Researcher } from "spark-core"
import locale_role from "../../../role_map.json"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      "& h5": {
        fontSize: "30px",
        fontWeight: "bold",
      },
    },
    optionsMain: {
      background: "#ECF4FF",
      borderTop: "1px solid #C7C7C7",
      marginTop: 20,
      width: "99.4vw",
      position: "relative",
      left: "50%",
      right: "50%",
      marginLeft: "-50vw",
      marginRight: "-50vw",
    },
    optionsSub: { width: 1030, maxWidth: "80%", margin: "0 auto", padding: "10px 0" },
    btnWhite: {
      background: "#fff",
      borderRadius: "40px",
      boxShadow: "none",
      cursor: "pointer",
      textTransform: "capitalize",
      fontSize: "14px",
      color: theme.palette.primary.main,
      "& svg": { marginRight: 8 },
      "&:hover": {
        color: theme.palette.primary.main,
        background: "#fff",
        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
      },
    },
    btnBlue: {
      background: theme.palette.primary.main,
      borderRadius: "40px",
      minWidth: 100,
      boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
      lineHeight: "38px",
      cursor: "pointer",
      textTransform: "capitalize",
      fontSize: "16px",
      color: "#fff",
      "& svg": { marginRight: 8 },
      "&:hover": { background: theme.palette.primary.main },
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: 0,
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "auto",
      },
    },
  })
)
export default function AddUpdateResearcher({
  researcher,
  researchers,
  refreshResearchers,
  setName,
  updateStore,
  ...props
}: {
  researcher?: any
  researchers?: any
  refreshResearchers?: Function
  setName?: Function
  updateStore?: Function
}) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [name, setResearcherName] = useState(!!researcher ? researcher.name : "")
  const [role, setResearcherRole] = useState(!!researcher ? researcher.role : "")
  const [organization, setResearcherOrganization] = useState(!!researcher ? researcher.organization : "")
  const [rData, setRdara] = useState(researcher)
  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    refreshOrganizations()
  }, [])

  const refreshOrganizations = () => {
    setOrganizations([])

    // FIXME: FIXME: do this with spark-core sdk
    let headers = new Headers()
    headers.set("Authorization", "Basic " + btoa(SPARK.Auth._auth.id + ":" + SPARK.Auth._auth.password))
    const url =
      (process.env.NODE_ENV === "production" ? "https" : "http") +
      "://" +
      (!!SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.lamp.digital") +
      "/organization"
    fetch(url, { method: "GET", headers: headers })
      .then((response) => response.json())
      .then((body) => {
        let data = body.data
        setOrganizations(data)
      })
  }

  const addResearcher = async () => {
    let duplicates = researchers.filter((x) =>
      !!researcher
        ? x.name?.toLowerCase() === name?.trim().toLowerCase() && x.id !== researcher?.id
        : x.name?.toLowerCase() === name?.trim().toLowerCase()
    )
    if (duplicates.length > 0) {
      enqueueSnackbar(`${t("Investigator with same name already exist.")}`, {
        variant: "error",
      })
      setResearcherName(!!researcher ? researcher.name : "")
    } else {
      const researcherObj = new Researcher()
      researcherObj.name = name.trim()
      // FIXME: do this instead
      // const researcherObj = {
      //   name: name,
      //   organization: "", // TODO: add organization_id here
      //   role: "admin" // TODO: add role here
      // }
      if (
        !!researcher
          ? ((await SPARK.Researcher.update(researcher.id, researcherObj)) as any).error === undefined
          : ((await SPARK.Researcher.create(researcherObj)) as any).error === undefined
      ) {
        if (!!researcher) {
          updateStore(researcher.id)
          setName(name.trim())
          setRdara({ ...rData, name: name.trim() })
        } else {
          setResearcherName("")
          refreshResearchers()
        }
        enqueueSnackbar(
          !!researcher
            ? `${t("Successfully updated a new investigator.")}`
            : `${t("Successfully created a new investigator.")}`,
          {
            variant: "success",
          }
        )
        setOpen(false)
      } else
        enqueueSnackbar(`${t("Failed to create a new investigator.")}`, {
          variant: "error",
        })
    }
  }

  return (
    <Box display="flex" alignItems="start">
      {!!researcher ? (
        <Fab size="small" classes={{ root: classes.btnWhite }} onClick={() => setOpen(true)}>
          <Icon>edit</Icon>
        </Fab>
      ) : (
        <Fab
          variant="extended"
          classes={{ root: classes.btnBlue }}
          onClick={() => setOpen(true)}
          style={{ marginTop: 15 }}
        >
          <Icon>add</Icon> {`${t("Add")}`}
        </Fab>
      )}
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={`${t("Name")}`}
            fullWidth
            onChange={(event) => setResearcherName(event.target.value)}
            value={name}
            helperText={
              typeof name == "undefined" || name === null || name.trim() === "" ? `${t("Please enter name")}` : ""
            }
          />
          <TextField
            select
            margin="dense"
            id="role"
            label={`${t("Role")}`}
            fullWidth
            onChange={(event) => setResearcherRole(event.target.value)}
            value={role}
            helperText={
              typeof name == "undefined" || name === null || name.trim() === "" ? `${t("Please enter role")}` : ""
            }
          >
            {Object.keys(locale_role).map((key, value) => {
              return (
                <MenuItem key={key} value={key}>
                  {locale_role[key].native}
                </MenuItem>
              )
            })}
          </TextField>
          <TextField
            select
            margin="dense"
            id="organization"
            label={`${t("Organization")}`}
            fullWidth
            onChange={(event) => setResearcherOrganization(event.target.value)}
            value={organization}
            helperText={
              typeof name == "undefined" || name === null || name.trim() === ""
                ? `${t("Please enter organization")}`
                : ""
            }
          >
            {organizations.map((key, value) => {
              return (
                <MenuItem key={key.id} value={key.id}>
                  {key.name}
                </MenuItem>
              )
            })}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false)
              setResearcherName(!!rData ? rData.name : "")
            }}
            color="primary"
          >
            {`${t("Cancel")}`}
          </Button>
          <Button
            onClick={() => addResearcher()}
            color="primary"
            disabled={typeof name == "undefined" || name === null || name.trim() === "" ? true : false}
          >
            {!!researcher ? `${t("Update")}` : `${t("Add")}`}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
