import i18next from "i18next"

const t = i18next.t.bind(i18next)

export const QUESTION_TYPE_KEY = "questionTypes"
export const FORM_STRUCTURE_TYPE_KEY = "formStructure"

export const QUESTION_TYPE_TEXT_ID = 1
export const QUESTION_TYPE_BOOLEAN_ID = 2
export const QUESTION_TYPE_LIST_ID = 3
export const QUESTION_TYPE_MULTI_SELECT_ID = 4
export const QUESTION_TYPE_SLIDER_ID = 5
export const QUESTION_TYPE_RATING_ID = 7
export const QUESTION_TYPE_TIME_ID = 8
export const QUESTION_TYPE_LIKE_ID = 9
export const QUESTION_TYPE_MATRIX_ID = 10
export const QUESTION_TYPE_SHORT_ANSWER_ID = 19

export const QUESTION_GROUP_TYPE_ID = 11
export const QUESTION_TYPE_STATEMENT_ID = 13
export const QUESTION_TYPE_END_SCREEN_ID = 14

const types = {
  [QUESTION_TYPE_KEY]: {
    name: t("Question type"),
    fields: [
      {
        type: "text",
        label: t("Text"),
        icon: "format_align_left",
        backgroundColor: "#A6EF67",
        borderColor: "#4CA30D",
        id: QUESTION_TYPE_TEXT_ID,
      },
      {
        type: "boolean",
        label: t("Boolean"),
        icon: "check_circle_outline",
        backgroundColor: "#FF9C66",
        borderColor: "#E62E05",
        id: QUESTION_TYPE_BOOLEAN_ID,
      },
      {
        type: "list",
        label: t("List"),
        icon: "format_list_bulleted",
        backgroundColor: "#5FE9D0",
        borderColor: "#0E9384",
        id: QUESTION_TYPE_LIST_ID,
      },
      {
        type: "multiselect",
        label: t("Multi-select"),
        icon: "grid_view",
        backgroundColor: "#75E0A7",
        borderColor: "#079455",
        id: QUESTION_TYPE_MULTI_SELECT_ID,
      },
      {
        type: "slider",
        label: t("Slider"),
        icon: "tune",
        backgroundColor: "#7CD4FD",
        borderColor: "#0086C9",
        id: QUESTION_TYPE_SLIDER_ID,
      },
      {
        type: "short",
        label: t("Short answer"),
        icon: "contact_support_outlined",
        backgroundColor: "#FDE272",
        borderColor: "#CA8504",
        id: QUESTION_TYPE_SHORT_ANSWER_ID,
      },
      {
        type: "rating",
        label: t("Rating"),
        icon: "star_outline_rounded",
        backgroundColor: "#FEC84B",
        borderColor: "#FD8B28",
        id: QUESTION_TYPE_RATING_ID,
      },
      {
        type: "time",
        label: t("Time"),
        icon: "alarm_outlined",
        backgroundColor: "#FAA7E0",
        borderColor: "#DD2590",
        id: QUESTION_TYPE_TIME_ID,
      },
      {
        type: "likert",
        label: t("Like"),
        icon: "thumb_up_alt_outlined",
        backgroundColor: "#EEAAFD",
        borderColor: "#BA24D5",
        id: QUESTION_TYPE_LIKE_ID,
      },
      {
        type: "matrix",
        label: t("Matrix"),
        icon: "window_outlined",
        backgroundColor: "#FEC84B",
        borderColor: "#FD8B28",
        id: QUESTION_TYPE_MATRIX_ID,
      },
    ],
  },
  [FORM_STRUCTURE_TYPE_KEY]: {
    name: t("Form Structure"),
    fields: [
      {
        type: "group", //FIXME: check if this is correct
        label: t("Question Group"),
        icon: "folder_open_outlined",
        backgroundColor: "#B9C0D4",
        borderColor: "#4A5578",
        id: QUESTION_GROUP_TYPE_ID,
      },
      {
        type: "end", //FIXME: check if this is correct
        label: t("End Screen"),
        icon: "exit_to_app_outlined",
        backgroundColor: "#B9C0D4",
        borderColor: "#4A5578",
        id: QUESTION_TYPE_END_SCREEN_ID,
      },
      {
        type: "chat", //FIXME: check if this is correct
        label: t("Statement"),
        icon: "chat_outlined",
        backgroundColor: "#B9C0D4",
        borderColor: "#4A5578",
        id: QUESTION_TYPE_STATEMENT_ID,
      },
    ],
  },
}
export default types
