import { Box } from "@material-ui/core"
import React, { useEffect } from "react"
import NodeTextField from "../NodeTextField"
import { Handle, Position } from "reactflow"
import DataSourceImage from "../../../../../../assets/img/data-source.svg"
import OptionsToolbar, { TriggerButton } from "../OptionsToolbar"
import { DataSource } from "../../../../../../types/dataSource"
import { Trans, useTranslation } from "react-i18next"
import SlotField from "../SlotField"
import { Question } from "../../../../../../types/question"

export default function DataSourceNode(props) {
  const { data } = props
  const { t } = useTranslation()
  const [displayOptionsToolbar, setDisplayOptionsToolbar] = React.useState(false)
  const [dataSource, setDataSource] = React.useState<DataSource>(() => {
    if (
      data?.payload &&
      (data.payload.name || data.payload.id || data.payload.question || data.payload.math_operator)
    ) {
      return data.payload
    } else {
      return {
        name: "",
        id: 1,
        question: null,
        math_operator: null,
      }
    }
  })

  // Trigger change event when the value changes
  useEffect(() => {
    data.onChange?.(props.id, dataSource)
  }, [dataSource])

  return (
    <Box position="relative" top="0" width="201px" height="149px">
      <Handle type="target" position={Position.Top} isConnectable={false} />
      <img
        src={DataSourceImage}
        alt=""
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          zIndex: 1,
          border: displayOptionsToolbar ? "1px black dashed" : "1px transparent solid",
        }}
      />

      {displayOptionsToolbar && (
        <OptionsToolbar
          onCancel={() => setDisplayOptionsToolbar(false)}
          onEdit={data.onEdit}
          onDelete={data.onDelete}
        />
      )}

      <Box
        width="100%"
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap={4}
      >
        <TriggerButton onClick={() => setDisplayOptionsToolbar((prev) => !prev)} />

        <NameField name={dataSource.name} onChange={(name) => setDataSource((prev) => ({ ...prev, name }))} />

        <NodeTextField>{t("value to store =")}</NodeTextField>

        <Box display="flex" alignItems="center" gridGap={6}>
          <QuestionField
            question={dataSource.question}
            questions={data.options}
            onChange={(question) => setDataSource((prev) => ({ ...prev, question }))}
          />
          <MathOperatorField
            mathOperator={dataSource.math_operator}
            onChange={(math_operator) => setDataSource((prev) => ({ ...prev, math_operator }))}
          />
        </Box>
      </Box>
      <Handle type="source" position={Position.Bottom} isConnectable={false} />
    </Box>
  )
}

function NameField(props: { name: DataSource["name"]; onChange?: (value: DataSource["name"]) => void }) {
  const [value, setValue] = React.useState(props.name)
  const inputRef = React.useRef<HTMLDivElement>(null)

  // Focus on contentEditable element when clicking on the parent div
  const handleTextFieldClick = () => {
    inputRef.current?.focus()
  }

  // Update the value when the contentEditable element loses focus
  const handleInputBlur = (e) => {
    setValue(() => inputRef.current?.textContent)
  }

  // Trigger the onChange event when the value changes
  useEffect(() => {
    if (props.onChange) {
      props.onChange(value)
    }
  }, [value])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  return (
    <NodeTextField onClick={handleTextFieldClick}>
      <Trans>
        var name ={" "}
        <div
          ref={inputRef}
          contentEditable={true}
          suppressContentEditableWarning={true}
          style={{ pointerEvents: "none" }}
          onBlur={handleInputBlur}
        >
          {props.name}
        </div>
      </Trans>
    </NodeTextField>
  )
}

function QuestionField(props: {
  question: Question
  questions: Question[]
  onChange?: (value: DataSource["question"]) => void
}) {
  const { question, questions, onChange } = props
  const options = questions.map((q) => ({ id: q.id as number, label: q.text }))

  const handleChange = (val) => {
    const selectedQuestion = questions.find((question) => question.id === val.id)

    if (selectedQuestion) {
      onChange?.(selectedQuestion)
    }
  }

  return (
    <SlotField options={options} onChange={handleChange}>
      {question?.text}
    </SlotField>
  )
}

function MathOperatorField(props: {
  mathOperator?: DataSource["math_operator"]
  onChange?: (value: DataSource["math_operator"]) => void
}) {
  const { mathOperator, onChange } = props
  const inputRef = React.useRef<HTMLDivElement>(null)
  const [value, setValue] = React.useState(mathOperator)

  // Focus on contentEditable element when clicking on the parent div
  const handleTextFieldClick = () => {
    inputRef.current?.focus()
  }

  // Update the value when the contentEditable element loses focus
  const handleInputBlur = (e) => {
    setValue(() => inputRef.current?.textContent)
  }

  // Trigger the onChange event when the value changes
  useEffect(() => {
    if (onChange) {
      onChange(value)
    }
  }, [value])

  return (
    <div style={{ height: "100%", zIndex: 2, display: "flex", alignItems: "center" }}>
      <NodeTextField onClick={handleTextFieldClick}>
        <div
          ref={inputRef}
          contentEditable={true}
          suppressContentEditableWarning={true}
          style={{ pointerEvents: "none" }}
          onBlur={handleInputBlur}
        >
          {mathOperator}
        </div>
      </NodeTextField>
    </div>
  )
}
