import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  InputBase,
  MenuItem,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import { useSnackbar } from "notistack"

// Local Imports
import SPARK from "spark-core"

import { useTranslation } from "react-i18next"

const _qrLink = (credID, password) =>
  window.location.href.split("#")[0] +
  "#/?a=" +
  btoa([credID, password, SPARK.Auth._auth.serverAddress].filter((x) => !!x).join(":"))

const CssTextField = withStyles({
  root: {
    "label + &": {},
  },
  input: {
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.75)",
  },
})(InputBase)

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 16,
      color: "white",
    },
    PopupButton: {
      marginTop: 35,
      width: 168,
      height: 50,
      background: theme.palette.primary.main,
      boxShadow: "0px 10px 15px rgba(96, 131, 231, 0.2)",
      borderRadius: 25,
      marginBottom: 40,
      "&:hover": { background: theme.palette.primary.main },
    },
    popWidth: { width: "95%", maxWidth: "500px", padding: "0 40px" },
    dialogTitle: {
      fontSize: 18,
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.75)",
      marginTop: 30,
    },
    disabled: {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  })
)

export interface Sensors {
  id?: string
  study_id?: string
  name?: string
  spec?: string
}
export default function SensorDialog({
  sensor,
  projectId,
  type,
  addOrUpdateSensor,
  allSensors,
  ...props
}: {
  sensor?: Sensors
  projectId?: string
  type?: string
  addOrUpdateSensor?: Function
  allSensors?: Array<any>
} & DialogProps) {
  const classes = useStyles()
  const [projectName, setProjectName] = useState("")
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [selectedSensor, setSelectedSensor] = useState(sensor ?? null)
  const [sensorName, setSensorName] = useState(sensor ? sensor.name : "")
  const [sensorSpecs, setSensorSpecs] = useState(null)
  const [sensorSpec, setSensorSpec] = useState(sensor ? sensor.spec : null)
  const [loading, setLoading] = useState(false)
  const [duplicateCnt, setDuplicateCnt] = useState(0)
  const [oldSensorName, setOldSensorName] = useState(sensor ? sensor.name : "")
  const [allSensorData, setAllSensorData] = useState([])

  useEffect(() => {
    SPARK.SensorSpec.all().then((res) => {
      if (res.length > 0) {
        setSensorSpecs(res)
      } else {
        setSensorSpecs([
          { id: "spark.analytics" },
          { id: "spark.gps" },
          { id: "spark.device_motion" },
          { id: "spark.accelerometer" },
          { id: "spark.device_state" },
          { id: "spark.steps" },
        ])
      }
      setSensorSpec(sensor ? sensor.spec : null)
    })
  }, [])

  useEffect(() => {
    setAllSensorData(allSensors)
  }, [allSensors])

  useEffect(() => {
    if (type === "add") {
      setSelectedSensor(null)
      setSensorName("")
      setSensorSpec("")
    } else {
      setSelectedSensor(sensor ?? null)
      setSensorName(sensor ? sensor.name : "")
      setSensorSpec(sensor ? sensor.spec : null)
    }
  }, [props.open])

  useEffect(() => {
    let duplicateCount = 0
    if (!(typeof sensorName === "undefined" || (typeof sensorName !== "undefined" && sensorName?.trim() === ""))) {
      if (allSensorData) {
        selectedSensor
          ? (duplicateCount = allSensorData.filter((sensorData) => {
              if (sensorData.name !== oldSensorName) {
                return (
                  sensorData.study_id === projectId &&
                  sensorData.name?.trim().toLowerCase() === sensorName?.trim().toLowerCase()
                )
              }
            }).length)
          : (duplicateCount = allSensorData.filter(
              (sensorData) =>
                sensorData.study_id === projectId &&
                sensorData.name?.trim().toLowerCase() === sensorName?.trim().toLowerCase()
            ).length)
      }
      setDuplicateCnt(duplicateCount)
    }
  }, [sensorName])

  const validate = () => {
    return !(
      duplicateCnt > 0 ||
      typeof sensorName == "undefined" ||
      sensorName === null ||
      sensorName.trim() === "" ||
      typeof sensorSpec == "undefined" ||
      sensorSpec === null ||
      sensorSpec === ""
    )
  }

  const updateSensor = async () => {
    setLoading(true)
    const result = await SPARK.Sensor.update(selectedSensor.id, {
      name: sensorName.trim(),
      spec: sensorSpec,
      settings: {
        frequency: 1,
      },
    })
      .then((res) => {
        console.log(res)
        enqueueSnackbar(`${t("Successfully updated a sensor.")}`, {
          variant: "success",
        })
        setLoading(false)
        addOrUpdateSensor()
      })
      .catch((e) => {
        console.dir(e)
        setLoading(false)
      })
  }

  const saveSensor = async () => {
    setLoading(true)
    const result = await SPARK.Sensor.create(projectId, {
      name: sensorName.trim(),
      spec: sensorSpec,
      settings: {
        frequency: 1,
      },
    })
      .then((res) => {
        let result = JSON.parse(JSON.stringify(res))
        enqueueSnackbar(`${t("Successfully created a sensor.")}`, {
          variant: "success",
        })
        setLoading(false)
        addOrUpdateSensor()
      })
      .catch((e) => {
        console.dir(e)
        setLoading(false)
      })
  }

  useEffect(() => {
    SPARK.Study.view(projectId).then((project) => {
      setProjectName(project.name)
    })
  }, [])

  return (
    <Dialog {...props} classes={{ paper: classes.popWidth }} aria-labelledby="simple-dialog-title">
      <div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography className={classes.dialogTitle}>
          {selectedSensor ? `${t("Update Sensor")}` : `${t("Add Sensor")}`}
        </Typography>
        <Box mt={4}>
          <TextField fullWidth variant="outlined" label={`${t("Study")}`} defaultValue={projectName} disabled={true} />
        </Box>
        <Box mt={4}>
          <TextField
            error={
              duplicateCnt > 0 || typeof sensorName == "undefined" || sensorName === null || sensorName.trim() === ""
                ? true
                : false
            }
            value={sensorName}
            variant="filled"
            label={`${t("Name")}`}
            onChange={(event) => setSensorName(event.target.value)}
            placeholder={`${t("Name")}`}
            helperText={
              duplicateCnt > 0
                ? `${t("Unique name required.")}`
                : typeof sensorName == "undefined" || sensorName === null || sensorName.trim() === ""
                ? `${t("Please enter name.")}`
                : ""
            }
          />
        </Box>
        <Box mt={4}>
          <TextField
            error={typeof sensorSpec == "undefined" || sensorSpec === null || sensorSpec === "" ? true : false}
            id="filled-select-currency"
            select
            label={`${t("Sensor spec")}`}
            value={sensorSpec}
            onChange={(e) => {
              setSensorSpec(e.target.value)
            }}
            helperText={
              typeof sensorSpec == "undefined" || sensorSpec === null || sensorSpec === ""
                ? `${t("Please select the sensor spec.")}`
                : ""
            }
            variant="filled"
          >
            {!!sensorSpecs &&
              sensorSpecs.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {t(option.id.replace("spark.", ""))}
                </MenuItem>
              ))}
          </TextField>
        </Box>
        <Box textAlign="center" mt={2}>
          <Button
            onClick={() => (selectedSensor ? updateSensor() : saveSensor())}
            disabled={!validate()}
            className={!validate() ? classes.disabled + " " + classes.PopupButton : classes.PopupButton}
          >
            <Typography className={classes.buttonText}>{selectedSensor ? `${t("Update")}` : `${t("Add")}`}</Typography>
          </Button>
        </Box>
      </div>
    </Dialog>
  )
}
