import { Fab, Icon } from "@material-ui/core"
import { GridActionsCellItemProps, GridColDef, GridRowParams, useGridApiRef } from "@mui/x-data-grid"
import SPARK from "spark-core"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ConfirmationDialog from "../../ConfirmationDialog"
import TableComponent from "../../shared/TableComponent"
import AddUserDialog from "./AddUserDialog"

const buttonStyle = {
  background: "transparent",
  boxShadow: "none",
  color: "#475467",
}

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
  },
  {
    field: "role",
    headerName: "Role",
    sortable: false,
  },
  {
    field: "projects",
    headerName: "Projects",
    sortable: false,
  },
  {
    field: "actions",
    headerName: "",
    type: "actions",
    sortable: false,
    align: "right",
    getActions: (params: GridRowParams) => [
      <DeleteUserActionItem
        label="Delete"
        icon={<Icon>delete_outline</Icon>}
        user={params.row}
        setUsers={params.row.refreshData}
      />,
      <EditUserActionItem label="Edit" icon={<Icon>edit</Icon>} user={params.row} setUsers={params.row.refreshData} />,
    ],
  },
]

function DeleteUserActionItem({ user, setUsers }: GridActionsCellItemProps & { user: any; setUsers: () => void }) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [confirmationDialog, setConfirmationDialog] = useState(0)
  let handleConfirmAction = async (status) => {
    if (status === "Yes") {
      await SPARK.Researcher.delete(user.id)
      enqueueSnackbar(`${t("Successfully deleted the selected User.")}`, {
        variant: "success",
      })
      setUsers()
    }
    setConfirmationDialog(0)
  }
  return (
    <React.Fragment>
      <Fab variant="round" size="small" style={buttonStyle} onClick={(event) => setConfirmationDialog(7)}>
        <Icon fontSize="medium">delete_outline</Icon>
      </Fab>
      <ConfirmationDialog
        open={confirmationDialog > 0 ? true : false}
        onClose={() => setConfirmationDialog(0)}
        confirmAction={handleConfirmAction}
        confirmationMsg={`${t("Are you sure you want to delete this User?")}`}
      />
    </React.Fragment>
  )
}

function EditUserActionItem({ user, setUsers }: GridActionsCellItemProps & { user: any; setUsers: () => void }) {
  const [dialog, setDialog] = useState(false)
  return (
    <React.Fragment>
      <Fab
        variant="round"
        size="small"
        style={{
          ...buttonStyle,
          marginRight: "18px",
        }}
        onClick={() => setDialog(true)}
      >
        <Icon fontSize="medium">edit</Icon>
      </Fab>
      <AddUserDialog
        open={dialog}
        handleClose={() => setDialog(false)}
        researcher={user}
        researchers={[]}
        refreshResearchers={setUsers}
      />
    </React.Fragment>
  )
}

export default function UserList({ users, setUsers }) {
  const apiRef = useGridApiRef()

  return (
    <TableComponent
      rows={users ?? []}
      refreshData={setUsers}
      columns={columns}
      autosizeColumns={["name", "role", "projects", "actions"]}
    />
  )
}
