import { Box, Typography } from "@material-ui/core"
import { Icon } from "@mui/material"
import React from "react"

export interface IQuestionTypeIconBoxProps {
  backgroundColor: string
  borderColor: string
  icon: string
  text?: string
  style?: React.CSSProperties
}

export default function QuestionTypeIconBox(props: IQuestionTypeIconBoxProps) {
  const { backgroundColor, borderColor, icon, text, style = {} } = props

  return (
    <Box
      style={{
        ...{
          backgroundColor: backgroundColor,
          padding: 12,
          border: `2px solid ${borderColor}`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          gap: 6,
          borderRadius: 10,
          minWidth: 52,
        },
        ...style,
      }}
    >
      {icon !== "" && (
        <Icon style={{ color: "white" }} fontSize="small">
          {icon}
        </Icon>
      )}
      {text && <Typography>{text}</Typography>}
    </Box>
  )
}
