import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  InputBase,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import { useSnackbar } from "notistack"

// Local Imports
import SPARK from "spark-core"

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useTranslation } from "react-i18next"

import dayjs from "dayjs"
import schema from "../../../../adaptable/theme.json"

const _qrLink = (credID, password) =>
  window.location.href.split("#")[0] +
  "#/?a=" +
  btoa([credID, password, SPARK.Auth._auth.serverAddress].filter((x) => !!x).join(":"))

const CssTextField = withStyles({
  root: {
    "label + &": {},
  },
  input: {
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.75)",
  },
})(InputBase)

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: `4px 0 0 4px`,
        },
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 16,
      color: "white",
    },
    textField: {
      width: "100%",
      ["& fieldset"]: {
        borderRadius: `10px`,
      },
    },
    PopupButton: {
      marginLeft: 10,
      color: "#fff",
      backgroundColor: "#EE9142",
      border: "1px solid #E0E0E0",
      marginTop: 6.75,
      marginBottom: 40,
      borderRadius: "8px",
      width: 168,
      height: 50,
      "&:hover": { background: "#EE9142" },
    },
    popWidth: { width: "95%", maxWidth: "500px", padding: "0 40px" },
    dialogTitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.75)",
      marginTop: 30,
    },
    disabled: {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  })
)

export default function TriggerSchedulerDialog({
  trigger,
  projectId,
  type,
  addOrUpdateTrigger,
  closeDialog,
  researcherId,
  ...props
}: {
  trigger?: any
  projectId?: string
  type?: string
  addOrUpdateTrigger?: Function
  closeDialog?: Function
  researcherId?: string
} & DialogProps) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [selectedSchedule, setSelectedSchedule] = useState(trigger.schedule ?? null)
  const [loading, setLoading] = useState(false)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [repeatInterval, setRepeatInterval] = useState("none")

  const [hasEndDate, setHasEndDate] = useState(false)

  useEffect(() => {
    if (trigger.schedule) {
      if (trigger.schedule.length > 0) {
        setStartDate(dayjs(trigger.schedule[0].start_date))
        if (trigger.schedule[0].end_date) {
          setHasEndDate(true)
          setEndDate(dayjs(trigger.schedule[0].end_date))
          setEndTime(trigger.schedule[0].end_time)
        } else {
          setHasEndDate(false)
          setEndDate(null)
          setEndTime(null)
        }
        setStartTime(trigger.schedule[0].time)
        setRepeatInterval(trigger.schedule[0].repeat_interval)
        setSelectedSchedule(trigger.schedule[0])
        return
      }
    }
    setStartDate(null)
    setEndDate(null)
    setStartTime(null)
    setEndTime(null)
    setRepeatInterval("none")
    setSelectedSchedule(null)
  }, [props.open])

  const validate = () => {
    return !(startDate === null || startTime === null || (hasEndDate && (endDate === null || endTime === null)))
  }

  const updateTriggerSchedule = async () => {
    setLoading(true)
    trigger.schedule = [
      {
        start_date: startDate,
        time: startTime,
        end_date: endDate,
        end_time: endTime,
        repeat_interval: repeatInterval,
      },
    ]

    updateTrigger(trigger)
    setLoading(false)
  }

  const updateTrigger = async (trigger) => {
    // setLoading(true)

    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    try {
      const response = await fetch(`${baseUrl}/study/${projectId}/trigger_group`, {
        method: "Put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
        body: JSON.stringify(trigger),
      })
      const data = await response.json()
      enqueueSnackbar(`${t("Successfully created a decision tree builder.")}`, {
        variant: "success",
      })
      // setLoading(false)
      addOrUpdateTrigger()
    } catch (e) {
      console.dir(e)
      // setLoading(false)
    }

    // await SPARK.Activity.update(activity.id, { schedule: activity.schedule })
    // Service.update("activities", { activities: [{ schedule: activity.schedule, id: activity.id }] }, "schedule", "id")
  }

  return (
    <Dialog {...props} classes={{ paper: classes.popWidth }} aria-labelledby="simple-dialog-title">
      <div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography className={classes.dialogTitle}>{`${t("Schedule this trigger group")}`}</Typography>
        <Box mt={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: "50%", display: "inline-block" }}>
              {t("Date start")}
              <DatePicker
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                slotProps={{
                  textField: {
                    sx: {
                      // borderRadius: "10px",
                      // borderColor: "#e91e63",
                      // border: "1px solid",
                      selectedColor: schema.palette.primary.main,
                      marginTop: "10px",
                    },
                  },
                  field: {
                    sx: {
                      // borderRadius: "10px",
                      // borderColor: "#e91e63",
                      // border: "1px solid",
                      selectedColor: schema.palette.primary.main,
                    },
                  },
                  layout: {
                    sx: {
                      ".MuiPickersToolbar-root": {
                        color: "#1565c0",
                        borderRadius: "12px",
                        borderWidth: "1px",
                        borderColor: "#2196f3",
                        border: "1px solid",
                        backgroundColor: "#90caf9",
                      },
                      input: schema.palette.primary.main,
                      borderRadius: 20,
                      width: "100%",
                      color: schema.palette.primary.main,
                      borderColor: schema.palette.primary.main,
                      accentColor: schema.palette.primary.main,
                      hoverColor: schema.palette.primary.main,
                      highlightColor: schema.palette.primary.main,
                      selectedColor: schema.palette.primary.main,
                      colorActive: schema.palette.primary.main,
                      colorSelected: schema.palette.primary.main,
                      colorDisabled: schema.palette.primary.main,
                      colorToday: schema.palette.primary.main,
                      "& input": {
                        color: schema.palette.primary.main,
                      },
                      "& .MuiPickersDay-root.Mui-selected": {
                        backgroundColor: schema.palette.primary.main,
                      },
                      "& .MuiPickersDay-root.Mui-select": {
                        backgroundColor: schema.palette.primary.main,
                      },
                      "& .MuiPickersYear-yearButton.Mui-selected": {
                        backgroundColor: schema.palette.primary.main,
                      },
                    },
                  },
                }}
              />
            </div>
            <div style={{ width: "50%", display: "inline-block" }}>
              {t("Time")}
              <TextField
                value={startTime}
                variant="outlined"
                type="time"
                onChange={(event) => setStartTime(event.target.value)}
                placeholder={`${t("Enter a time")}`}
                className={classes.textField}
                style={{
                  marginTop: "10px",
                }}
              />
            </div>
          </LocalizationProvider>
        </Box>
        <Box mt={4} style={{ width: "100%", alignItems: "center" }}>
          {`${t("Add an end date")}`}
          <Switch
            checked={hasEndDate}
            onChange={(event) => setHasEndDate(event.target.checked)}
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
            style={{
              marginLeft: "auto",
              marginRight: "0px",
            }}
          />
          <Typography></Typography>
        </Box>
        {hasEndDate && (
          <Box mt={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: "50%", display: "inline-block" }}>
                {t("Date end")}
                <DatePicker
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  slotProps={{
                    textField: {
                      sx: {
                        // borderRadius: "10px",
                        // borderColor: "#e91e63",
                        // border: "1px solid",
                        selectedColor: schema.palette.primary.main,
                        marginTop: "10px",
                      },
                    },
                    field: {
                      sx: {
                        // borderRadius: "10px",
                        // borderColor: "#e91e63",
                        // border: "1px solid",
                        selectedColor: schema.palette.primary.main,
                      },
                    },
                    layout: {
                      sx: {
                        ".MuiPickersToolbar-root": {
                          color: "#1565c0",
                          borderRadius: "12px",
                          borderWidth: "1px",
                          borderColor: "#2196f3",
                          border: "1px solid",
                          backgroundColor: "#90caf9",
                        },
                        input: schema.palette.primary.main,
                        borderRadius: 20,
                        width: "100%",
                        color: schema.palette.primary.main,
                        borderColor: schema.palette.primary.main,
                        accentColor: schema.palette.primary.main,
                        hoverColor: schema.palette.primary.main,
                        highlightColor: schema.palette.primary.main,
                        selectedColor: schema.palette.primary.main,
                        colorActive: schema.palette.primary.main,
                        colorSelected: schema.palette.primary.main,
                        colorDisabled: schema.palette.primary.main,
                        colorToday: schema.palette.primary.main,
                        "& input": {
                          color: schema.palette.primary.main,
                        },
                        "& .MuiPickersDay-root.Mui-selected": {
                          backgroundColor: schema.palette.primary.main,
                        },
                        "& .MuiPickersDay-root.Mui-select": {
                          backgroundColor: schema.palette.primary.main,
                        },
                        "& .MuiPickersYear-yearButton.Mui-selected": {
                          backgroundColor: schema.palette.primary.main,
                        },
                      },
                    },
                  }}
                />
              </div>
              <div style={{ width: "50%", display: "inline-block" }}>
                {t("Time")}
                <TextField
                  value={endTime}
                  variant="outlined"
                  type="time"
                  onChange={(event) => setEndTime(event.target.value)}
                  placeholder={`${t("Enter a time")}`}
                  className={classes.textField}
                  style={{
                    marginTop: "10px",
                  }}
                />
              </div>
            </LocalizationProvider>
          </Box>
        )}
        <Box mt={4}>
          {`${t("Repeat")}`}
          <Select
            value={repeatInterval}
            variant="outlined"
            onChange={(event) => setRepeatInterval(event.target.value.toString())}
            style={{
              marginTop: "10px",
              width: "100%",
            }}
          >
            <MenuItem value="none">{`${t("None")}`}</MenuItem>
            <MenuItem value="daily">{`${t("Every day")}`}</MenuItem>
            <MenuItem value="weekly">{`${t("Every week")}`}</MenuItem>
            <MenuItem value="monthly">{`${t("Every month")}`}</MenuItem>
            <MenuItem value="yearly">{`${t("Every year")}`}</MenuItem>
          </Select>
        </Box>
        <Box textAlign="right" mt={2}>
          <Button
            onClick={() => {
              updateTriggerSchedule()
              closeDialog()
            }}
            disabled={!validate()}
            className={!validate() ? classes.disabled + " " + classes.PopupButton : classes.PopupButton}
          >
            <Typography className={classes.buttonText}>
              {selectedSchedule ? `${t("Update")}` : `${t("Continue")}`}
            </Typography>
          </Button>
        </Box>
      </div>
    </Dialog>
  )
}
