import React from "react"
import { useEffect } from "react"

type ValidationError = {
  message: string
  tag?: string
  payload?: any
}

/**
 * Custom hook for validation
 *
 * @returns {Object} - Object containing validation state and functions
 */
export default function useValidation() {
  const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([])
  const [isValidated, setIsValidated] = React.useState(false)

  // Add new error to the validation errors list
  const addError = (message: string, tag?: string, payload?: any) => {
    const validationError = { message, tag, payload }

    setValidationErrors((prev) => [...prev, validationError])
  }

  // Remove all errors currently in the validation errors list
  const clearErrors = () => setValidationErrors([])

  useEffect(() => {
    setIsValidated(validationErrors.length === 0)
  }, [validationErrors])

  return {
    isValidated,
    validationErrors,
    addError,
    clearErrors,
  }
}
