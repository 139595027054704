import { Box, Button, Icon, IconButton, InputAdornment, Paper, TextField, Typography } from "@material-ui/core"
import { Stack } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

export type Option = {
  id: string | number
  label: string
}

export type SearchableSelectProps = {
  options: Option[]
  filterOptions?: boolean // Whether to filter options based on the search query
  onSearch?: (search: string) => void
  onChange?: (option: Option) => void
}

export default function SearchableSelect(props: SearchableSelectProps) {
  const { onSearch, onChange, filterOptions = true, options = [] } = props
  const { t } = useTranslation()
  const [query, setQuery] = React.useState("")
  const [optionsProcessed, setOptionsProcessed] = React.useState<Option[]>(options)

  React.useEffect(() => {
    // Filter options if needed
    setOptionsProcessed(
      options.filter((option) => {
        if (!filterOptions) {
          return true
        }

        const q = query.toLowerCase()

        return option.label.toLocaleLowerCase().includes(q) || String(option.id).toLowerCase().includes(q)
      })
    )
  }, [filterOptions, options, query])

  const { pageItems, setPage, allowNext, allowPrev, handleNextPage, handlePrevPage } = usePaginate(optionsProcessed, 7)

  const handleClick = React.useCallback(
    (option) => {
      if (onChange) {
        onChange(option)
      }
    },
    [options, onChange]
  )

  const handleSearch = (e: any) => {
    // Use callback if declared
    if (onSearch) {
      onSearch?.(e.target.value)
    }

    setQuery(e.target.value)
    setPage(0)
  }

  return (
    <div>
      <Paper elevation={12}>
        <Box bgcolor="white" display="flex" flexDirection="column" style={{ borderRadius: 12 }} p={2} width={200}>
          <TextField
            label={t("Search")}
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon style={{ color: "#344054" }}>search</Icon>
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            value={query}
          />
          <Box style={{ overflowY: "auto" }}>
            <Stack>
              {pageItems.length > 0 ? (
                pageItems.map((option, index) => (
                  <Button key={index} onClick={() => handleClick(option)}>
                    <span style={{ textAlign: "left", width: "100%" }}>{option.label}</span>
                  </Button>
                ))
              ) : (
                <Box mt={1}>
                  <Typography>{t("No results")}</Typography>
                </Box>
              )}
            </Stack>
          </Box>

          {(allowPrev || allowNext) && (
            <Box width="100%" display="flex" justifyContent="space-between" mt={2}>
              <IconButton onClick={handlePrevPage} disabled={!allowPrev} color="primary">
                <Icon>arrow_back_ios</Icon>
              </IconButton>
              <IconButton onClick={handleNextPage} disabled={!allowNext} color="primary" style={{ marginLeft: "auto" }}>
                <Icon>arrow_forward_ios</Icon>
              </IconButton>
            </Box>
          )}
        </Box>
      </Paper>
    </div>
  )
}

/**
 * Custom hook for pagination
 *
 * @param {Array} items - Array of items to paginate, make sure this is a state, to prevent memory leaks
 * @param {number} rowsPerPage - Number of items per page
 * @returns {Object} - Object containing pagination state and functions
 */
function usePaginate(items: any[], rowsPerPage = 10) {
  const [page, setPage] = React.useState(0)
  const [pageItems, setPageItems] = React.useState<any[]>([])
  const [allowNext, setAllowNext] = React.useState(true)
  const [allowPrev, setAllowPrev] = React.useState(false)
  const itemsTotal = items.length
  const maxPage = Math.ceil(itemsTotal / rowsPerPage)

  const handleNextPage = () => {
    setPage((prev) => prev + 1)
  }

  const handlePrevPage = () => {
    setPage((prev) => Math.max(prev - 1, 0))
  }

  React.useEffect(() => {
    setAllowNext(page < maxPage - 1)
    setAllowPrev(page > 0)
    setPageItems(items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [page, items])

  return {
    page,
    setPage,
    pageItems,
    allowNext,
    allowPrev,
    handleNextPage,
    handlePrevPage,
  }
}
