import {
  BottomNavigationAction,
  Box,
  Drawer,
  Icon,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import schema from "../adaptable/theme.json"
import { ReactComponent as Assess } from "../icons/Assess.svg"
import { ReactComponent as Learn } from "../icons/Learn.svg"
import { ReactComponent as Manage } from "../icons/Manage.svg"
import { ReactComponent as PreventIcon } from "../icons/Prevent.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigation: {
      "& svg": { width: 36, height: 36, padding: 6, borderRadius: "50%", opacity: 0.5 },
      "& span": { color: "black" },
      [theme.breakpoints.up("md")]: {
        flex: "none",
      },
      minWidth: 64,
      margin: "6.75px 10px",
      borderRadius: 4,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "rgba(255, 255, 255, 0.75)",
    },
    navigationSelected: {
      "& svg": {
        background: `${schema.palette.themeColor1.main} !important`,
        opacity: 1,
      },
      "& span": { color: "black" },
      marginLeft: 10,
      marginRight: 10,
      color: "#000",
      backgroundColor: "#fafafa",
      marginTop: 6.75,
      marginBottom: 6.75,
      borderRadius: 4,
    },
    navigationLabel: {
      letterSpacing: 0,
      borderRadius: 4,
      fontsize: "0.875rem",
      fonrweight: 500,
    },
    leftbar: {
      "& > div": {
        [theme.breakpoints.up("md")]: {
          backgroundColor: "#fff",
          border: 0,
        },
        "& a": {
          [theme.breakpoints.down("sm")]: {
            flex: 1,
          },
        },
      },
    },
    logResearcher: {
      "& > div": {
        marginTop: 50,
        height: "calc(100vh - 55px)",
        borderLeft: theme.palette.primary.main + " solid 5px",
        zIndex: 1111,
        [theme.breakpoints.down("sm")]: {
          borderBottom: theme.palette.primary.main + " solid 5px",
          borderRight: theme.palette.primary.main + " solid 5px",
        },
      },
    },
    btnCursor: {
      "&:hover span ": {
        cursor: "pointer !important",
      },
      "&:hover span > div": {
        cursor: "pointer !important",
      },
      "&:hover span > div > svg": {
        cursor: "pointer !important",
      },
      "&:hover span > div > svg > path": {
        cursor: "pointer !important",
      },
      "&:hover span > svg": {
        cursor: "pointer !important",
      },
      "&:hover span > svg > path": {
        cursor: "pointer !important",
      },
    },
    assessH: {
      background: "#E7F8F2 !important",
    },
    learnH: {
      background: "#FFF9E5 !important",
    },
    manageH: {
      background: "#FFEFEC !important",
    },
    preventH: {
      background: "#ECF4FF !important",
    },
  })
)

const GenericTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    zIndex: 999,
    padding: "25px 20px",
    boxShadow: "none",
    background: schema.palette.primary.main,
    borderRadius: 10,
    maxWidth: 345,
    right: 10,
    "& h6": { color: "white", fontWeight: 300, fontSize: 16, "& span": { fontWeight: 500 } },
    "& p": { color: "white", fontWeight: 300, marginTop: 10 },
    "& svg": { color: "white" },
    [theme.breakpoints.up("md")]: {
      right: 0,
    },
  },
  arrow: {
    color: schema.palette.primary.main,
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "19px !important",
    },
  },
}))(Tooltip)

export async function sensorEventUpdate(val: string, participantId: string, activityId: string, timestamp?: number) {
  if (SPARK.Auth._type === "participant") {
    return await SPARK.SensorEvent.create(participantId, {
      timestamp: timestamp ?? new Date().getTime(),
      sensor: "spark.analytics",
      data: {
        type: "open_page",
        page: val,
        activity: activityId,
      },
    })
  }
}

export default function BottomMenu({ ...props }) {
  const classes = useStyles()
  const supportsSidebar = useMediaQuery(useTheme().breakpoints.up("md"))
  const { t } = useTranslation()
  const tabs = [
    // {
    //   id: 0,
    //   name: "feed",
    //   label: "Feed",
    //   classes: {
    //     root: classes.navigation,
    //     selected: classes.navigationSelected,
    //     label: classes.navigationLabel,
    //   },
    //   icon: <Icon></Icon>,
    //   tooltip: (
    //     <React.Fragment>
    //       <IconButton aria-label="close" className={classes.closeButton} onClick={() => updateLocalStorage(0)}>
    //         <Icon>close</Icon>
    //       </IconButton>
    //       <Typography variant="h6">{`${t("Welcome to the Feed section")}`}</Typography>
    //       <Typography variant="body1">{`${t("Review today's activities.")}`}</Typography>
    //     </React.Fragment>
    //   ),
    // },
    {
      id: 0,
      name: "learn",
      label: "Beep",
      classes: {
        root: classes.navigation,
        selected: classes.navigationSelected,
        label: classes.navigationLabel,
      },
      icon: <Learn />,
      tooltip: (
        <React.Fragment>
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => updateLocalStorage(1)}>
            <Icon>close</Icon>
          </IconButton>
          <Typography variant="h6">{`${t("Welcome to the Learn section")}`}</Typography>
          <Typography variant="body1">{`${t("Find useful information and practice healthy habits.")}`}</Typography>
        </React.Fragment>
      ),
    },
    {
      id: 1,
      name: "assess",
      label: "Assess",
      classes: {
        root: classes.navigation,
        selected: classes.navigationSelected,
        label: classes.navigationLabel,
      },
      icon: <Assess />,
      tooltip: (
        <React.Fragment>
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => updateLocalStorage(2)}>
            <Icon>close</Icon>
          </IconButton>
          <Typography variant="h6">{`${t("Welcome to the Assess section")}`}</Typography>
          <Typography variant="body1">{`${t("Log feelings, behavior, and activity.")}`}</Typography>
        </React.Fragment>
      ),
    },
    {
      id: 2,
      name: "manage",
      label: "Triggered",
      classes: {
        root: classes.navigation,
        selected: classes.navigationSelected,
        label: classes.navigationLabel,
      },
      icon: <Manage />,
      tooltip: (
        <React.Fragment>
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => updateLocalStorage(3)}>
            <Icon>close</Icon>
          </IconButton>
          <Typography variant="h6">{`${t("Welcome to the Manage section")}`}</Typography>
          <Typography variant="body1">{`${t("Take steps to refocus, reflect, and recover.")}`}</Typography>
        </React.Fragment>
      ),
    },
    {
      id: 3,
      name: "portal",
      label: "Portal",
      classes: {
        root: classes.navigation,
        selected: classes.navigationSelected,
        label: classes.navigationLabel,
      },
      icon: <PreventIcon />,
      tooltip: (
        <React.Fragment>
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => updateLocalStorage(4)}>
            <Icon>close</Icon>
          </IconButton>
          <Typography variant="h6">{`${t("Welcome to the Portal section")}`}</Typography>
          <Typography variant="body1">{`${t("Track progress and make connections.")}`}</Typography>
        </React.Fragment>
      ),
    },
  ]

  const getTab = (tab: string) => {
    return tabs.find((item) => item.name === tab).id
  }
  const [openTab, _setTab] = useState(getTab(props.tabValue))
  const [tabValues, setTabValues] = useState(
    localStorage.getItem("bottom-menu-tabs" + props.participant.id) !== null
      ? JSON.parse(localStorage.getItem("bottom-menu-tabs" + props.participant.id))
      : []
  )

  useEffect(() => {
    sensorEventUpdate(tabs[openTab].name, props.participant.id, null)
    props.activeTab(tabs[openTab].name, props.participant.id)
  }, [])

  const openTabUpdate = (val) => {
    sensorEventUpdate(tabs[val].name, props.participant.id, null)
    props.activeTab(tabs[val].name, props.participant.id)
    _setTab(val)
    props.setShowDemoMessage(false)
  }

  const updateLocalStorage = (tab) => {
    setTabValues({ ...tabValues, [tab]: false })
  }

  useEffect(() => {
    localStorage.setItem("bottom-menu-tabs" + props.participant.id, JSON.stringify(tabValues))
  }, [tabValues])

  return (
    <div>
      <Box clone displayPrint="none">
        <Drawer
          open
          className={classes.leftbar + (SPARK.Auth._type === "participant" ? "" : " " + classes.logResearcher)}
          anchor={supportsSidebar ? "top" : "bottom"}
          variant="permanent"
          PaperProps={{
            style: {
              marginTop: 51,
              flexDirection: "row",
              justifyContent: !supportsSidebar ? "center" : undefined,
              alignItems: "left",
              height: 50,
              width: "100%",
              transition: "all 500ms ease-in-out",
              outline: "#D0D5DD solid 1px",
            },
          }}
        >
          {(tabs ?? []).map((_, index) => (
            <GenericTooltip
              open={false}
              interactive={true}
              className={classes.btnCursor}
              title={tabs[openTab].tooltip}
              arrow={true}
              placement={supportsSidebar ? "right" : "top"}
            >
              <BottomNavigationAction
                showLabel
                selected={openTab === index}
                label={`${t(tabs[index].label)}`}
                value={index}
                classes={tabs[index].classes}
                onChange={(_, newTab) => openTabUpdate(newTab)}
              />
            </GenericTooltip>
          ))}
        </Drawer>
      </Box>
    </div>
  )
}
