import { Box, Button, Grid, Icon, Theme, createStyles, makeStyles } from "@material-ui/core"
import { IconButton } from "@mui/material"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Pagination from "../../../PaginatedElement"
import { EmptyIndicator } from "../../../shared/EmptyIcon"
import AddTrigger from "./AddTrigger"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: "#fff",
      },
    },
    tableContainer: {
      "& div.MuiInput-underline:before": { borderBottom: "0 !important" },
      "& div.MuiInput-underline:after": { borderBottom: "0 !important" },
      "& div.MuiInput-underline": {
        "& span.material-icons": {
          width: 21,
          height: 19,
          fontSize: 27,
          lineHeight: "23PX",
          color: "rgba(0, 0, 0, 0.4)",
        },
        "& button": { display: "none" },
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 80,
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    norecordsmain: {
      minHeight: "calc(100% - 114px)",
      position: "absolute",
    },
    norecords: {
      "& span": { marginRight: 5 },
    },
    triggerMain: {
      background: "#fff",
      borderRadius: 12,
      minWidth: "312.25px",
      width: "100%",
      minHeight: "100px",
      border: "1px solid #EEEEEE",
    },
  })
)

export default function TriggerList({
  triggers,
  getAllTriggers,
  setOrder,
  order,
  mode,
  projectId,
  getAllActivities,
  researcherId,
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [selectedTriggers, setSelectedTriggers] = useState([])
  const [paginatedTriggers, setPaginatedTriggers] = useState([])
  const [allTriggers, setAllTriggers] = useState([])
  const [rowCount, setRowCount] = useState(40)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState(null)

  const [activities, setActivities] = useState([])

  useEffect(() => {
    let params = JSON.parse(localStorage.getItem("triggers"))
    setPage(params?.page ?? 0)
    setRowCount(params?.rowCount ?? 40)
  }, [])

  const handleChange = (sensorData, checked) => {
    if (checked) {
      setSelectedTriggers((prevState) => [...prevState, sensorData])
    } else {
      let selected = selectedTriggers.filter((item) => item.id != sensorData.id)
      setSelectedTriggers(selected)
    }
  }

  const searchTriggers = (searchVal?: string) => {
    const searchTxt = searchVal ?? search
    let result = []
    getAllTriggers().then((triggerData) => {
      setAllTriggers(triggerData)
      result = result.concat(triggerData)
      if (!!searchTxt && searchTxt.trim().length > 0) {
        result = result.filter((i) => i.name?.toLowerCase()?.includes(searchTxt?.toLowerCase()))
      }
      setPaginatedTriggers(result.slice(page * rowCount, page * rowCount + rowCount))
      setLoading(false)
    })
    setSelectedTriggers(allTriggers)
  }

  const refreshTriggers = () => {
    let result = []
    getAllTriggers().then((triggerData) => {
      setAllTriggers(triggerData)
      result = result.concat(triggerData)
      setPaginatedTriggers(result.slice(page * rowCount, page * rowCount + rowCount))
      setLoading(false)
    })
    setSelectedTriggers([])
  }

  const handleSearchData = (val: string) => {
    setSearch(val)
    searchTriggers(val)
  }

  const handleChangePage = (page: number, rowCount: number) => {
    setLoading(true)
    setRowCount(rowCount)
    setPage(page)
    localStorage.setItem("triggers", JSON.stringify({ page: page, rowCount: rowCount }))
    setPaginatedTriggers(triggers.slice(page * rowCount, page * rowCount + rowCount))
    setLoading(false)
  }

  useEffect(() => {
    getAllActivities().then((a) => setActivities(a.filter((a) => a.spec !== "group.survey.question")))
  }, [])

  useEffect(() => {
    console.log("Activities", activities)
  }, [activities])

  return (
    <React.Fragment>
      {triggers !== null && triggers !== null && (triggers || []).length > 0 && (
        <Box>
          <Box className={classes.tableContainer} py={4}>
            <Grid
              container
              spacing={0}
              lg="auto"
              direction="row"
              style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
            >
              {paginatedTriggers.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={index}
                  style={{
                    margin: 6,
                    flex: "49%",
                  }}
                >
                  <Box display="flex" p={1} className={classes.triggerMain} sx={{ alignItems: "center" }}>
                    <Box
                      display="flex"
                      flexGrow={1}
                      sx={{ flexDirection: "column", justifyContent: "space-between" }}
                      onClick={(event) =>
                        (window.location.href = `/#/researcher/${researcherId}/project/${projectId}/trigger/${item.id}`)
                      }
                      height="100%"
                    >
                      <div>
                        <h1 style={{ marginTop: 0 }}>{item.name}</h1>
                      </div>
                      <Button
                        variant={"outlined"}
                        disableElevation={false}
                        disabled={true}
                        style={{
                          marginLeft: 6,
                          marginRight: "auto",
                          height: 25,
                          marginTop: 12.5,
                          marginBottom: 12.5,
                          borderRadius: 15,
                          padding: "0px 10px",
                          fontSize: 14,
                          backgroundColor:
                            item.schedule &&
                            item.schedule.length > 0 &&
                            dayjs(item.schedule[0]?.start_date) <= dayjs(new Date()) &&
                            !item.schedule[0]?.end_date
                              ? "#ECFDF3"
                              : "#F9FAFB",
                          borderColor:
                            item.schedule &&
                            item.schedule.length > 0 &&
                            dayjs(item.schedule[0]?.start_date) <= dayjs(new Date()) &&
                            !item.schedule[0]?.end_date
                              ? "#ABEFC6"
                              : "#EAECF0",
                          outlineColor:
                            item.schedule &&
                            item.schedule.length > 0 &&
                            dayjs(item.schedule[0]?.start_date) <= dayjs(new Date()) &&
                            !item.schedule[0]?.end_date
                              ? "#ABEFC6"
                              : "#EAECF0",
                          color:
                            item.schedule &&
                            item.schedule.length > 0 &&
                            dayjs(item.schedule[0]?.start_date) <= dayjs(new Date()) &&
                            !item.schedule[0]?.end_date
                              ? "#067647"
                              : "#717BBC",
                          boxShadow: "0px 0px 0px 0px",
                        }}
                      >
                        {!item.schedule || item.schedule.length <= 0
                          ? `${t("Concept")}`
                          : dayjs(item.schedule[0]?.start_date) <= dayjs(new Date()) && !item.schedule[0]?.end_date
                          ? `${t("Published")}`
                          : `${t("Scheduled")}`}
                      </Button>
                    </Box>
                    <Box
                      style={{
                        textAlign: "right",
                        marginBottom: "auto",
                      }}
                    >
                      <IconButton
                        style={{
                          marginLeft: "auto",
                        }}
                        onClick={(event) => {
                          console.log("TODO: show project creator")
                        }}
                      >
                        <Icon>more_vert</Icon>
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              ))}
              <Pagination
                data={triggers}
                updatePage={handleChangePage}
                rowPerPage={[20, 40, 60, 80]}
                currentPage={page}
                currentRowCount={rowCount}
              />
            </Grid>
          </Box>
        </Box>
      )}
      {triggers === null ||
        ((triggers || []).length === 0 && (
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" pt={12}>
            <EmptyIndicator
              title={t("No decision tree builders found")}
              description={t(
                "There are no decision tree builders groups here yet. Create a new decision tree builder and start your project."
              )}
            />

            <AddTrigger projectId={projectId} refreshTriggers={refreshTriggers} styleType={true} />
          </Box>
        ))}
    </React.Fragment>
  )
}
