import { Box, Card, Grid } from "@material-ui/core"
import jsonata from "jsonata"
import React from "react"
import { useDrop } from "react-dnd"
import { portalHomeStyle } from "./DataPortalShared"
import QueryBuilder from "./QueryBuilder"
import QueryRender from "./QueryRender"
import RenderTree from "./RenderTree"

export default function DataPortalHome({ token, onLogout, ...props }) {
  const classes = portalHomeStyle()
  const editorRef = React.useRef(null)
  const [query, setQuery] = React.useState("")
  const [result, setResult] = React.useState("")
  const [focusBuilder, toggleFocus] = React.useState(false)

  const [treeCollapsed, setTreeCollapsed] = React.useState(false)

  const [loadingGraphs, setLoadingGraphs] = React.useState(false)

  const [GUIQuery, setGUIQuery] = React.useState({
    target: "",
    name: "",
    query: "",
    type: "",
  })
  const runQuery = async () => {
    try {
      jsonata(query)["errors"] // check for errors first (change from .errors() made for TSX compliance)
      const url = (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + token.server
      let res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Basic ${token.username}:${token.password}`,
        },
        body: query,
      })
      let text = await res.text()
      let json = JSON.parse(text)
      setResult(!!json["$schema"] ? json : text)
    } catch (err) {
      delete err.stack
      setResult(JSON.stringify(err, null, 4))
    }
  }

  const onMonacoMount = (ref) => {
    editorRef.current = ref
    if (!!editorRef.current) editorRef.current.editor.getModel().setValue(query)
  }
  React.useEffect(() => {
    if (!!editorRef.current) {
      let cursorY = editorRef.current.editor.getPosition().lineNumber
      let cursorX = editorRef.current.editor.getPosition().column
      editorRef.current.editor.getModel().setValue(query)
      editorRef.current.editor.setPosition({ lineNumber: cursorY, column: cursorX })
    }
  }, [query])

  function updateGUIQuery(change) {
    let updatedQuery = { ...GUIQuery }
    change._update.forEach(function (updateTarget, index) {
      updatedQuery[updateTarget] = change.content[index]
    })
    setGUIQuery(updatedQuery)
  }

  const [viewModeSwitch, setViewModeSwitch] = React.useState(false)

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: "TARGETINFO",
    // if we drop a target in here, we switch to the GUI editor,
    // and load the new query
    drop: (item, monitor) => {
      //@ts-ignore: item should always be an object
      setGUIQuery(item)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  return (
    <Box className={classes.box}>
      <Box className={classes.queryWrapperBox}>
        <Grid
          className={classes.columnsGrid}
          item
          xs={12}
          spacing={1}
          direction={"row"}
          container
          alignContent={"flex-start"}
          style={{ marginTop: 50 }}
        >
          <Grid container className={classes.treeColumn} direction={"column"} item xs={3} lg={2}>
            <Card style={{ overflowY: "scroll", border: "1px solid black", maxHeight: "90%", marginTop: "10px" }}>
              <RenderTree
                token={token}
                name={token.name}
                id={token.type === "Administrator" ? [token.id] : [token.type, token.id]}
                type={token.type}
                isGUIEditor={true}
                onSetQuery={(q) => setQuery(q)}
                onUpdateGUI={(q) => updateGUIQuery(q)}
              />
            </Card>
          </Grid>
          <Grid container item direction={"column"} xs={9} lg={10} className={classes.queryColumn}>
            <Grid
              item
              ref={drop}
              role={"QueryBuilder"}
              className={focusBuilder ? classes.builderStyleFocus : classes.builderStyleUnfocus}
              onClick={() => {
                toggleFocus(true)
                if (editorRef.current) editorRef.current.editor.layout()
              }}
            >
              <QueryBuilder
                query={GUIQuery}
                focusMe={() => toggleFocus(true)}
                token={token}
                setQueryResult={setResult}
                setLoadingGraphs={setLoadingGraphs}
                loadingGraphs={loadingGraphs}
                queryResult={result}
              />
            </Grid>
            <Grid
              item
              className={focusBuilder ? classes.renderStyleUnfocus : classes.renderStyleFocus}
              onClick={() => toggleFocus(false)}
            >
              <QueryRender
                className={classes.queryRender}
                focusMe={() => toggleFocus(false)}
                loading={loadingGraphs}
                queryResult={result}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
