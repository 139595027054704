import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  InputBase,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import { useSnackbar } from "notistack"

// Local Imports
import SPARK, { Study } from "spark-core"

import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: `4px 0 0 4px`,
        },
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 16,
      color: "white",
    },
    textField: {
      width: "100%",
      ["& fieldset"]: {
        borderRadius: `10px`,
      },
    },
    PopupButton: {
      marginLeft: 10,
      color: "#fff",
      backgroundColor: "#EE9142",
      border: "1px solid #E0E0E0",
      marginTop: 6.75,
      marginBottom: 40,
      borderRadius: "8px",
      width: 168,
      height: 50,
      "&:hover": { background: "#EE9142" },
    },
    popWidth: { width: "95%", maxWidth: "500px", padding: "0 40px" },
    dialogTitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.75)",
      marginTop: 30,
    },
    disabled: {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  })
)

export interface Trigger {
  id?: string
  name?: string
  description?: string
  conditions: any[]
  activity: string
}
export default function ProjectDialog({
  project,
  onAddOrUpdateProject,
  projects,
  researcherId,
  ...props
}: {
  project?: Study
  onAddOrUpdateProject?: Function
  projects?: Array<any>
  researcherId?: string
} & DialogProps) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [duplicateCnt, setDuplicateCnt] = useState(0)

  const [projectName, setProjectName] = useState(project ? project.name : "")

  useEffect(() => {
    let duplicateCount = 0
    if (!(typeof projectName === "undefined" || (typeof projectName !== "undefined" && projectName?.trim() === ""))) {
      if (projects) {
        project
          ? (duplicateCount = projects.filter((a) => {
              if (a.name !== project.name) {
                return a.name?.trim().toLowerCase() === projectName?.trim().toLowerCase()
              }
            }).length)
          : (duplicateCount = projects.filter((a) => a.name?.trim().toLowerCase() === projectName?.trim().toLowerCase())
              .length)
      }
      setDuplicateCnt(duplicateCount)
    }
  }, [projectName])

  const validate = () => {
    return !(
      (duplicateCnt > 0 || typeof projectName == "undefined" || projectName === null || projectName.trim() === "") //||
    )
  }

  const handleCreateNewProject = () => {
    // TODO: this
  }

  const handleUpdateProject = () => {
    SPARK.Study.update(project.id, {
      ...project,
      name: projectName,
    }).then((res: any) => {
      if (res.error) {
        enqueueSnackbar(`${t("Something went wrong when updating the Project.")}`, { variant: "error" })
      } else {
        enqueueSnackbar(`${t("Successfully updated the Project.")}`, { variant: "success" })
        onAddOrUpdateProject()
      }
    })
  }

  return (
    <Dialog {...props} classes={{ paper: classes.popWidth }} aria-labelledby="simple-dialog-title">
      <div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography className={classes.dialogTitle}>
          {project ? `${t("Update Project")}` : `${t("Let's start creating a Project")}`}
        </Typography>
        <Box mt={4}>
          {t("Give your Project a name")}
          <TextField
            error={duplicateCnt > 0 || typeof projectName == "undefined"}
            value={projectName}
            variant="outlined"
            onChange={(event) => setProjectName(event.target.value)}
            placeholder={`${t("Survey title")}`}
            className={classes.textField}
            style={{
              marginTop: "10px",
            }}
          />
        </Box>
        <Box textAlign="right" mt={2}>
          <Button
            onClick={project ? handleUpdateProject : handleCreateNewProject}
            disabled={!validate()}
            className={!validate() ? classes.disabled + " " + classes.PopupButton : classes.PopupButton}
          >
            <Typography className={classes.buttonText}>{project ? `${t("Update")}` : `${t("Create")}`}</Typography>
          </Button>
        </Box>
      </div>
    </Dialog>
  )
}
