import { Box, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import SearchBox from "../../SearchBox"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      position: "relative",
      "& h5": {
        fontSize: "30px",
        fontWeight: "bold",
      },
    },
  })
)
export default function Header({ organizations, searchData, refreshOrganizations, adminType, ...props }) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  return (
    <Box display="flex" alignItems="center" className={classes.header}>
      <Box flexGrow={1}>
        <Typography variant="h5" style={{ marginTop: 15 }}>{`${t("Organizations")}`}</Typography>
      </Box>
      <SearchBox searchData={searchData} />
      {/* {adminType !== "practice_lead" && (
        <AddUpdateOrganization refreshOrganizations={refreshOrganizations} organizations={organizations} />
      )} */}
    </Box>
  )
}
