"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Every object can have one or more credential(s) associated with it. i.e. my_researcher.credentials = ['person A', 'person B', 'api A', 'person C', 'api B']
 */
var Credential = /** @class */ (function () {
    function Credential() {
    }
    return Credential;
}());
exports.Credential = Credential;
