import React from "react"
import useDetectClickAway from "../../../../../hooks/useDetectClickAway"
import { getUniqueElementId } from "../../../../../utils/getUniqueId"
import { Box, Icon, IconButton } from "@material-ui/core"
import NodeTextField from "./NodeTextField"
import SearchableSelect, { Option } from "./SearchableSelect"

export type SlotFieldProps = {
  children?: any
  options?: Option[]
  onChange?: (value: Option) => void
}

export default function SlotField(props: SlotFieldProps) {
  const { children, onChange, options = [] } = props
  const [displayOptions, setDisplayOptions] = React.useState(false)
  const itemFieldContainerRef = React.useRef<HTMLDivElement>(null)
  const id = getUniqueElementId()

  useDetectClickAway(itemFieldContainerRef, () => setDisplayOptions(false))

  const handleSearch = (search: string) => {
    console.log(search)
  }

  const handleChange = (option: Option) => {
    if (onChange) {
      onChange(option)
    }
    setDisplayOptions(false)
  }

  return (
    <div id={id} ref={itemFieldContainerRef} style={{ zIndex: displayOptions ? 2 : 1 }}>
      <Box position="relative" top={0}>
        {children ? (
          <NodeTextField onClick={() => setDisplayOptions(!displayOptions)}>{children}</NodeTextField>
        ) : (
          <IconButton
            onClick={() => setDisplayOptions(!displayOptions)}
            style={{ backgroundColor: "orange", color: "white", borderRadius: 12 }}
          >
            <Icon>{displayOptions ? "remove" : "add"}</Icon>
          </IconButton>
        )}

        {displayOptions && (
          <Box position="absolute" right="125%" top={0}>
            <SearchableSelect options={options} onSearch={handleSearch} onChange={handleChange} />
          </Box>
        )}
      </Box>
    </div>
  )
}
