"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * A specific sub-detail of a `ActivityEvent` that contains specific  interaction information that comprises the parent `ActivityEvent`.
 */
var TemporalEvent = /** @class */ (function () {
    function TemporalEvent() {
    }
    return TemporalEvent;
}());
exports.TemporalEvent = TemporalEvent;
/**
 * An event generated by the participant interacting with an `Activity`.
 */
var ActivityEvent = /** @class */ (function () {
    function ActivityEvent() {
    }
    return ActivityEvent;
}());
exports.ActivityEvent = ActivityEvent;
