import { Box, Button, Grid, Paper, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import { styled } from "@mui/material/styles"
import React from "react"
import { useTranslation } from "react-i18next"
import Header from "./Header"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      "& div.MuiInput-underline:before": { borderBottom: "0 !important" },
      "& div.MuiInput-underline:after": { borderBottom: "0 !important" },
      "& div.MuiInput-underline": {
        "& span.material-icons": {
          width: 21,
          height: 19,
          fontSize: 27,
          lineHeight: "23PX",
          color: "rgba(0, 0, 0, 0.4)",
          backgroundColor: "#F9FAFB",
        },
        "& button": { display: "none" },
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 80,
      },
    },
  })
)

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  alignItems: "stretch",
}))

const OverviewHeaderItem = ({ title, value, onClick, ...props }) => {
  return (
    <Grid item xs={4}>
      <Item>
        <Button
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
          onClick={onClick}
        >
          <Box p={2}>
            <Typography variant="h4">{value}</Typography>
            <Typography variant="subtitle1">{title}</Typography>
          </Box>
        </Button>
      </Item>
    </Grid>
  )
}

const OverviewItem = ({ title, value, ...props }) => {
  return (
    <Grid item xs={6}>
      <Item>
        <Grid container direction={"column"}>
          <Box
            style={{
              display: "grid",
              justifyContent: "flex-start",
              borderBottom: "1px solid #E5E5E5",
            }}
          >
            <Typography variant="subtitle2">{title}</Typography>
          </Box>
          <Box>{value}</Box>
        </Grid>
      </Item>
    </Grid>
  )
}

export default function Overview({
  numParticipants,
  numActivities,
  numSensors,
  numTriggers,
  researcherId,
  projectId,
  ...props
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <React.Fragment>
      <Header title={`${t("Overview")}`} style={{ backgroundColor: "#F9FAFB" }} />
      <Box className={classes.tableContainer} py={4} style={{ backgroundColor: "#F9FAFB" }}>
        <Grid container spacing={3} style={{ backgroundColor: "#F9FAFB" }}>
          <OverviewHeaderItem
            title={`${t("Question Sets")}`}
            value={numActivities}
            onClick={(event) => {
              window.location.href = `/#/researcher/${researcherId}/project/${projectId}/activities`
            }}
          />
          <OverviewHeaderItem
            title={`${t("Participants")}`}
            value={numParticipants}
            onClick={(event) =>
              (window.location.href = `/#/researcher/${researcherId}/project/${projectId}/participants`)
            }
          />
          <OverviewHeaderItem
            title={`${t("Triggers")}`}
            value={numTriggers}
            onClick={(event) => {
              window.location.href = `/#/researcher/${researcherId}/project/${projectId}/triggers`
            }}
          />
        </Grid>
      </Box>
    </React.Fragment>
  )
}
