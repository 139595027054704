import { Box, Button, Grid, Icon, IconButton } from "@material-ui/core"
import { Stack } from "@mui/material"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { specialCharacters } from "../../../../../data/specialChars"
import useValidation from "../../../../../hooks/useVal"
import { ActivityListFormProps } from "../../../../../types/activityListFormProps"
import { Question } from "../../../../../types/question"
import FormLayout from "./FormLayout"
import InputField from "./TextField"

export default function RatingForm(props: ActivityListFormProps) {
  const { question, onEditedQuestion, onEditedQuestionEntry } = props
  const { t } = useTranslation()
  const [formData, setFormData] = React.useState<Question>(question)
  const [tempForm, setTempForm] = React.useState<Question>()
  const { isValidated, addError, clearErrors } = useValidation()

  const handleSaveButtonClick = () =>
    onEditedQuestion?.({
      ...question,
      ...formData,
    })

  useEffect(() => {
    onEditedQuestionEntry?.({
      ...question,
      ...tempForm,
    })
  }, [tempForm])

  const handleAddOptionButtonClick = () => {
    const newOption = {
      value: "",
      description: "",
    }

    setTempForm({
      ...formData,
      options: formData.options === undefined ? [newOption] : [...formData.options, newOption],
    })
  }

  useEffect(() => {
    clearErrors()

    if (!formData.human_id) {
      addError(t("Question ID is required"))
    }

    if (specialCharacters.test(formData.human_id)) {
      addError(t("Question ID contains illegal characters"))
    }

    if (!formData.text) {
      addError(t("Question is required"))
    }

    ;(formData.options || []).forEach((option) => {
      if (!option.value) {
        addError(t("Option text is required"))
      } else if (isNaN(parseInt(option.value))) {
        addError(t("Option text must be a number"))
      }
    })
  }, [formData])

  // Update form data on question change
  useEffect(() => setFormData(question), [question])

  return (
    <FormLayout
      label={t("Specify your question")}
      description={t(
        "This allows you to ask a question which can be answered using a slider. This can be useful if you want to ask a question and get a numerical answer."
      )}
    >
      <Stack spacing={2} mt={4}>
        <div>
          <InputField
            label={t("Your question")}
            placeholder={t("Type your question here..")}
            style={{
              marginTop: "10px",
            }}
            value={formData.text}
            onChange={(e) => setTempForm({ ...formData, text: e.target.value })}
          />
        </div>

        <div>
          <InputField
            label={t("Question ID")}
            placeholder={t("Type your question ID here..")}
            style={{
              marginTop: "10px",
            }}
            value={formData.human_id}
            onChange={(e) => setTempForm({ ...formData, human_id: e.target.value })}
          />
        </div>

        <div>
          <InputField
            label={t("Description (optional)")}
            placeholder={t("Enter a description...")}
            style={{
              marginTop: "10px",
            }}
            value={formData.description}
            onChange={(e) => setTempForm({ ...formData, description: e.target.value })}
          />
        </div>
      </Stack>

      <Box mt={4}>
        {(formData.options || []).map((option, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={5}>
              <InputField
                label={t("Option Texts (numerical)")}
                style={{ marginTop: "10px" }}
                value={option.value}
                onChange={(e) => {
                  setTempForm((prev) => {
                    return {
                      ...prev,
                      options: prev.options?.map((a, i) => {
                        if (i === index) {
                          return { ...a, value: e.target.value }
                        }

                        return a
                      }),
                    }
                  })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label={t("Option Description")}
                style={{
                  marginTop: "10px",
                }}
                value={option.description}
                onChange={(e) => {
                  setTempForm(() => {
                    return {
                      ...formData,
                      options: formData.options.map((a, i) => {
                        if (i === index) {
                          return {
                            ...a,
                            description: e.target.value,
                          }
                        }

                        return a
                      }),
                    }
                  })
                }}
              />
            </Grid>

            <Grid item xs={1}>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
                <IconButton
                  onClick={() =>
                    setTempForm({
                      ...formData,
                      options: formData.options.filter((_, i) => i !== index),
                    })
                  }
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        ))}

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="outlined" size="large" onClick={handleAddOptionButtonClick}>
            {t("Add choice")}
          </Button>
        </Box>
      </Box>
    </FormLayout>
  )
}
