import { Box, Button, Grid, Icon, IconButton, Typography } from "@material-ui/core"
import { Stack } from "@mui/material"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { specialCharacters } from "../../../../../data/specialChars"
import useValidation from "../../../../../hooks/useVal"
import { ActivityListFormProps } from "../../../../../types/activityListFormProps"
import { ListOption, Question } from "../../../../../types/question"
import FormLayout from "./FormLayout"
import InputField from "./TextField"

export default function ListForm(props: ActivityListFormProps) {
  const { question, onEditedQuestion, onEditedQuestionEntry } = props
  const { t } = useTranslation()
  const [formData, setFormData] = React.useState<Question>(question)
  const [tempForm, setTempForm] = React.useState<Question>()
  const { isValidated, addError, clearErrors } = useValidation()

  const handleSaveButtonClick = () =>
    onEditedQuestion?.({
      ...question,
      ...formData,
    })

  useEffect(() => {
    onEditedQuestionEntry?.({
      ...question,
      ...tempForm,
    })
  }, [tempForm])

  const handleAddOptionButtonClick = () => {
    const newOption: ListOption = {
      human_id: "",
      value: "",
      description: "",
    }

    setTempForm({
      ...formData,
      options: formData.options === undefined ? [newOption] : [...formData.options, newOption],
    })
  }

  const updateOption = (index: number, key: string, value: string) => {
    setTempForm(() => {
      return {
        ...formData,
        options: formData.options.map((a, i) => {
          if (i === index) {
            return { ...a, [key]: value }
          }

          return a
        }),
      }
    })
  }

  // Check if the form is validated
  useEffect(() => {
    clearErrors()

    if (!formData.human_id) {
      addError(t("Question ID is required"))
    }

    if (specialCharacters.test(formData.human_id)) {
      addError(t("Question ID contains illegal characters"))
    }

    if (!formData.text) {
      addError(t("Question is required"))
    }

    ;(formData.options || []).forEach((option, index) => {
      if (!option.value) {
        addError(t("Option text is required on index {{index}}", { index }))
      } else if (isNaN(parseInt(option.value))) {
        addError(t("Option text must be a number on index {{index}}", { index }))
      }

      if (specialCharacters.test(option.human_id)) {
        addError(t("Question ID contains illegal characters"))
      }

      if (formData.options.filter((o) => o.value === option.value).length > 1) {
        addError(t("Option text must be unique"))
      }
    })
  }, [formData])

  // Update form data on question change
  useEffect(() => setFormData(question), [question])

  return (
    <FormLayout
      label={t("Specify your question")}
      description={t("A question that provides multiple possible answers. The user can select one or more answers.")}
    >
      <Stack spacing={2} mt={4}>
        <div>
          <InputField
            label={t("Your question")}
            placeholder={t("Type your question here..")}
            style={{
              marginTop: "10px",
            }}
            value={formData.text}
            onChange={(e) => {
              setTempForm({ ...formData, text: e.target.value })
            }}
          />
        </div>

        <div>
          <InputField
            label={t("Question ID")}
            placeholder={t("Type your question ID here..")}
            style={{
              marginTop: "10px",
            }}
            value={formData.human_id}
            onChange={(e) => setTempForm({ ...formData, human_id: e.target.value })}
          />
        </div>

        <div>
          <InputField
            label={t("Description (optional)")}
            placeholder={t("Enter a description...")}
            style={{
              marginTop: "10px",
            }}
            value={formData.description}
            onChange={(e) => setTempForm({ ...formData, description: e.target.value })}
          />
        </div>
      </Stack>

      <Box mt={4}>
        <Typography variant="h6">{t("Options")}</Typography>
        {(formData.options || []).map((option, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={4}>
              <Box height="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                <InputField
                  label={t("Answer option name (used in logical expressions)")}
                  style={{ marginTop: "10px" }}
                  value={option.human_id}
                  onChange={(e) => updateOption(index, "human_id", e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box height="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                <InputField
                  label={t("Option value (numerical)")}
                  style={{ marginTop: "10px" }}
                  value={option.value}
                  onChange={(e) => updateOption(index, "value", e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box height="100%" display="flex" justifyContent="flex-end" flexDirection="column">
                <InputField
                  label={t("Option label")}
                  style={{
                    marginTop: "10px",
                  }}
                  value={option.description}
                  onChange={(e) => updateOption(index, "description", e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box height="100%" display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center">
                <IconButton
                  onClick={() =>
                    setTempForm({
                      ...formData,
                      options: formData.options.filter((_, i) => i !== index),
                    })
                  }
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        ))}

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="outlined" size="large" onClick={handleAddOptionButtonClick}>
            {t("Add choice")}
          </Button>
        </Box>
      </Box>
    </FormLayout>
  )
}
