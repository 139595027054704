import { Backdrop, Box, Button, CircularProgress, Drawer, useMediaQuery, useTheme } from "@material-ui/core"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import dayjs from "dayjs"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import { Service } from "../../../DBService/DBService"
import {
  SchemaList,
  addActivity,
  saveCTestActivity,
  saveSurveyActivity,
  saveTipActivity,
  spliceActivity,
  updateActivityData,
  updateGroupSurvey,
} from "./ActivityMethods"
import ActivityPublishDialog from "./ActivityPublishDialog"
import ActivitySchedulerDialog from "./ActivitySchedulerDialog"
import GameCreator from "./GameCreator"
import GroupCreator from "./GroupCreator"
import Tips from "./Tips"

export const games = [
  "spark.jewels_a",
  "spark.jewels_b",
  "spark.spatial_span",
  "spark.cats_and_dogs",
  "spark.pop_the_bubbles",
  "spark.balloon_risk",
]

const sparkActivities = [
  "spatial_span",
  "cats_and_dogs",
  "jewels_a",
  "jewels_b",
  "dbt_diary_card",
  "balloon_risk",
  "pop_the_bubbles",
  "journal",
  "breathe",
  "recording",
  "survey",
  "scratch_image",
  "tips",
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    backbtnlink: {
      width: 48,
      height: 48,
      color: "rgba(0, 0, 0, 0.54)",
      padding: 12,
      borderRadius: "50%",
      "&:hover": { background: "rgba(0, 0, 0, 0.04)" },
    },
    toolbardashboard: {
      minHeight: 100,
      padding: "0 10px",
      "& h5": {
        color: "rgba(0, 0, 0, 0.75)",
        textAlign: "left",
        fontWeight: "600",
        fontSize: 30,
        width: "calc(100% - 96px)",
      },
    },
    dividerHeader: {
      marginTop: 0,
    },
    researcherMenu: {
      background: "#ffffff",
      maxWidth: "100%",
      border: 0,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flexDirection: "row",
      },
      "& span": { fontSize: 12 },
      "& div.Mui-selected": {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        "& path": { fill: theme.palette.primary.main },
      },
    },
    logResearcher: {
      zIndex: 1111,
      [theme.breakpoints.up("md")]: {
        height: "50px",
        width: "100%",
        flexDirection: "row",
        marginTop: 51,
        marginBottom: 5,
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "50px",
        width: "100%",
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,
      },
      outline: "#D0D5DD solid 1px",
    },
  })
)

const toBinary = (string) => {
  const codeUnits = new Uint16Array(string.length)
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i)
  }
}
const defaultBase64 = toBinary("data:image/png;base64,")

export default function Activity({
  id,
  type,
  projectId,
  researcherId,
  ...props
}: {
  id?: string
  type?: string
  projectId?: string
  researcherId?: string
}) {
  const [loading, setLoading] = useState(true)
  const [activity, setActivity] = useState(null)
  const [study, setStudy] = useState(null)
  const [allActivities, setAllActivities] = useState(null)
  const [details, setDetails] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()
  const supportsSidebar = useMediaQuery(useTheme().breakpoints.up("md"))
  const [activitySchedulerDialog, setActivitySchedulerDialog] = useState(false)
  const [activityPublishDialog, setActivityPublishDialog] = useState(false)

  useEffect(() => {
    setLoading(true)
    SPARK.Study.view(projectId).then((study) => {
      setStudy(study)
      SPARK.Activity.allByStudy(study.id).then((activities: any[]) => {
        // Set the projectId to the activity
        activities.map((activity) => (activity.study_id = projectId))

        setAllActivities(activities)
        if (!!id) {
          SPARK.Activity.view(id)
            .then((activity: any) => {
              activity.study_id = study.id
              setActivity(activity)
            })
            .catch((error: any) => {
              enqueueSnackbar(`${t("Failed to retrieve Survey." + error)}`, {
                variant: "error",
              })
            })
        }
        setLoading(false)
      })
    })
  }, [])

  useEffect(() => {
    if (!!activity && details === null) {
      ;(async () => {
        let data = await SPARK.Activity.view(activity.id)
        activity.settings = data.settings
        if ((activity.spec ?? "").includes("spark.survey")) {
          let tag = [await SPARK.Type.getAttachment(activity.id, "spark.dashboard.survey_description")].map((y: any) =>
            !!y.error ? undefined : y.data
          )[0]
          let dataActivity = spliceActivity({ raw: activity, tag: tag })
          setActivity(dataActivity)
          setDetails(tag ?? [])
        } else if (activity.spec === "spark.tips") {
          activity.settings = activity.settings.reduce((ds, d) => {
            let newD = d
            if (d.image === "") {
              newD = Object.assign({}, d, { image: defaultBase64 })
            }
            return ds.concat(newD)
          }, [])
          let tag = [await SPARK.Type.getAttachment(activity.id, "spark.dashboard.activity_details")].map((y: any) =>
            !!y.error ? undefined : y.data
          )[0]
          setActivity(activity)
          setDetails(tag ?? [])
        } else {
          if (activity.spec === "spark.breathe" && activity.settings?.audio === null) {
            delete activity.settings?.audio
          }
          let tag = [await SPARK.Type.getAttachment(activity.id, "spark.dashboard.activity_details")].map((y: any) =>
            !!y.error ? undefined : y.data
          )[0]
          setDetails(tag ?? [])
        }
        setLoading(false)
      })()
    }
  }, [activity])

  const addOrUpdateActivities = () => {
    //refreshTriggers()
    setActivitySchedulerDialog(false)
    setActivityPublishDialog(false)
  }

  const saveActivity = async (x) => {
    setLoading(true)
    // TODO: are description settings saved accordingly?
    let newItem =
      x.spec === "spark.survey" || x.spec === "spark.survey.hidden"
        ? await saveSurveyActivity(x)
        : x.spec === "spark.tips"
        ? await saveTipActivity(x)
        : await saveCTestActivity(x)
    if (!!newItem.error) {
      setLoading(false)
      enqueueSnackbar(`${t("Failed to create a new Activity.")}`, {
        variant: "error",
      })
    } else {
      x["id"] = newItem["data"]
      updateDb(x)
      setLoading(false)
      enqueueSnackbar(`${t("Successfully created a new Activity.")}`, {
        variant: "success",
      })
      history.back()
    }
  }

  const updateDb = (x) => {
    addActivity(x, study)
    setLoading(false)
  }

  // Commit an update to an Activity object (ONLY DESCRIPTIONS).
  const updateActivity = async (x, isDuplicated) => {
    setLoading(true)
    const expressions = x.settings.filter((q) => q.expression !== "")

    if (x.spec === "group.survey" || expressions.length > 0) {
      if (x.spec === "spark.survey") {
        // Create a new group.survey
        await saveSurveyActivity(x)
        // Remove the old instance
        SPARK.Activity.delete(x.id)
      } else {
        // Update the group.survey instance and its associated triggers
        updateGroupSurvey(x)
      }
      history.back()
    } else {
      let result = await updateActivityData(x, isDuplicated, activity)
      if (!!result.error)
        enqueueSnackbar(`${t("Encountered an error: .")}` + result?.error, {
          variant: "error",
        })
      else {
        if (isDuplicated || (!x.id && x.name)) {
          x["id"] = result.data
          addActivity(x, study)
          enqueueSnackbar(`${t("Successfully duplicated the Activity.")}`, {
            variant: "success",
          })
          history.back()
        } else {
          x["study_id"] = x.studyID
          x["study_name"] = study?.name
          delete x["studyID"]
          Service.updateMultipleKeys("activities", { activities: [x] }, Object.keys(x), "id")
          enqueueSnackbar(`${t("Successfully updated the Activity.")}`, {
            variant: "success",
          })
          history.back()
        }
      }
    }
    setLoading(false)
  }

  const renderContent = () => {
    if (!type) {
      return (
        <GameCreator
          activities={allActivities}
          value={activity ?? null}
          study={study}
          activitySpecId={
            activity?.spec === "group.survey"
              ? "spark.survey"
              : !!type
              ? Object.keys(SchemaList()).includes("spark." + type)
                ? "spark." + type
                : type
              : activity.spec ?? activity.spec
          }
        />
      )
    }

    if (type === "tips" || activity.spec.includes("spark.tips")) {
      return (
        <Tips
          value={activity}
          details={null}
          onSave={activity && activity?.id ? updateActivity : saveActivity}
          study={study}
          allActivities={allActivities}
        />
      )
    }

    if (type === "group" || activity.spec === "spark.group") {
      return (
        <GroupCreator
          activities={allActivities}
          value={activity ?? null}
          onSave={!!type ? saveActivity : updateActivity}
          study={study}
          details={null}
        />
      )
    }

    throw new Error("Invalid activity type")
  }

  return (
    <Box position="absolute" top="45px" bottom={0} width="100%">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && !!study && (!!type || !!activity) && (
        <Box height="100%">
          <Drawer
            anchor={supportsSidebar ? "left" : "bottom"}
            variant="permanent"
            classes={{
              paper: classes.researcherMenu + " " + classes.logResearcher,
            }}
          >
            <p
              style={{
                fontSize: 18,
                marginLeft: 30,
                marginTop: 6.75,
                marginBottom: 6.75,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              onClick={() => {
                window.location.href = `/#/researcher/${researcherId}/project/${projectId}/activities`
              }}
            >
              <u>{study?.name}</u>
            </p>
            <p
              style={{
                fontSize: 20,
                marginLeft: 6,
                marginTop: 6.75,
                marginBottom: 6.75,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                minWidth: 145,
              }}
            >
              <b>{activity?.name}</b>
            </p>

            {
              //TODO: check role?
              <Button
                variant={"contained"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: "#fafafa",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                // onClick={(event) => (window.location.href = `/#/researcher/${researcherId}/project/${id}/overview`)}
              >
                {`${t("Create")}`}
              </Button>
            }
            {
              //TODO: check role?
              <Button
                variant={"text"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                // onClick={(event) => {
                //   window.location.href = `/#/researcher/${researcherId}/project/${id}/activities`
                // }}
              >
                {`${t("Results")}`}
              </Button>
            }
            {
              //TODO: check role?
              <Button
                variant={"outlined"}
                disableElevation={false}
                disabled={true}
                style={{
                  marginLeft: "auto",
                  marginRight: 6,
                  height: 25,
                  marginTop: 12.5,
                  marginBottom: 12.5,
                  borderRadius: 15,
                  padding: "0px 10px",
                  fontSize: 14,
                  backgroundColor:
                    activity.schedule &&
                    activity.schedule.length > 0 &&
                    dayjs(activity.schedule[0]?.start_date) <= dayjs(new Date()) &&
                    !activity.schedule[0]?.end_date
                      ? "#ECFDF3"
                      : "#F9FAFB",
                  borderColor:
                    activity.schedule &&
                    activity.schedule.length > 0 &&
                    dayjs(activity.schedule[0]?.start_date) <= dayjs(new Date()) &&
                    !activity.schedule[0]?.end_date
                      ? "#ABEFC6"
                      : "#EAECF0",
                  outlineColor:
                    activity.schedule &&
                    activity.schedule.length > 0 &&
                    dayjs(activity.schedule[0]?.start_date) <= dayjs(new Date()) &&
                    !activity.schedule[0]?.end_date
                      ? "#ABEFC6"
                      : "#EAECF0",
                  color:
                    activity.schedule &&
                    activity.schedule.length > 0 &&
                    dayjs(activity.schedule[0]?.start_date) <= dayjs(new Date()) &&
                    !activity.schedule[0]?.end_date
                      ? "#067647"
                      : "#717BBC",
                  boxShadow: "0px 0px 0px 0px",
                }}
              >
                {!activity.schedule || activity.schedule.length <= 0
                  ? `${t("Concept")}`
                  : dayjs(activity.schedule[0]?.start_date) <= dayjs(new Date()) && !activity.schedule[0]?.end_date
                  ? `${t("Published")}`
                  : `${t("Scheduled")}`}
              </Button>
            }
            {localStorage.getItem("mode") !== "other" && ( //TODO: check role?
              <Button
                variant="contained"
                tabIndex={-1}
                disableElevation={true}
                style={{
                  marginLeft: 6,
                  marginRight: 6,
                  color: "#fff",
                  backgroundColor: "#EE9142",
                  border: "1px solid #E0E0E0",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                  borderRadius: "8px",
                }}
                onClick={(event) => {
                  setActivityPublishDialog(true)
                }}
              >
                {`${t("Publish")}`}
              </Button>
            )}
            {localStorage.getItem("mode") !== "other" && ( //TODO: check role?
              <Button
                variant="contained"
                tabIndex={-1}
                disableElevation={true}
                style={{
                  marginLeft: 6,
                  marginRight: 35,
                  color: "#000",
                  backgroundColor: "#fff",
                  border: "1px solid #E0E0E0",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                  borderRadius: "8px",
                }}
                onClick={(event) => {
                  setActivitySchedulerDialog(true)
                }}
              >
                {`${t("Schedule")}`}
              </Button>
            )}
          </Drawer>
          {renderContent()}
          <ActivitySchedulerDialog
            projectId={projectId}
            onClose={() => setActivitySchedulerDialog(false)}
            open={activitySchedulerDialog}
            type=""
            addOrUpdateActivity={addOrUpdateActivities}
            closeDialog={() => setActivitySchedulerDialog(false)}
            closeActivity={() => {
              window.location.href = `/#/researcher/${researcherId}/project/${projectId}/activities`
            }}
            activity={activity}
            researcherId={researcherId}
          />
          <ActivityPublishDialog
            projectId={projectId}
            onClose={() => setActivityPublishDialog(false)}
            open={activityPublishDialog}
            type=""
            addOrUpdateActivity={addOrUpdateActivities}
            closeDialog={() => setActivityPublishDialog(false)}
            closeActivity={() => {
              window.location.href = `/#/researcher/${researcherId}/project/${projectId}/activities`
            }}
            activity={activity}
            researcherId={researcherId}
          />
        </Box>
      )}
    </Box>
  )
}
