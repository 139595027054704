import { Box, Fab, Icon, Theme, createStyles, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import SensorDialog from "./SensorDialog"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      background: theme.palette.primary.main,
      borderRadius: "8px",
      minWidth: 100,
      boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
      lineHeight: "38px",
      cursor: "pointer",
      textTransform: "capitalize",
      fontSize: "14px",
      color: "#fff",
      "& svg": { marginRight: 8 },
      "&:hover": { background: theme.palette.primary.main },
    },
    popexpand: {
      backgroundColor: "#fff",
      color: theme.palette.primary.main,
      zIndex: 11111,
      "& path": { fill: theme.palette.primary.main },
      "&:hover": { backgroundColor: "#f3f3f3" },
    },
    addText: {
      padding: "0px 2px 0px 2px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
)

export default function AddSensor({ projectId, setSensors, allSensors, ...props }) {
  const [dialog, setDialog] = useState(false)
  const { t } = useTranslation()
  const classes = useStyles()

  const handleClosePopUp = () => {
    setSensors()
    setDialog(false)
  }

  return (
    <Box>
      <Fab variant="extended" color="primary" classes={{ root: classes.btn }} onClick={(event) => setDialog(true)}>
        <Icon>add</Icon> <span className={classes.addText}>{`${t("Add sensor")}`}</span>
      </Fab>
      <SensorDialog
        projectId={projectId}
        onClose={() => setDialog(false)}
        open={dialog}
        type="add"
        addOrUpdateSensor={handleClosePopUp}
        allSensors={allSensors}
      />
    </Box>
  )
}
