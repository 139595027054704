import { Box, Button, Typography } from "@material-ui/core"
import React from "react"

export interface IFormLayoutProps {
  label: string
  description: string
  children?: React.ReactNode
}

export default function FormLayout(props: IFormLayoutProps) {
  const { label, description, children } = props

  return (
    <form action="post" method="#">
      <Box>
        <Typography variant="h5">{label}</Typography>
        <Typography>{description}</Typography>
      </Box>

      {children}
    </form>
  )
}
