import { Node } from "reactflow"
import { nodeTypeKeys } from "../data/node_type_keys"

export const calcNodePositionRelativeToParent = (node: Node, parentNode: Node): { x: number; y: number } => {
  const widthDiff = parentNode.width - node.width

  return {
    x: parentNode.position.x + widthDiff / 2,
    y: parentNode.position.y + (node.type === nodeTypeKeys.SLOT ? parentNode.height / 2 : 0),
  }
}
