import { Box, Icon, IconButton, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { ReactSortable } from "react-sortablejs"

const REACT_SORTABLE_GROUP_NAME = `react_sortable_group`

// Layout container for displaying a stack of items. Each item can have a substack of items.
export default function ItemsStackLayout(props: {
  title: string
  onClickAddButton: () => void
  children: React.ReactNode
}) {
  const { title, onClickAddButton, children } = props

  return (
    <Box borderRight={{ xs: "none", md: "#EAECF0 1px solid" }} height="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" p="16px">
        <Typography variant="h5">{title}</Typography>

        <IconButton style={{ backgroundColor: "#FD8B28", borderRadius: 10 }} onClick={onClickAddButton}>
          <Icon style={{ color: "white" }}>add</Icon>
        </IconButton>
      </Box>

      {children}
    </Box>
  )
}

function Item(props: {
  icon: React.ReactNode
  label: string
  children?: React.ReactNode
  allowSubstack?: boolean
  onEdit?: () => void
  onDelete?: () => void
}) {
  const { icon, label, allowSubstack = false, children, onDelete, onEdit } = props
  const [displaySubstack, setDisplaySubstack] = React.useState(allowSubstack)

  return (
    <Box pr="16px">
      <Box display="flex" width="100%" mt={1}>
        <Box display="flex" alignItems="center" height="100%" width="16px" marginY="auto">
          {children !== undefined && allowSubstack && (
            // Display substack toggle button
            <Icon onClick={() => setDisplaySubstack((prev) => !prev)} style={{ fontSize: "16px" }}>
              {displaySubstack ? "expand_less" : "expand_more"}
            </Icon>
          )}
        </Box>

        {/* Main item data */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          border="#EAECF0 1px solid"
          bgcolor="#F9FAFB"
          borderRadius={10}
          px={2}
          py={2}
          width="100%"
          gridGap={12}
          position="relative"
          top="0"
        >
          {icon}
          <Box flex="1 1 0%">
            <Typography>{label}</Typography>
          </Box>

          {/* Button triggers a click event, while not interfering with options menu */}
          <button
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              opacity: 0,
              zIndex: -1,
            }}
            onClick={() => setDisplaySubstack((prev) => !prev)}
          ></button>

          <OptionsMenu onClickEdit={onEdit} onClickDelete={onDelete} />
        </Box>
      </Box>

      {displaySubstack && children}
    </Box>
  )
}

function OptionsMenu(props: { onClickEdit?: () => void; onClickDelete?: () => void }) {
  const { onClickEdit, onClickDelete } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { t } = useTranslation()

  const displayMenu = Boolean(anchorEl)
  const options = [
    { label: t("Edit"), onClick: onClickEdit },
    { label: t("Delete"), onClick: onClickDelete },
  ]

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <IconButton onClick={handleClick}>
        <Icon>more_vert</Icon>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={displayMenu}
        onClose={handleClose}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              option.onClick?.()
              handleClose()
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

/**
 * Stack of items that can be sorted by dragging and dropping.
 *
 * Component extends the ReactSortable component from react-sortablejs.
 * This library does not have typescript support, so props are extends to any types.
 * Only the props that are necessary for the component to run are defined.
 *
 * For all available options provided by ReactSortable visit https://github.com/SortableJS/Sortable#options, also be sure to also check the docs thoroughly.
 */
function ItemsStack(
  props: {
    children?: React.ReactNode
    list: ({ id: number } & any)[]
    setList: React.Dispatch<React.SetStateAction<({ id: number } & any)[]>>
    sortable: boolean
    variant?: "default" | "substack"
  } & any
) {
  const { children, list, setList, style = {}, sortable = true, variant, blockIndex, ...restProps } = props

  return (
    <ReactSortable
      group={REACT_SORTABLE_GROUP_NAME}
      list={list}
      disabled={!sortable}
      setList={setList}
      style={{
        ...{
          marginTop: variant === "substack" ? 0 : 10,
          marginLeft: variant === "substack" ? 50 : 0,
          marginRight: variant === "substack" ? -16 : 0,
          maxHeight: "75vh",
          overflowY: "auto",
          overflowX: "visible",
        },
        ...style,
      }}
      {...restProps}
    >
      {children}
    </ReactSortable>
  )
}

ItemsStackLayout.Item = Item
ItemsStackLayout.Stack = ItemsStack
