import { Box, Button, createStyles, makeStyles } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Service } from "../../../DBService/DBService"
import TriggerDialog from "./TriggerDialog"

const useStyles = makeStyles((theme) =>
  createStyles({
    btnBlue: {
      marginLeft: 10,
      marginRight: 35,
      color: "#000",
      backgroundColor: "#fff",
      border: "1px solid #E0E0E0",
      marginTop: 6.75,
      marginBottom: 6.75,
      borderRadius: "8px",
    },
    addText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
)
export function addTriggerItem(x, studies) {
  Service.updateCount("studies", x.studyID, "trigger_count")
  x["study_id"] = x.studyID
  x["study_name"] = studies.filter((study) => study.id === x.studyID)[0]?.name
  delete x["studyID"]
  Service.addData("triggers", [x])
}
export default function AddTrigger({
  projectId,
  refreshTriggers,
  triggers,
  styleType = false,
  ...props
}: {
  projectId?: string
  refreshTriggers?: Function
  triggers?: Array<any>
  styleType?: boolean
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [triggerDialog, setTriggerDialog] = useState(false)

  return (
    <Box>
      <Button
        variant="contained"
        tabIndex={-1}
        startIcon={styleType ? null : <Add />}
        endIcon={styleType ? <Add /> : null}
        disableElevation={true}
        style={
          styleType
            ? {
                marginTop: "20px",
                borderRadius: "10px",
                boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
                fontSize: "14px",
                color: "#fff",
                backgroundColor: "#EE9142",
              }
            : {
                marginLeft: 6,
                marginRight: 35,
                color: "#000",
                backgroundColor: "#fff",
                border: "1px solid #E0E0E0",
                marginTop: 6.75,
                marginBottom: 6.75,
                borderRadius: "8px",
              }
        }
        onClick={(event) => {
          setTriggerDialog(true)
        }}
      >
        {styleType ? t("New decision tree builder") : t("Add decision tree builder")}
      </Button>
      <TriggerDialog
        projectId={projectId}
        onClose={() => setTriggerDialog(false)}
        open={triggerDialog}
        type="add"
        triggers={triggers}
      />
    </Box>
  )
}
