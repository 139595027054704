import { Activity } from "spark-core"
import { Action } from "../../../../../../types/action"
import { Question } from "../../../../../../types/question"
import { nodeTypeKeys } from "../data/node_type_keys"
import { CustomNodeData } from "../types/CustomNodeData"

export class NodeFactory {
  static createNode(type: string, data: any) {
    const config = {
      [nodeTypeKeys.EVAL]: { payload: null, options: [] } as CustomNodeData<Question>,
      [nodeTypeKeys.DATA_SOURCE]: { payload: null, options: [] } as CustomNodeData<any>,
      [nodeTypeKeys.ACTION]: { payload: null, options: [] } as CustomNodeData<Action>,
      [nodeTypeKeys.ACTIVITY]: { payload: null, options: [] } as CustomNodeData<Activity>,
      [nodeTypeKeys.INITIAL]: {},
      [nodeTypeKeys.SLOT]: {},
    }[type]

    if (!config) {
      throw new Error(`Unknown node type: ${type}`)
    }

    return {
      id: Math.random().toString(),
      type,
      deletable: false,
      data: config,
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      ...data,
    }
  }
}
