import { Box, Button, Icon, IconButton, Paper } from "@material-ui/core"
import React from "react"
import { Handle, Position } from "reactflow"
import OptionsToolbar, { TriggerButton } from "../OptionsToolbar"
import SlotField from "../SlotField"
import { Question } from "../../../../../../types/question"
import {
  QUESTION_TYPE_BOOLEAN_ID,
  QUESTION_TYPE_LIKE_ID,
  QUESTION_TYPE_LIST_ID,
  QUESTION_TYPE_MULTI_SELECT_ID,
  QUESTION_TYPE_RATING_ID,
  QUESTION_TYPE_SHORT_ANSWER_ID,
  QUESTION_TYPE_SLIDER_ID,
  QUESTION_TYPE_TIME_ID,
} from "../../../../../../data/questionTypes"
import NodeTextField from "../NodeTextField"
import { getUniqueElementId } from "../../../../../../utils/getUniqueId"

type Answer = {
  id: number
  label: string
}

type EvalNodeData = {
  question: Question | null
  comparisonOperator: string | null
  answer: Answer | null
}

export default function EvalNode(props) {
  const { data } = props

  const [selectedData, setSelectedData] = React.useState<EvalNodeData>(() => {
    return {
      question: null,
      comparisonOperator: "==",
      answer: null,
      ...data.payload,
    }
  })

  const [displayToolbar, setDisplayToolbar] = React.useState(false)

  const updateSelectedData = (key: keyof EvalNodeData, value: any) =>
    setSelectedData((prev) => ({ ...prev, [key]: value }))

  React.useEffect(() => {
    data.onChange?.(props.id, selectedData)
  }, [selectedData])

  return (
    <>
      <Box position="relative" top="0" width="280px" height="140px">
        <Handle type="target" position={Position.Top} isConnectable={false} />

        {displayToolbar && (
          <OptionsToolbar onEdit={data.onEdit} onDelete={data.onDelete} onCancel={() => setDisplayToolbar(false)} />
        )}

        <Box
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          top={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box style={{ transform: "scaleY(0.5) scaleX(0.9)" }}>
            <Box
              bgcolor="#B3B8DB"
              width="200px"
              border={displayToolbar ? "2px #182230 dashed" : "1px #182230 solid"}
              style={{ transform: "rotate(45deg)", aspectRatio: "1 / 1" }}
            />
          </Box>
        </Box>

        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position="relative"
          top="0"
          gridGap={4}
        >
          <TriggerButton onClick={() => setDisplayToolbar((prev) => !prev)} />
          <SelectedQuestion
            question={selectedData.question}
            questions={data.options}
            onChange={(question) => updateSelectedData("question", question)}
          />
          <SelectedComparisonOperator
            comparisonOperator={selectedData?.comparisonOperator}
            question={selectedData?.question}
            onChange={(comparisonOperator) => updateSelectedData("comparisonOperator", comparisonOperator)}
          />
          <SelectedAnswer
            answer={selectedData?.answer}
            answers={selectedData?.question?.options ?? []}
            selectedQuestion={selectedData?.question}
            onChange={(answer) => updateSelectedData("answer", answer)}
          />
        </Box>
        <Handle type="source" id="eval-handle-r" position={Position.Right} isConnectable={false} />
        <Handle type="source" id="eval-handle-l" position={Position.Left} isConnectable={false} />
      </Box>
    </>
  )
}

function SelectedQuestion(props: {
  question: Question
  questions: Question[]
  onChange: (question: Question) => void
}) {
  const { question, questions, onChange } = props

  const options = questions.map((question) => ({
    id: question.id as number,
    label: question.text,
  }))

  const handleChange = (val) => {
    const selectedQuestion = questions.find((question) => question.id === val.id)

    if (selectedQuestion) {
      onChange(selectedQuestion)
    }
  }

  return (
    <SlotField options={options} onChange={handleChange}>
      {question?.text}
    </SlotField>
  )
}
function SelectedComparisonOperator(props: {
  comparisonOperator: EvalNodeData["comparisonOperator"]
  question: EvalNodeData["question"]
  onChange?: (comparisonOperator: EvalNodeData["comparisonOperator"]) => void
}) {
  const { comparisonOperator, question, onChange } = props

  const getComparisonOperators = (questionTypeId) => {
    let comparisonOperators = []

    switch (questionTypeId) {
      case QUESTION_TYPE_BOOLEAN_ID:
      case QUESTION_TYPE_LIST_ID:
        comparisonOperators = ["==", "!="]
        break
      case QUESTION_TYPE_SHORT_ANSWER_ID:
        comparisonOperators = []
        break
      case QUESTION_TYPE_MULTI_SELECT_ID:
        comparisonOperators = ["==", "!="]
        break
      case QUESTION_TYPE_SLIDER_ID:
      case QUESTION_TYPE_RATING_ID:
      case QUESTION_TYPE_TIME_ID:
      case QUESTION_TYPE_LIKE_ID:
        comparisonOperators = [">", "<", "==", ">=", "<=", "!="]
        break
    }

    return comparisonOperators
  }

  const options = getComparisonOperators(question?.question_type_id).map((operator) => {
    return {
      id: operator,
      label: operator,
    }
  })

  return (
    <SlotField onChange={(val) => onChange(val.label)} options={options}>
      {comparisonOperator}
    </SlotField>
  )
}

function SelectedAnswer(props: {
  answer: Answer
  selectedQuestion: Question
  answers: any[]
  onChange: (answer: Answer) => void
}) {
  const { onChange, answer, answers, selectedQuestion } = props

  let options = []

  if (selectedQuestion?.question_type_id === QUESTION_TYPE_BOOLEAN_ID) {
    options = [
      { id: 0, label: "TRUE" },
      { id: 1, label: "FALSE" },
    ]
  } else {
    // Format options
    options = answers.map((answer) => {
      return {
        id: answer.id,
        label: answer.value ?? answer.text,
      }
    })
  }

  // Displays text field instead of set options
  if (selectedQuestion?.question_type_id === QUESTION_TYPE_SHORT_ANSWER_ID) {
    const handleInputBlur = (e) => {
      onChange({ id: 0, label: e.target.textContent })
    }

    const id = getUniqueElementId()

    return (
      <NodeTextField onClick={() => document.getElementById(id).focus()}>
        <div
          id={id}
          contentEditable={true}
          suppressContentEditableWarning={true}
          style={{ pointerEvents: "none" }}
          onBlur={handleInputBlur}
        >
          {answer?.label}
        </div>
      </NodeTextField>
    )
  }

  return (
    <SlotField options={options} onChange={onChange}>
      {answer?.label}
    </SlotField>
  )
}
