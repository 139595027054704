import { Box, Button, Grid, Icon, IconButton, Theme, createStyles, makeStyles } from "@material-ui/core"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import dayjs from "dayjs"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import ConfirmationDialog from "../../../ConfirmationDialog"
import Pagination from "../../../PaginatedElement"
import EmptyIcon from "../../../shared/EmptyIcon"
import ActivityDialog from "./ActivityDialog"
import AddActivity from "./AddActivity"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: "#fff",
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    tableContainer: {
      "& div.MuiInput-underline:before": { borderBottom: "0 !important" },
      "& div.MuiInput-underline:after": { borderBottom: "0 !important" },
      "& div.MuiInput-underline": {
        "& span.material-icons": {
          width: 21,
          height: 19,
          fontSize: 27,
          lineHeight: "23PX",
          color: "rgba(0, 0, 0, 0.4)",
        },
        "& button": { display: "none" },
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 80,
      },
    },
    norecordsmain: {
      minHeight: "calc(100% - 114px)",
      position: "absolute",
    },
    norecords: {
      "& span": { marginRight: 5 },
    },
    activityMain: {
      background: "#fff",
      borderRadius: 12,
      minWidth: "312.25px",
      width: "100%",
      minHeight: "100px",
      border: "1px solid #EEEEEE",
    },
  })
)

export const availableActivitySpecs = [
  "spark.group",
  "spark.survey",
  "spark.journal",
  "spark.jewels_a",
  "spark.jewels_b",
  "spark.breathe",
  "spark.spatial_span",
  "spark.tips",
  "spark.cats_and_dogs",
  "spark.scratch_image",
  "spark.dbt_diary_card",
  "spark.pop_the_bubbles",
  "spark.balloon_risk",
  "spark.recording",
]
export const games = [
  "spark.jewels_a",
  "spark.jewels_b",
  "spark.spatial_span",
  "spark.cats_and_dogs",
  "spark.pop_the_bubbles",
  "spark.balloon_risk",
]
export default function ActivityList({
  activities,
  getAllActivities,
  setOrder,
  order,
  mode,
  projectId,
  researcherId,
  ...props
}) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [selectedActivities, setSelectedActivities] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [paginatedActivities, setPaginatedActivities] = useState([])
  const [allActivities, setAllActivities] = useState(null)
  const [rowCount, setRowCount] = useState(40)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState(null)

  useEffect(() => {
    let params = JSON.parse(localStorage.getItem("activities"))
    setPage(params?.page ?? 0)
    setRowCount(params?.rowCount ?? 40)
  }, [])

  const handleChange = (activity, checked) => {
    if (checked) {
      setSelectedActivities((prevState) => [...prevState, activity])
    } else {
      let selected = selectedActivities.filter((item) => item.id != activity.id)
      setSelectedActivities(selected)
    }
  }

  const searchActivities = (searchVal?: string) => {
    const searchTxt = searchVal ?? search
    let result = []
    getAllActivities().then((activitiesData) => {
      activitiesData = activitiesData.filter((a) => a.spec !== "group.survey.question")
      setAllActivities(activitiesData)
      result = result.concat(activitiesData)
      if (!!searchTxt && searchTxt.trim().length > 0) {
        result = result.filter((i) => i.name?.toLowerCase()?.includes(searchTxt?.toLowerCase()))
      }
      setPaginatedActivities(result.slice(page * rowCount, page * rowCount + rowCount))
      setLoading(false)
    })
    setSelectedActivities([])
  }

  const handleSearchData = (val: string) => {
    setSearch(val)
    searchActivities(val)
  }

  const handleChangePage = (page: number, rowCount: number) => {
    setLoading(true)
    setRowCount(rowCount)
    setPage(page)
    localStorage.setItem("activities", JSON.stringify({ page: page, rowCount: rowCount }))
    setPaginatedActivities(activities.slice(page * rowCount, page * rowCount + rowCount))
    setLoading(false)
  }

  return (
    <React.Fragment>
      {activities !== null && (activities || []).length > 0 && (
        <Box>
          <Box className={classes.tableContainer} py={4}>
            <Grid container spacing={2} lg="auto" direction="row">
              {(paginatedActivities || []).map((item, index) => (
                <ActivityItem
                  activity={item}
                  activities={activities}
                  index={index}
                  projectId={projectId}
                  researcherId={researcherId}
                  handleRefresh={() => searchActivities(search)}
                />
              ))}
              <Pagination
                data={activities}
                updatePage={handleChangePage}
                rowPerPage={[20, 40, 60, 80]}
                currentPage={page}
                currentRowCount={rowCount}
              />
            </Grid>
          </Box>
        </Box>
      )}
      {activities === null ||
        ((activities || []).length === 0 && (
          <Box
            py={4}
            style={{
              position: "relative",
              top: "50%",
              textAlign: "center",
              display: "block",
            }}
          >
            <Box
              style={{
                display: "inline-block",
                margin: "auto",
                padding: "10px 20px",
                borderRadius: "5px",
                width: "400px",
              }}
            >
              <EmptyIcon />

              {/* Description section */}
              <div>
                <br />
                <br />
                <span>
                  <b>{`${t("No Question Set found")}`}</b>
                </span>
                <br />
                <span>{"There are no Question Set here yet. Create a new Question Set and start your project."}</span>
                <br />
                <AddActivity projectId={projectId} styleType={true} researcherId={researcherId} />
              </div>
            </Box>
          </Box>
        ))}
    </React.Fragment>
  )
}

const ActivityItem = ({ activity, activities, index, projectId, researcherId, handleRefresh, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [activityDialog, setActivityDialog] = useState(false)
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false)

  const onUpdateActivity = () => {
    handleRefresh()
    setActivityDialog(false)
  }

  const deleteActivity = (action) => {
    if (action === "Yes") {
      SPARK.Activity.delete(activity.id).then((res: any) => {
        if (res.error) {
          enqueueSnackbar(`${t("Something went wrong when deleting the Survey.")}`, { variant: "error" })
        } else {
          enqueueSnackbar(`${t("Successfully deleted the Survey.")}`, { variant: "success" })
          handleRefresh()
        }
      })
    }
    setDeleteConfirmationDialog(false)
  }

  const onClickEdit = () => {
    handleCloseMenu()
    setActivityDialog(true)
  }

  const onClickDelete = () => {
    handleCloseMenu()
    setDeleteConfirmationDialog(true)
  }

  const menuOptions = [
    { label: t("Edit Question Set Metadata"), onClick: onClickEdit },
    { label: t("Delete Question Set and all included questions"), onClick: onClickDelete },
  ]

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const displayMenu = Boolean(anchorEl)
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)

  return (
    <>
      <Grid item xs={12} lg={6} key={index}>
        <Box display="flex" p={1} className={classes.activityMain} sx={{ alignItems: "center" }}>
          <Box
            display="flex"
            flexGrow={1}
            sx={{ flexDirection: "column", justifyContent: "space-between" }}
            onClick={(event) =>
              (window.location.href = `/#/researcher/${researcherId}/project/${projectId}/activity/${activity.id}`)
            }
            height="100%"
          >
            <div>
              <h1 style={{ marginTop: 0 }}>{activity.name}</h1>
            </div>
            <Button
              variant={"outlined"}
              disableElevation={false}
              disabled={true}
              style={{
                marginLeft: 6,
                marginRight: "auto",
                height: 25,
                marginTop: 12.5,
                marginBottom: 12.5,
                borderRadius: 15,
                padding: "0px 10px",
                fontSize: 14,
                backgroundColor:
                  activity.schedule &&
                  activity.schedule.length > 0 &&
                  dayjs(activity.schedule[0]?.start_date) <= dayjs(new Date()) &&
                  !activity.schedule[0]?.end_date
                    ? "#ECFDF3"
                    : "#F9FAFB",
                borderColor:
                  activity.schedule &&
                  activity.schedule.length > 0 &&
                  dayjs(activity.schedule[0]?.start_date) <= dayjs(new Date()) &&
                  !activity.schedule[0]?.end_date
                    ? "#ABEFC6"
                    : "#EAECF0",
                outlineColor:
                  activity.schedule &&
                  activity.schedule.length > 0 &&
                  dayjs(activity.schedule[0]?.start_date) <= dayjs(new Date()) &&
                  !activity.schedule[0]?.end_date
                    ? "#ABEFC6"
                    : "#EAECF0",
                color:
                  activity.schedule &&
                  activity.schedule.length > 0 &&
                  dayjs(activity.schedule[0]?.start_date) <= dayjs(new Date()) &&
                  !activity.schedule[0]?.end_date
                    ? "#067647"
                    : "#717BBC",
                boxShadow: "0px 0px 0px 0px",
              }}
            >
              {!activity.schedule || activity.schedule.length <= 0
                ? `${t("Concept")}`
                : dayjs(activity.schedule[0]?.start_date) <= dayjs(new Date()) && !activity.schedule[0]?.end_date
                ? `${t("Published")}`
                : `${t("Scheduled")}`}
            </Button>
          </Box>
          <Box
            style={{
              textAlign: "right",
              marginBottom: "auto",
            }}
          >
            <IconButton
              style={{
                marginLeft: "auto",
              }}
              onClick={handleClickMenu}
            >
              <Icon>more_vert</Icon>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={displayMenu}
              onClose={handleCloseMenu}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {menuOptions.map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={() => {
                    option.onClick?.()
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </Grid>
      <ActivityDialog
        activity={activity}
        projectId={projectId}
        onClose={() => setActivityDialog(false)}
        open={activityDialog}
        type="update"
        addOrUpdateActivity={onUpdateActivity}
        activities={activities}
        researcherId={researcherId}
      />
      <ConfirmationDialog
        open={deleteConfirmationDialog}
        confirmAction={deleteActivity}
        confirmationMsg={`Are you sure you want to delete ${activity.name}?`}
      />
    </>
  )
}
