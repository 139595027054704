import { Box, Button } from "@material-ui/core"
import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import QuestionTypesData, {
  FORM_STRUCTURE_TYPE_KEY,
  QUESTION_GROUP_TYPE_ID,
  QUESTION_TYPE_KEY,
} from "../../../../data/questionTypes"
import { Question } from "../../../../types/question"
import { EmptyIndicator } from "../../../shared/EmptyIcon"
import ItemsStackLayout from "../Overview/ItemsStackLayout"
import QuestionTypeIconBox from "./QuestionTypeIcon"

export interface IQuestionListProps {
  questions: any[]
  setQuestions: Dispatch<SetStateAction<any[]>> // This is necessary for SortableJs to maintain state
  onAddQuestion?: () => void
  onEditQuestion?: (question: Question) => void
  onEditQuestionEntry?: (question: Question) => void
  onDeleteQuestion?: (question: Question) => void
  onSaveActivity?: () => void
}

const getQuestionType = (type: string) => {
  return [...QuestionTypesData[QUESTION_TYPE_KEY].fields, ...QuestionTypesData[FORM_STRUCTURE_TYPE_KEY].fields].find(
    (q) => q.type === type
  )
}

/**
 * Feature component that displays a list of questions.
 *
 * Uses the ItemsStackLayout component to display the list of questions.
 */
export default function QuestionsList(props: IQuestionListProps) {
  const { questions, setQuestions, onAddQuestion, onEditQuestion, onDeleteQuestion, onSaveActivity } = props
  const { t } = useTranslation()

  //if (questions.length === 0) run onAddQuestion
  if (questions.length === 0) {
    onAddQuestion()
  }

  return (
    <Box>
      <ItemsStackLayout title={t("Questions")} onClickAddButton={onAddQuestion}>
        {questions.length > 0 ? (
          <ItemsStackLayout.Stack list={questions} setList={setQuestions}>
            {questions.map((question, index) => (
              // Render questions as list items
              <QuestionListItem
                question={question}
                questions={questions}
                setQuestions={setQuestions}
                key={question.id}
                index={index}
                onEditQuestion={onEditQuestion}
                onDeleteQuestion={onDeleteQuestion}
              />
            ))}
          </ItemsStackLayout.Stack>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              paddingBlock: "50px",
            }}
          >
            <EmptyIndicator
              title={t("No questions found")}
              description={t("There are no questions here yet. Create a new question and start your project.")}
              //action={{ label: t("New question"), onClick: onAddQuestion }}
              width="100%"
            />
          </div>
        )}
      </ItemsStackLayout>

      <Button //FIXME: improve this design
        variant="contained"
        tabIndex={-1}
        disableElevation={true}
        style={{
          marginLeft: 6,
          marginRight: 6,
          color: "#fff",
          backgroundColor: "#EE9142",
          border: "1px solid #E0E0E0",
          marginTop: 6.75,
          marginBottom: 6.75,
          borderRadius: "8px",
        }}
        onClick={onSaveActivity}
      >
        {`${t("Save question set")}`}
      </Button>
    </Box>
  )
}

/**
 * Feature component that displays a single question item.
 *
 * Uses the ItemsStackLayout.Item component to display the question item.
 */
function QuestionListItem({
  question,
  setQuestions,
  questions,
  index,
  onEditQuestion,
  onEditQuestionEntry,
  onDeleteQuestion,
  ...props
}: {
  question: any
  setQuestions?: Dispatch<SetStateAction<any[]>>
  questions?: Question[]
  index: number
  onEditQuestion?: (question: Question) => void
  onEditQuestionEntry?: (question: Question) => void
  onDeleteQuestion?: (question: Question) => void
}) {
  const questionType = getQuestionType(question.type)

  // Assure question type is found
  if (!questionType) {
    console.log(`Question type not found for question: ${question.id}, will not be rendered.`)
    return null
  }

  return (
    <ItemsStackLayout.Item
      key={question.id}
      icon={
        <QuestionTypeIconBox
          backgroundColor={questionType.backgroundColor}
          borderColor={questionType.borderColor}
          icon={questionType.icon}
          text={String(index + 1)}
        />
      }
      label={question.text}
      allowSubstack={question.question_type_id === QUESTION_GROUP_TYPE_ID}
      onEdit={() => onEditQuestion?.(question)}
      onDelete={() => onDeleteQuestion?.(question)}
    >
      {question.children && (
        <Box position="relative" top={0}>
          <ItemsStackLayout.Stack
            list={question.children}
            style={{ minHeight: "40px" }}
            setList={(children) => {
              const oldParentQuestion = questions[index]

              // Question group is not allowed
              const filteredChildren = children.filter((child) => child.question_type_id !== QUESTION_GROUP_TYPE_ID)

              setQuestions?.((prev) => {
                const newQuestions = [...prev]
                newQuestions[index] = {
                  ...oldParentQuestion,
                  children: filteredChildren,
                }
                return newQuestions
              })
            }}
            variant="substack"
          >
            {question.children.map((child, index) => (
              <QuestionListItem
                key={child.id}
                question={child}
                index={index}
                onEditQuestion={onEditQuestion}
                onEditQuestionEntry={onEditQuestionEntry}
                onDeleteQuestion={onDeleteQuestion}
              />
            ))}
          </ItemsStackLayout.Stack>
        </Box>
      )}
    </ItemsStackLayout.Item>
  )
}
