import React from "react"
import { Box } from "@material-ui/core"
import { Handle, Position } from "reactflow"
import OptionsToolbar, { TriggerButton } from "../OptionsToolbar"
import { getUniqueElementId } from "../../../../../../utils/getUniqueId"
import SlotField from "../SlotField"
import { Action } from "../../../../../../types/action"
import { Option } from "../SearchableSelect"

export default function ActionNode(props) {
  const { data } = props
  const [displayOptionsToolbar, setDisplayOptionsToolbar] = React.useState(false)
  const elementRef = React.useRef(null)

  const id = getUniqueElementId()

  return (
    <div id={id} ref={elementRef}>
      <Handle type="target" position={Position.Top} isConnectable={false} />
      <Box
        width={143}
        height={93}
        bgcolor="black"
        position="relative"
        top="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
        style={{ borderRadius: "999vmax", aspectRatio: "10 / 7" }}
      >
        {displayOptionsToolbar && (
          <OptionsToolbar
            onCancel={() => setDisplayOptionsToolbar(false)}
            onEdit={data?.onEdit}
            onDelete={data?.onDelete}
          />
        )}
        <Field action={data.payload} actions={data.options} onChange={(val) => data.onChange(props.id, val)} />
        <TriggerButton onClick={() => setDisplayOptionsToolbar((prev) => !prev)} />
      </Box>
      <Handle type="source" position={Position.Bottom} isConnectable={false} />
    </div>
  )
}

function Field(props: { action: Action | null; actions: Action[]; onChange?: (action: Action) => void }) {
  const { action, actions, onChange } = props

  const options = actions.map((option) => ({
    id: option.id as string,
    label: option.name,
  }))

  const handleChange = (option: Option) => {
    if (onChange) {
      const action = actions.find((action) => action.id === option.id)

      if (action) {
        onChange(action)
      }
    }
  }

  return (
    <SlotField options={options} onChange={handleChange}>
      {action?.name}
    </SlotField>
  )
}
