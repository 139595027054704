import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  InputBase,
  MenuItem,
  Select,
  Switch,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import { useSnackbar } from "notistack"

// Local Imports
import SPARK from "spark-core"

import { useTranslation } from "react-i18next"

import dayjs from "dayjs"
import { updateSchedule } from "./ActivityMethods"

const _qrLink = (credID, password) =>
  window.location.href.split("#")[0] +
  "#/?a=" +
  btoa([credID, password, SPARK.Auth._auth.serverAddress].filter((x) => !!x).join(":"))

const CssTextField = withStyles({
  root: {
    "label + &": {},
  },
  input: {
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.75)",
  },
})(InputBase)

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: `4px 0 0 4px`,
        },
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 16,
      color: "white",
    },
    textField: {
      width: "100%",
      ["& fieldset"]: {
        borderRadius: `10px`,
      },
    },
    PopupButton: {
      marginLeft: 10,
      color: "#fff",
      backgroundColor: "#EE9142",
      border: "1px solid #E0E0E0",
      marginTop: 6.75,
      marginBottom: 40,
      borderRadius: "8px",
      width: 168,
      height: 50,
      "&:hover": { background: "#EE9142" },
    },
    popWidth: { width: "95%", maxWidth: "500px", padding: "0 40px" },
    dialogTitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.75)",
      marginTop: 30,
    },
    disabled: {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  })
)

export default function ActivityPublishDialog({
  activity,
  projectId,
  type,
  addOrUpdateActivity,
  closeDialog,
  closeActivity,
  researcherId,
  ...props
}: {
  activity?: any
  projectId?: string
  type?: string
  addOrUpdateActivity?: Function
  closeDialog?: Function
  closeActivity?: Function
  researcherId?: string
} & DialogProps) {
  const classes = useStyles()
  const [projectName, setProjectName] = useState("")
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [selectedSchedule, setSelectedSchedule] = useState(activity.schedule ?? null)
  const [loading, setLoading] = useState(false)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [repeatInterval, setRepeatInterval] = useState("none")

  const [hasEndDate, setHasEndDate] = useState(false)
  const [needsToBeTriggered, setNeedsToBeTriggered] = useState(activity.needs_trigger ?? false)
  const [minBeepInterval, setMinBeepInterval] = useState(null)
  const [maxBeepInterval, setMaxBeepInterval] = useState(null)

  useEffect(() => {
    if (activity.schedule) {
      if (activity.schedule.length > 0) {
        setStartDate(dayjs(activity.schedule[0].start_date))
        if (activity.schedule[0].end_date) {
          setHasEndDate(true)
          setEndDate(dayjs(activity.schedule[0].end_date))
          setEndTime(activity.schedule[0].end_time)
        } else {
          setHasEndDate(false)
          setEndDate(null)
          setEndTime(null)
        }
        setStartTime(activity.schedule[0].time)
        setRepeatInterval(activity.schedule[0].repeat_interval)
        setSelectedSchedule(activity.schedule[0])
        return
      }
    }
    setStartDate(null)
    setEndDate(null)
    setStartTime(null)
    setEndTime(null)
    setRepeatInterval("none")
    setSelectedSchedule(null)
  }, [props.open])

  const validate = () => {
    return (
      !(startDate === null || startTime === null || (hasEndDate && (endDate === null || endTime === null))) ||
      needsToBeTriggered ||
      !needsToBeTriggered
    )
  }

  const updateActivitySchedule = async () => {
    setLoading(true)
    if (!needsToBeTriggered) {
      activity.schedule = [
        {
          repeat_interval: repeatInterval,
        },
      ]
    }

    activity["needs_trigger"] = needsToBeTriggered
    activity["is_hidden"] = needsToBeTriggered
    console.log("activity", activity)

    updateSchedule(activity)
    setLoading(false)
  }

  useEffect(() => {
    SPARK.Study.view(projectId).then((project) => {
      setProjectName(project.name)
    })
  }, [])

  return (
    <Dialog {...props} classes={{ paper: classes.popWidth }} aria-labelledby="simple-dialog-title">
      <div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography className={classes.dialogTitle}>{`${t("Publish this survey")}`}</Typography>
        <Box mt={4} style={{ width: "100%", alignItems: "center" }}>
          <div style={{ width: "50%", display: "inline-block" }}>
            <Typography>{`${t("User initiated")}`}</Typography>
          </div>
          <div style={{ width: "50%", display: "inline-block" }}>
            <Switch
              checked={!needsToBeTriggered}
              onChange={(event) => setNeedsToBeTriggered(!event.target.checked)}
              name="checkedB"
              inputProps={{ "aria-label": "secondary checkbox" }}
              style={{
                marginLeft: "auto",
                marginRight: "0px",
              }}
            />
          </div>
        </Box>
        {!needsToBeTriggered && (
          <Box mt={4}>
            {`${t("Repeat")}`}
            <Select
              value={repeatInterval}
              variant="outlined"
              onChange={(event) => setRepeatInterval(event.target.value.toString())}
              style={{
                marginTop: "10px",
                width: "100%",
              }}
            >
              <MenuItem value="none">{`${t("None")}`}</MenuItem>
              <MenuItem value="daily">{`${t("Every day")}`}</MenuItem>
              <MenuItem value="weekly">{`${t("Every week")}`}</MenuItem>
              <MenuItem value="monthly">{`${t("Every month")}`}</MenuItem>
              <MenuItem value="yearly">{`${t("Every year")}`}</MenuItem>
            </Select>
          </Box>
        )}
        <Box textAlign="right" mt={2}>
          <Button
            onClick={() => {
              updateActivitySchedule()
              closeDialog()
              closeActivity()
            }}
            disabled={!validate()}
            className={!validate() ? classes.disabled + " " + classes.PopupButton : classes.PopupButton}
          >
            <Typography className={classes.buttonText}>
              {selectedSchedule ? `${t("Update")}` : `${t("Continue")}`}
            </Typography>
          </Button>
        </Box>
      </div>
    </Dialog>
  )
}
