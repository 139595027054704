import { Box, Button, Icon, Typography, makeStyles } from "@material-ui/core"
import React from "react"
import { ReactComponent as FileTypeIcon } from "../../icons/FileType.svg"

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    top: 0,
    marginInline: "auto",
    width: "100%",
    aspectRatio: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    alignContent: "center",
    fontSize: "75px",
    color: "#EE9142",
    padding: "10px",
    width: "100%",
    height: "100%",
    display: "flex",
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "999vmax",
  },
  iconContainer: {
    alignContent: "center",
    fontSize: "75px",
    color: "#EE9142",
    backgroundColor: "#edf1f3",
    padding: "10px",
    width: "45%",
    aspectRatio: "1",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    aspectRatio: "1",
  },
  backgroundCircle: {
    borderRadius: "999vmax",
    border: "1px gray solid",
    aspectRatio: "1",
  },
  backgroundCircleContainer: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

export interface IEmptyIconProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function EmptyIcon(props: IEmptyIconProps) {
  const { ...restProps } = props
  const classes = useStyles()

  return (
    <div className={classes.container} {...restProps}>
      {/* Icon Container */}
      <div className={classes.iconContainer}>
        <Icon className={classes.icon}>
          <FileTypeIcon />
        </Icon>
      </div>

      {/* Background Circles Container */}
      <div className={classes.background}>
        <div style={{ position: "relative", top: 0, height: "100%" }}>
          {/* Actual circle elements */}
          {Array.from({ length: 4 }).map((_, i) => (
            <div key={i} className={classes.backgroundCircleContainer}>
              <div
                className={classes.backgroundCircle}
                style={{
                  width: `${100 - i * 16}%`,
                  opacity: 0.03,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export function EmptyIndicator(props: {
  title: string
  description: string
  action?: { label: string; onClick: () => void }
  width?: string | number
  height?: string | number
}) {
  const { width = "400px", height = "auto" } = props

  return (
    <Box
      style={{
        display: "inline-block",
        margin: "auto",
        padding: "10px 20px",
        borderRadius: "5px",
        width: width,
        height: height,
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ width: "75%", scale: "1.25" }}>
          <EmptyIcon />
        </div>
      </div>

      <div>
        <Typography align="center">
          <b>{`${props.title}`}</b>
        </Typography>
        <br />
        <Typography align="center">{props.description}</Typography>
        <br />
        {props.action && (
          <Box display="flex" justifyContent="center" width="100%">
            <Button
              onClick={props.action?.onClick}
              style={{
                marginTop: "10px",
                borderRadius: "10px",
                boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
                fontSize: "14px",
                color: "#fff",
                backgroundColor: "#EE9142",
              }}
            >
              {`${props.action?.label}`}
              <Icon style={{ marginLeft: "5px" }}>add</Icon>
            </Button>
          </Box>
        )}
      </div>
    </Box>
  )
}
