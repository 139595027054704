import { Edge, MarkerType, Node } from "reactflow"
import { Action } from "../../../../../../types/action"
import { NodeFactory } from "../factory/NodeFactory"
import { calcNodePositionRelativeToParent } from "./calcNodePosition"
import { nodeTypeKeys } from "../data/node_type_keys"

/**
 * Fill a slot node with an action node and a slot node.
 *
 * @param slotNode The slot node to fill.
 * @param setNodes The function to set the nodes.
 * @param setEdges The function to set the edges.
 * @param nodes The current nodes in the diagram.
 * @param edges The current edges in the diagram.
 * @param options The options for the action node.
 *
 * @returns void
 */
export const fillSlotAsAction = (slotNode, setNodes, setEdges, nodes: Node[], edges: Edge[], options: Action[]) => {
  const originalNode = slotNode
  const actionNodeId = slotNode.id
  const actionNode = NodeFactory.createNode(nodeTypeKeys.ACTION, {
    id: actionNodeId,
    position: {
      x: originalNode.position.x,
      y: originalNode.position.y + 100,
    },
    width: 143,
    height: 93,
  })
  actionNode.data.options = options
  actionNode.position = calcNodePositionRelativeToParent(actionNode, originalNode)

  // Create a slot node for next step in diagram
  const nodeSlot = NodeFactory.createNode(nodeTypeKeys.SLOT, {
    id: `${actionNodeId}a`,
    position: {
      x: actionNode.position.x + originalNode.width,
      y: actionNode.position.y + actionNode.height,
    },
    width: 48,
    height: 48,
  })
  nodeSlot.position = calcNodePositionRelativeToParent(nodeSlot, actionNode)
  nodeSlot.position.y += 100

  // Apply the changes to the diagram
  setNodes((nds) => nds.filter((node) => node.id !== actionNodeId).concat([actionNode, nodeSlot]))
  setEdges((eds) =>
    eds.concat([
      {
        id: `${actionNodeId}-${nodeSlot.id}`,
        type: "step",
        source: actionNodeId,
        target: nodeSlot.id,
        deletable: false,
        markerEnd: {
          type: MarkerType.Arrow,
          width: 30,
          height: 30,
        },
      },
    ])
  )
}
