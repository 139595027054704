/**
 * File for BuildSurveyForm component
 *
 * Displays a form with all the question types that can be added to a survey.
 * Once a question type is clicked, the onAddQuestion callback will be fired with the question type id
 */

import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import QuestionTypeIcon from "./QuestionTypeIcon"
import QuestionTypesData from "../../../../data/questionTypes"

export interface IBuildSurveyFormProps {
  onAddQuestion?: (type: string) => void
}

export type field = {
  label: string
  icon: string
  backgroundColor: string
  borderColor: string
  id: number
}

export default function BuildSurveyForm(props: IBuildSurveyFormProps) {
  const { onAddQuestion } = props
  const { t } = useTranslation()

  const questionTypeCats = Object.values(QuestionTypesData)

  return (
    <Paper elevation={4}>
      <Box sx={{ p: 4 }}>
        <Box>
          <Typography variant="h4">{t("Compose your question set")}</Typography>
          <Typography style={{ width: "min(70ch, 100%)" }}>
            Here, you can specify the questions to be presented in this question set.
          </Typography>
        </Box>

        {/* Render question type fields by category */}
        {questionTypeCats.map((field, index) => (
          <Box key={`c${index}`} sx={{ mt: 4 }} maxWidth={500}>
            <Typography>{field.name}</Typography>

            <Grid container spacing={1}>
              {/* Render fields */}
              {field.fields.map((field, index) => (
                <Grid item key={`f${index}`} xs={6}>
                  <QuestionType field={field} onClick={() => onAddQuestion?.(field.type)} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
    </Paper>
  )
}

function QuestionType(props: { field: field; onClick?: () => void }) {
  const { field, ...restProps } = props

  return (
    <Button
      size="large"
      fullWidth
      style={{ display: "flex", justifyContent: "flex-start", textTransform: "none" }}
      startIcon={
        <QuestionTypeIcon backgroundColor={field.backgroundColor} borderColor={field.borderColor} icon={field.icon} />
      }
      {...restProps}
    >
      {field.label}
    </Button>
  )
}
