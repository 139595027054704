"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * A sensor that may or may not be available on a physical device.
 */
var Sensor = /** @class */ (function () {
    function Sensor() {
    }
    return Sensor;
}());
exports.Sensor = Sensor;
