import { Box, Fab, Icon, Theme, createStyles, makeStyles } from "@material-ui/core"
import SPARK from "spark-core"
import React, { useEffect, useState } from "react"
import ConfirmationDialog from "../../../ConfirmationDialog"
import { Service } from "../../../DBService/DBService"
import SensorDialog from "./SensorDialog"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnWhite: {
      background: "#fff",
      borderRadius: "40px",
      boxShadow: "none",
      cursor: "pointer",
      textTransform: "capitalize",
      fontSize: "14px",
      color: theme.palette.primary.main,
      "& svg": { marginRight: 8 },
      "&:hover": {
        color: theme.palette.primary.main,
        background: "#fff",
        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
      },
    },
  })
)

export interface Sensors {
  id?: string
  study_id?: string
}
export default function UpdateSensor({
  sensor,
  type,
  projectId,
  setSensors,
  profile,
  ...props
}: {
  sensor?: Sensors
  type?: string
  projectId?: string
  setSensors?: Function
  profile?: boolean
}) {
  const classes = useStyles()
  const [confirmationDialog, setConfirmationDialog] = React.useState(0)
  const [sensorDialog, setSensorDialog] = useState(false)
  const [allSensors, setAllSensors] = useState<Array<Object>>([])
  const [participantCount, setParticipantCount] = useState(0)

  useEffect(() => {
    if (!!profile) {
      SPARK.Participant.allByStudy(projectId).then((result) => {
        setParticipantCount(result.length)
      })
    }
    getAllStudies()
  }, [])

  useEffect(() => {
    getAllStudies()
  }, [sensorDialog])

  const getAllStudies = () => {
    Service.getAll("sensors").then((sensorObj) => {
      if (sensorObj) {
        setAllSensors(sensorObj)
      }
    })
  }

  const addOrUpdateSensor = (sensor?: any) => {
    setSensorDialog(false)
    setSensors()
  }

  const confirmAction = (val) => {
    if (val === "Yes") {
      setSensorDialog(true)
    }
    setConfirmationDialog(0)
  }

  return (
    <Box>
      <Fab
        size="small"
        color="primary"
        classes={{ root: classes.btnWhite }}
        onClick={() => {
          !!profile && participantCount > 1 ? setConfirmationDialog(1) : setSensorDialog(true)
        }}
      >
        <Icon>mode_edit</Icon>
      </Fab>
      <ConfirmationDialog
        open={confirmationDialog > 0 ? true : false}
        onClose={() => setConfirmationDialog(0)}
        confirmAction={confirmAction}
        confirmationMsg={
          !!profile
            ? "Changes done to this sensor will reflect for all the participants under the study. Are you sure you want proceed?."
            : null
        }
      />
      <SensorDialog
        sensor={sensor}
        onClose={() => setSensorDialog(false)}
        open={sensorDialog}
        type="edit"
        projectId={projectId}
        addOrUpdateSensor={addOrUpdateSensor}
        allSensors={allSensors}
      />
    </Box>
  )
}
