import React from "react"
import { Box, Icon, IconButton } from "@material-ui/core"
import { Handle, Position } from "reactflow"
import NodeOptionsToolbar from "../NodeOptionsToolbar"

export default function SlotNode(props) {
  const { id, data, onFillSlot, ...restProps } = props
  const [displayNodeOptions, setDisplayNodeOptions] = React.useState(false)

  const handleSelectedOption = (nodeType) => {
    if (data.onFillSlot) {
      data.onFillSlot(id, nodeType)
    }

    setDisplayNodeOptions(false)
  }

  return (
    <Box position="relative" top="0" width="48px" height="48px" onClick={data.onClick}>
      {displayNodeOptions && <NodeOptionsToolbar onSelectedOption={handleSelectedOption} />}

      <Handle type="target" position={Position.Top} isConnectable={false} />

      <IconButton
        style={{
          backgroundColor: "orange",
          color: "white",
          borderRadius: 12,
        }}
        onClick={() => setDisplayNodeOptions(!displayNodeOptions)}
      >
        <Icon>add</Icon>
      </IconButton>

      <Handle type="source" position={Position.Bottom} isConnectable={false} />
    </Box>
  )
}
