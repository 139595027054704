// Core Imports
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Icon,
  IconButton,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import SPARK, { Study } from "spark-core"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { userLanguages } from "../../../../i18n"
import locale_lang from "../../../../locale_map.json"
import { Service } from "../../../DBService/DBService"
import { fetchPostData } from "../../SaveResearcherData"
import NewPatientDetail from "./NewPatientDetail"

const useStyles = makeStyles((theme) => ({
  dataQuality: {
    margin: "4px 0",
    backgroundColor: "#E9F8E7",
    color: "#FFF",
  },
  switchLabel: { color: "#4C66D6" },
  activityContent: {
    padding: "15px 25px 0",
  },
  btnBlue: {
    background: theme.palette.primary.main,
    borderRadius: "8px",
    minWidth: 100,
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.20)",
    lineHeight: "38px",
    cursor: "pointer",
    textTransform: "capitalize",
    fontSize: "16px",
    color: "#fff",
    "& svg": { marginRight: 8 },
    "&:hover": { background: theme.palette.primary.main },
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
    },
  },
  backdrop: {
    zIndex: 111111,
    color: "#fff",
  },
  addNewDialog: {
    maxWidth: 550,
    borderRadius: "8px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "auto",
      minWidth: 500,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  inputRounded: {
    ["& fieldset"]: {
      borderRadius: "8px",
      padding: "10px",
    },
  },
  checkboxActive: { color: theme.palette.primary.main + " !important" },
}))

export default function PatientProjectCreator({
  projects,
  researcherId,
  handleNewProject,
  closePopUp,
  organizationId,
  ...props
}: {
  projects: any
  researcherId: string
  handleNewProject: Function
  closePopUp: Function
  organizationId: string
} & DialogProps) {
  const [projectName, setProjectName] = useState("")
  const [studyLanguage, setStudyLanguage] = useState(!!projects ? projects.defaultLanguage : "")
  const classes = useStyles()
  const [duplicateCnt, setDuplicateCnt] = useState(0)
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [newId, setNewId] = useState(null)
  // const [fellowProfessionals, setFellowProfessionals] = useState([])

  const validate = () => {
    return !(
      duplicateCnt > 0 ||
      typeof projectName === "undefined" ||
      (typeof projectName !== "undefined" && projectName?.trim() === "")
    )
  }

  const saveProjectData = async (result, type) => {
    for (let resultData of result) {
      Service.addData(type, [resultData])
    }
  }

  // useEffect(() => {
  //   let authString = SPARK.Auth._auth.id + ":" + SPARK.Auth._auth.password
  //   fetchPostData(authString, organizationId, "members", "organization", "GET", undefined)
  //     .then((res) => {
  //       let fellows = res.data
  //         .filter((fellow) => fellow.id !== researcherId)
  //         .map((fellow) => {
  //           return { ...fellow, checked: false }
  //         }
  //       )
  //       setFellowProfessionals(fellows)
  //     })
  // }, [])
  // useEffect(() => {console.log(fellowProfessionals)}, [fellowProfessionals])

  useEffect(() => {
    let duplicateCount = 0
    if (!(typeof projectName === "undefined" || (typeof projectName !== "undefined" && projectName?.trim() === ""))) {
      duplicateCount = projects.filter(
        (project) => project.name?.trim().toLowerCase() === projectName?.trim().toLowerCase()
      ).length
    }
    setDuplicateCnt(duplicateCount)
  }, [projectName])

  const createNewProject = (projectName, studyLanguage) => {
    let sparkAuthId = SPARK.Auth._auth.id
    if (
      SPARK.Auth._type === "researcher" &&
      (sparkAuthId === "researcher@demo.spark.digital" || sparkAuthId === "clinician@demo.spark.digital")
    ) {
      createDemoProject(projectName)
    } else {
      createProject(projectName, studyLanguage)
    }
  }

  const createDemoProject = (projectName) => {
    setLoading(true)
    let newProjectObj: any = {}
    let project = new Study()
    project.name = projectName

    SPARK.Study.create(researcherId, project).then(async (res) => {
      // NOTE: Duplicate project creation is removed
      let result = JSON.parse(JSON.stringify(res))
      if (!!result.error) {
        enqueueSnackbar(`${t("Encountered an error: .")}` + result?.error, {
          variant: "error",
        })
      } else {
        let projectsData = {
          id: result.data,
          name: projectName,
          participant_count: 0,
          activity_count: 0,
          sensor_count: 0,
          joinable: false,
        }
        Service.addData("studies", [projectsData])
      }
    })
    setLoading(false)
    handleNewProject(newProjectObj)
    closePopUp(1)
  }

  const updateProjectLocalStorage = async (authId: string, projectName: string) => {
    let projectsSelected =
      localStorage.getItem("studies_" + authId) !== null ? JSON.parse(localStorage.getItem("studies_" + authId)) : []
    projectsSelected.push(projectName)
    localStorage.setItem("studies_" + authId, JSON.stringify(projectsSelected))
  }

  const createProject = async (projectName: string, studyLanguage: string) => {
    // NOTE: Duplicate project creation is removed
    setLoading(true)
    let authId = researcherId
    let authString = SPARK.Auth._auth.id + ":" + SPARK.Auth._auth.password
    let bodyData = {
      name: projectName,
    }
    fetchPostData(authString, organizationId, "study", "organization", "POST", bodyData).then((projectData) => {
      let newProjectId = projectData.data
      let newUriProjectID = "?study_id=" + newProjectId

      let newProjectData = {
        id: projectData.data,
        name: projectName,
        participant_count: 0,
        activity_count: 0,
        sensor_count: 0,
      }
      projectUpdate(newProjectData, projectName, studyLanguage)
    })
  }

  const projectUpdate = (newProjectData, projectName, studyLanguage) => {
    let authId = researcherId
    newProjectData.participant_count = 0
    setLoading(false)
    Service.addData("studies", [newProjectData])
    if (!!projectName) updateProjectLocalStorage(authId, projectName)
    handleNewProject(newProjectData, studyLanguage)
    if (studyLanguage !== "") {
      SPARK.Type.setAttachment(newProjectData.id, "me", "project.language", studyLanguage)
    }
    closePopUp(1)
  }

  const handleEnter = () => {
    setProjectName("")
    setStudyLanguage("")
  }

  return (
    <React.Fragment>
      <Dialog
        {...props}
        onEnter={handleEnter}
        scroll="paper"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        classes={{ paper: classes.addNewDialog }}
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="alert-dialog-slide-title" disableTypography>
          <Typography
            variant="h5"
            style={{
              marginTop: 15,
              color: "#000",
              fontSize: "1.5rem",
              fontWeight: 500,
              lineHeight: "1.334",
              letterSpacing: "0em",
              textAlign: "left",
              marginBottom: "0.5rem",
            }}
          >{`${t("Create a new study.")}`}</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              closePopUp(1)
              setProjectName("")
              setStudyLanguage("")
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={false} classes={{ root: classes.activityContent }}>
          <Box mb={2} style={{ borderRadius: "8px" }}>
            {t("Give your project a title")}
            <TextField
              error={!validate()}
              autoFocus
              fullWidth
              className={classes.inputRounded}
              variant="outlined"
              placeholder={`${t("Study title")}`}
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value)
              }}
              inputProps={{
                maxLength: 80,
                style: { padding: "10px" },
              }}
              helperText={
                duplicateCnt > 0
                  ? `${t("Unique study name required")}`
                  : !validate()
                  ? `${t("Please enter study name.")}`
                  : ""
              }
            />
          </Box>
          <Box mb={2}>
            {t("Default Language")}
            <Select
              autoFocus
              margin="dense"
              id="defaultLanguage"
              label={`${t("Default Language")}`}
              fullWidth
              value={studyLanguage}
              onChange={(event) => {
                setStudyLanguage(event.target.value)
              }}
            >
              {Object.keys(locale_lang).map((key, value) => {
                if (userLanguages.includes(key)) {
                  return <option value={key}>{locale_lang[key].native + " (" + locale_lang[key].english + ")"}</option>
                }
              })}
            </Select>
          </Box>
          {/* {fellowProfessionals.map((fellow) => (
            <Box ml={-1}>
              <Checkbox
                checked={fellow.checked}
                onChange={(event) => {
                  let newList = fellowProfessionals.map((_fellow) => {
                    if (_fellow.id == fellow.id) 
                      _fellow.checked = event.target.checked
                    return _fellow
                  })
                  setFellowProfessionals(newList);
                }}
                classes={{ checked: classes.checkboxActive }}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              {`${fellow.name}`}
            </Box>
          ))} */}
        </DialogContent>
        <DialogContent>
          {t("Description")}
          <TextField
            autoFocus
            fullWidth
            variant="outlined"
            className={classes.inputRounded}
            placeholder={`${t("Enter a description")}`}
            multiline
            rows={4}
            inputProps={{ maxLength: 500 }}
          />
        </DialogContent>
        <DialogActions>
          <Box textAlign="right" width={1} mt={1} mb={3} mx={3}>
            <Button
              onClick={() => {
                createNewProject(projectName, studyLanguage)
              }}
              color="primary"
              autoFocus
              disabled={!validate()}
              style={{ textTransform: "none" }}
              classes={{ root: classes.btnBlue }}
            >
              {`${t("Continue")}`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      {!!newId && <NewPatientDetail id={newId} />}
    </React.Fragment>
  )
}
