import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    backend: {
      preload: true,
      // for all available options read the backend's repository readme file
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    react: {
      useSuspense: false,
    },
    keySeparator: false,
    nsSeparator: false,
  })

// available language options
export const userLanguages = ["nl", "en-US", "es-ES", "hi-IN"]
export const getSelectedLanguage = (locale_lang) => {
  // TODO: of toch navigator.language gebruiken als default language??
  const matched_codes = Object.keys(locale_lang).filter((code) =>
    code.startsWith(process.env.REACT_APP_DEFAULT_LANGUAGE)
  )
  const lang = matched_codes.length > 0 ? matched_codes[0] : "en-US"
  return i18n.language ? i18n.language : userLanguages.includes(lang) ? lang : "en-US"
}

export default i18n
