"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * A participant within a study a participant cannot be enrolled in  more than one study at a time.
 */
var Participant = /** @class */ (function () {
    function Participant() {
    }
    return Participant;
}());
exports.Participant = Participant;
