import React from "react"
import Toolbar from "./Toolbar"

type NodeTypes = "eval" | "activity" | "data-source" | "action"

export default function NodeOptionsToolbar(props: { onSelectedOption?: (nodeType: NodeTypes) => void }) {
  const { onSelectedOption } = props

  const options = [
    { name: "Evaluation", icon: "add", nodeType: "eval" },
    { name: "Activity", icon: "park", nodeType: "activity" },
    { name: "Data Source", icon: "data_object", nodeType: "data-source" },
    // { name: "Action", icon: "bolt", nodeType: "action" },
  ].map((option) => ({ ...option, onClick: () => onSelectedOption?.(option.nodeType as NodeTypes) }))

  return <Toolbar options={options} />
}
