import { Box } from "@material-ui/core"
import "beautiful-react-diagrams/styles.css"
import React from "react"
import Diagram from "./SchemaContext"

export default function DecisionTreeBuilder({ projectId, trigger }: { projectId?: string; trigger?: any }) {
  return (
    <Box width="100%" height="100vh">
      <Box width="100%" height="100vh">
        <Box width="100%" height="100%" position="relative" top="0" p={24}>
          <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
            <Diagram projectId={projectId} trigger={trigger} />
          </div>
        </Box>
      </Box>
    </Box>
  )
}
