import { Box, Button, Icon, IconButton, Paper, Tooltip } from "@material-ui/core"
import React, { HTMLProps } from "react"

export type ToolbarOption = {
  name: string
  icon: string
  onClick: () => void
}

export type ToolbarProps = {
  options: ToolbarOption[]
}

export default function Toolbar(props: ToolbarProps) {
  const { options } = props

  return (
    <Box position="absolute" top="0" left="50%" zIndex={2} style={{ transform: "translate(-50%, -120%)" }}>
      <Paper elevation={12}>
        <Box display="flex" gridGap={2}>
          {options.map((option, index) => (
            <Item key={index} title={option.name} icon={option.icon} onClick={option.onClick} />
          ))}
        </Box>
      </Paper>
    </Box>
  )
}

function Item(props: { title: string; icon: string; onClick: () => void }) {
  const { title, icon, onClick } = props

  return (
    <Box bgcolor="white" sx={{ borderRadius: 12 }} p={1}>
      <Tooltip title={title} style={{ fontSize: 32 }}>
        <IconButton onClick={onClick}>
          <Icon>{icon}</Icon>
        </IconButton>
      </Tooltip>
    </Box>
  )
}
