import { Button, Dialog, DialogActions, DialogContent, MenuItem, TextField } from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import locale_role from "../../../role_map.json"

export default function AddUserDialog({
  open,
  handleClose,
  researcher,
  researchers,
  refreshResearchers,
  organizationId,
  ...props
}: {
  open: boolean
  handleClose: Function
  researcher?: any
  researchers?: any
  refreshResearchers?: Function
  organizationId?: string
}) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [name, setName] = useState(!!researcher ? researcher.name : "")
  const [role, setRole] = useState(!!researcher ? researcher.role : "")

  const addOrganizationMember = async (organizationId, researcherObj) => {
    // FIXME: do this using spark-core sdk
    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    return fetch(baseUrl + `/organization/${organizationId}/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
      },
      body: JSON.stringify(researcherObj),
    })
  }

  const addResearcher = async () => {
    let duplicates = researchers.filter((x) =>
      !!researcher
        ? x.name?.toLowerCase() === name?.trim().toLowerCase() && x.id !== researcher?.id
        : x.name?.toLowerCase() === name?.trim().toLowerCase()
    )
    if (duplicates.length > 0) {
      enqueueSnackbar(`${t("Investigator with same name already exist.")}`, {
        variant: "error",
      })
      setName(!!researcher ? researcher.name : "")
    } else {
      const researcherObj = {
        name: name,
        role: role,
      }
      if (
        !!researcher
          ? ((await SPARK.Researcher.update(researcher.id, researcherObj)) as any).error === undefined
          : ((await addOrganizationMember(organizationId, researcherObj)) as any).error === undefined
      ) {
        setName("")
        setRole(null)
        refreshResearchers()
        enqueueSnackbar(
          !!researcher
            ? `${t("Successfully updated a new investigator.")}`
            : `${t("Successfully created a new investigator.")}`,
          {
            variant: "success",
          }
        )
        handleClose()
      } else {
        enqueueSnackbar(`${t("Failed to create a new investigator.")}`, {
          variant: "error",
        })
      }
    }
  }

  const validate = () => {
    return (
      typeof name == "undefined" ||
      name === null ||
      name.trim() === "" ||
      typeof role == "undefined" ||
      role === null ||
      role.trim() === ""
    )
  }

  return (
    <Dialog open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={`${t("Name")}`}
          fullWidth
          onChange={(event) => setName(event.target.value)}
          value={name}
          helperText={
            typeof name == "undefined" || name === null || name?.trim() === "" ? `${t("Please enter name")}` : ""
          }
        />
        <TextField
          select
          margin="dense"
          id="role"
          label={`${t("Role")}`}
          fullWidth
          onChange={(event) => setRole(event.target.value)}
          value={role}
        >
          {Object.keys(locale_role).map((key, value) => {
            return (
              <MenuItem key={key} value={key}>
                {locale_role[key].native}
              </MenuItem>
            )
          })}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose()
          }}
          color="primary"
        >
          {`${t("Cancel")}`}
        </Button>
        <Button onClick={() => addResearcher()} color="primary" disabled={validate()}>
          {!!researcher ? `${t("Update")}` : `${t("Add")}`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
