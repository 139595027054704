import React, { useReducer, useState } from "react"
import Toolbar from "./Toolbar"
import { getUniqueElementId } from "../../../../../utils/getUniqueId"
import useDetectClickAway from "../../../../../hooks/useDetectClickAway"
import { Box, Button, Paper, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import NodeOptionsToolbar from "./NodeOptionsToolbar"

type OptionsToolbarProps = {
  onEdit?: (nodeType: string) => void
  onDelete?: () => void
  onCancel?: () => void
}

export default function OptionsToolbar(props: OptionsToolbarProps) {
  const { onEdit, onDelete, onCancel } = props
  const { t } = useTranslation()
  const id = getUniqueElementId()
  const elementRef = React.useRef(null)
  const [activeAction, setActiveAction] = useState<"edit" | "delete" | null>(null)

  const options = [
    { name: t("Edit"), tag: "edit", icon: "edit", onClick: () => setActiveAction("edit") },
    { name: t("Delete"), tag: "delete", icon: "delete", onClick: () => setActiveAction("delete") },
    { name: t("Cancel"), tag: "cancel", icon: "close", onClick: onCancel },
  ]

  const renderContent = () => {
    switch (activeAction) {
      case "edit":
        return <NodeOptionsToolbar onSelectedOption={onEdit} />
        break
      case "delete":
        return <ConfirmDeletePrompt onConfirm={onDelete} onCancel={() => setActiveAction(null)} />
        break
      default:
        return <Toolbar options={options} />
        break
    }
  }

  return (
    <div id={id} ref={elementRef}>
      {renderContent()}
    </div>
  )
}

export function TriggerButton(props: { onClick: () => void }) {
  const { onClick } = props

  return (
    <button
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        opacity: 0,
        cursor: "pointer",
      }}
      onClick={onClick}
    ></button>
  )
}

function ConfirmDeletePrompt(props: { onConfirm?: () => void; onCancel?: () => void }) {
  const { onConfirm, onCancel } = props
  const { t } = useTranslation()

  return (
    <Box position="absolute" top="0" left="50%" style={{ transform: "translate(-50%, -120%)" }}>
      <Paper elevation={12}>
        <Box display="flex" gridGap={6} p={2}>
          <Typography style={{ width: 210 }}>{t("Are you sure you want to delete this?")}</Typography>
          <Button variant="outlined" onClick={onCancel}>
            {t("Cancel")}
          </Button>
          <Button
            variant="outlined"
            style={{ backgroundColor: "#D92D20", color: "white", marginLeft: 8 }}
            onClick={onConfirm}
          >
            {t("Delete")}
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}
