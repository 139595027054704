import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  Select,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SPARK from "spark-core"
import i18n from "../../../i18n"
import { ResponsivePaper } from "../../Utils"
import AddActivity from "./ActivityList/AddActivity"
import ActivityList from "./ActivityList/Index"
import ProjectOverview from "./Overview/Index"
import ParticipantList from "./ParticipantList/Index"
import SensorsList from "./SensorsList/Index"
import AddTrigger from "./TriggerList/AddTrigger"
import TriggerList from "./TriggerList/Index"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    researcherMenu: {
      background: "#ffffff",
      maxWidth: "100%",
      border: 0,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flexDirection: "row",
      },
      "& span": { fontSize: 12 },
      "& div.Mui-selected": {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        "& path": { fill: theme.palette.primary.main },
      },
    },
    menuItems: {
      display: "inline-block",
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.4)",
      paddingTop: 40,
      paddingBottom: 30,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 16,
        paddingBottom: 9,
      },
      [theme.breakpoints.down("xs")]: {
        padding: 6,
      },
    },
    menuIcon: {
      minWidth: "auto",
      [theme.breakpoints.down("xs")]: {
        top: 5,
        position: "relative",
      },
      "& path": { fill: "rgba(0, 0, 0, 0.4)", fillOpacity: 0.7 },
    },
    tableContainerWidth: {
      // width: "98%",
      [theme.breakpoints.down("md")]: {
        padding: 0,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    container: {
      paddingTop: "24px",
      paddingBottom: "24px",
    },
    tableContainerWidthPad: {
      maxWidth: 1055,
      paddingLeft: 0,
      paddingRight: 0,
    },
    tableContainerDataPortalWidth: {
      width: "calc(100vw - 100px)",
      height: "calc(100vh - 50px)",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "100vw",
      maxHeight: "100vh",
      top: "0px",
      left: "100px",
      overflow: "scroll",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        left: "0px",
        width: "100vw",
        height: "calc(100vh - 150px)",
      },
    },
    dataPortalPaper: {
      height: "100%",
    },
    menuOuter: {
      paddingTop: 0,
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        padding: 0,
      },
    },
    logResearcher: {
      zIndex: 1111,
      [theme.breakpoints.up("md")]: {
        height: "50px",
        width: "100%",
        flexDirection: "row",
        marginTop: 51,
        marginBottom: 5,
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "50px",
        width: "100%",
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,
      },
      outline: "#D0D5DD solid 1px",
    },
    btnCursor: {
      "&:hover div": {
        cursor: "pointer !important",
      },
      "&:hover div > svg": {
        cursor: "pointer !important",
      },
      "&:hover div > svg > path": {
        cursor: "pointer !important",
      },
      "&:hover div > span": {
        cursor: "pointer !important",
      },
    },
  })
)

export default function ProjectView({ project, id, onParticipantSelect, mode, tab, researcherId, ...props }) {
  const [notificationColumn, setNotification] = useState(false)
  const supportsSidebar = useMediaQuery(useTheme().breakpoints.up("md"))
  const [participants, setParticipants] = useState([])
  const [activities, setActivities] = useState([])
  const [sensors, setSensors] = useState([])
  const [triggers, setTriggers] = useState([])
  const [updatedData, setUpdatedData] = useState(null)
  const [deletedData, setDeletedData] = useState(null)
  const [search, setSearch] = useState(null)
  const [researcher, setResearcher] = useState(null)
  const [order, setOrder] = useState(localStorage.getItem("order") ? JSON.parse(localStorage.getItem("order")) : true)
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [joinable, setJoinable] = useState(false)

  useEffect(() => {
    SPARK.Study.view(id).then((data) => {
      setJoinable(data.joinable)
    })
    if (localStorage.getItem("lang")) {
      i18n.changeLanguage(JSON.parse(localStorage.getItem("lang")))
    } else {
      // FIXME: FIXME: do this with spark-core sdk
      let headers = new Headers()
      headers.set("Authorization", "Basic " + btoa(SPARK.Auth._auth.id + ":" + SPARK.Auth._auth.password))
      const url =
        (process.env.NODE_ENV === "production" ? "https" : "http") +
        "://" +
        (!!SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.lamp.digital") +
        "/study/" +
        id +
        "/tag/project.language"
      fetch(url, { method: "GET", headers: headers })
        .then((response) => response.json())
        .then((body) => {
          let data = body.data
          if (data) {
            i18n.changeLanguage(data)
          }
        })
    }
    getAllParticipants().then(setParticipants)
    getAllActivities().then((activities) => {
      setActivities(activities.filter((a) => a.spec !== "group.survey.question"))
    })
    getAllSensors().then(setSensors)

    getAllTriggers()
      .then(setTriggers)
      .catch((err) => {
        enqueueSnackbar(
          `${t("Failed to load study's language: errorMessage", {
            alias: id,
            errorMessage: err.message,
          })}`,
          { variant: "error" }
        )
      })
  }, [])

  const getAllParticipants = () => {
    return SPARK.Participant.allByStudy(id)
  }

  const getAllActivities = () => {
    return SPARK.Activity.allByStudy(id)
  }

  const getAllSensors = () => {
    return SPARK.Sensor.allByStudy(id)
  }

  const getAllTriggers = async () => {
    const baseUrl =
      (process.env.REACT_APP_ENV === "production" ? "https" : "http") + "://" + process.env.REACT_APP_API_URL
    try {
      const response = await fetch(`${baseUrl}/study/${id}/trigger_group`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
        },
      })
      const data = await response.json()
      return data.data
    } catch (error) {
      throw error
    }
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={tab !== "portal" ? classes.tableContainerWidth : classes.tableContainerDataPortalWidth}>
        <ResponsivePaper className={tab === "portal" ? classes.dataPortalPaper : null} elevation={0}>
          <Drawer
            anchor={supportsSidebar ? "left" : "bottom"}
            variant="permanent"
            classes={{
              paper: classes.researcherMenu + " " + classes.logResearcher,
            }}
          >
            <p
              style={{
                fontSize: 20,
                marginLeft: 30,
                marginTop: 6.75,
                marginBottom: 6.75,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                minWidth: 145,
              }}
            >
              <b>{document.title}</b>
            </p>
            {localStorage.getItem("mode") !== "other" && ( //TODO: check role?
              <Select
                value={joinable}
                variant="outlined"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                onChange={(event) => {
                  console.log("event", event)
                  if (joinable !== event.target.value) {
                    let body = { joinable: event.target.value === "true" }
                    project = event.target.value
                    const baseUrl =
                      (process.env.REACT_APP_ENV === "production" ? "https" : "http") +
                      "://" +
                      process.env.REACT_APP_API_URL
                    fetch(baseUrl + `/study/${id}/toggle`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
                      },
                      body: JSON.stringify(body),
                    })
                      .then((res) => res.json())
                      .then(
                        (res) => {
                          setJoinable(res.data.joinable)
                        },
                        (err) => {
                          enqueueSnackbar(
                            `${t("Failed setting study: errorMessage", {
                              alias: project,
                              errorMessage: err.message,
                            })}`,
                            { variant: "error" }
                          )
                        }
                      )
                  }
                }}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  height: 25,
                  marginTop: 12.5,
                  marginBottom: 12.5,
                  borderRadius: 15,
                  padding: "0px 10px",
                  fontSize: 14,
                  backgroundColor: joinable ? "#ECFDF3" : "#F9FAFB",
                  borderColor: joinable ? "#ABEFC6" : "#EAECF0",
                  outlineColor: joinable ? "#ABEFC6" : "#EAECF0",
                  color: joinable ? "#067647" : "#717BBC",
                }}
              >
                <option
                  value="false"
                  style={{ backgroundColor: "#F9FAFB", borderColor: "#EAECF0", color: "#717BBC" }}
                >{`${t("concept")}`}</option>
                <option
                  value="true"
                  style={{ backgroundColor: "#ECFDF3", borderColor: "#ABEFC6", color: "#067647" }}
                >{`${t("Active")}`}</option>
              </Select>
            )}

            {localStorage.getItem("mode") !== "other" && ( //TODO: check role?
              <Button
                variant={tab === "overview" ? "contained" : "text"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: tab === "overview" ? "#fafafa" : "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                onClick={(event) => (window.location.href = `/#/researcher/${researcherId}/project/${id}/overview`)}
              >
                {`${t("Overview")}`}
              </Button>
            )}
            {localStorage.getItem("mode") === "other" && ( //TODO: check role?
              <Button
                variant={tab === "activities" ? "contained" : "text"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: tab === "activities" ? "#fafafa" : "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                onClick={(event) => {
                  window.location.href = `/#/researcher/${researcherId}/project/${id}/activities`
                }}
              >
                {`${t("Question Set")}`}
              </Button>
            )}
            {localStorage.getItem("mode") === "simple" && ( //TODO: check role?
              <Button
                variant={tab === "activities" ? "contained" : "text"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: tab === "activities" ? "#fafafa" : "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                onClick={(event) => {
                  window.location.href = `/#/researcher/${researcherId}/project/${id}/activities`
                }}
              >
                {`${t("Question Set")}`}
              </Button>
            )}
            {localStorage.getItem("mode") === "advanced" && ( //TODO: check role?
              <Button
                variant={tab === "activities" ? "contained" : "text"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: tab === "activities" ? "#fafafa" : "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                onClick={(event) => {
                  window.location.href = `/#/researcher/${researcherId}/project/${id}/activities`
                }}
              >
                {`${t("Question Set")}`}
              </Button>
            )}
            {localStorage.getItem("mode") === "advanced" && ( //TODO: check role?
              <Button
                variant={tab === "sensors" ? "contained" : "text"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: tab === "sensors" ? "#fafafa" : "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                onClick={(event) => {
                  window.location.href = `/#/researcher/${researcherId}/project/${id}/sensors`
                }}
              >
                {`${t("Data Sources")}`}
              </Button>
            )}
            {mode !== "other" && ( //TODO: check role?
              <Button
                variant={tab === "triggers" ? "contained" : "text"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: tab === "triggers" ? "#fafafa" : "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                onClick={(event) => {
                  window.location.href = `/#/researcher/${researcherId}/project/${id}/triggers`
                }}
              >
                {`${t("Decision Tree Builders")}`}
              </Button>
            )}
            {localStorage.getItem("mode") === "advanced" && ( //TODO: check role?
              <Button
                variant={tab === "participants" ? "contained" : "text"}
                disableElevation={true}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                  backgroundColor: tab === "participants" ? "#fafafa" : "#fff",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                }}
                onClick={(event) => (window.location.href = `/#/researcher/${researcherId}/project/${id}/participants`)}
              >
                {`${t("Users")}`}
              </Button>
            )}
            {/* {localStorage.getItem("mode") !== "other" && ( //TODO: check role?
              <Button
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadOutlined />}
                disableElevation={true}
                style={{
                  marginLeft: "auto",
                  marginRight: 6,
                  color: "#000",
                  backgroundColor: "#fff",
                  border: "1px solid #E0E0E0",
                  marginTop: 6.75,
                  marginBottom: 6.75,
                  borderRadius: "8px",
                }}
                onClick={(event) => {
                  console.log("TODO: import")
                }}
              >
                {`${t("Import")}`}
              </Button>
            )} */}
            {localStorage.getItem("mode") !== "other" && (
              <AddActivity
                projectId={
                  id //TODO: check role?
                }
                researcherId={researcherId}
              />
            )}
            {localStorage.getItem("mode") !== "other" && ( //TODO: check role?
              <AddTrigger projectId={id} refreshTriggers={setTriggers} triggers={triggers} />
            )}
          </Drawer>
          {tab === "overview" && (
            <ProjectOverview
              numParticipants={participants.length}
              numActivities={activities.length}
              numSensors={0}
              numTriggers={0}
              researcherId={researcherId}
              projectId={id}
            />
          )}
          {tab === "participants" && (
            <ParticipantList
              projectId={id}
              participants={participants}
              onParticipantSelect={onParticipantSelect}
              notificationColumn={notificationColumn}
              getAllParticipants={getAllParticipants}
              mode={mode}
              setOrder={() => setOrder(!order)}
              order={order}
            />
          )}
          {tab === "activities" && (
            <ActivityList
              activities={activities}
              getAllActivities={getAllActivities}
              setOrder={() => setOrder(!order)}
              order={order}
              mode={mode}
              projectId={id}
              researcherId={researcherId}
            />
          )}
          {tab === "sensors" && (
            <SensorsList
              sensors={sensors}
              getAllSensors={getAllSensors}
              setOrder={() => setOrder(!order)}
              order={order}
              mode={mode}
              projectId={id}
            />
          )}
          {tab === "triggers" && (
            <TriggerList
              triggers={triggers}
              getAllTriggers={getAllTriggers}
              setOrder={() => setOrder(!order)}
              order={order}
              mode={mode}
              projectId={id}
              getAllActivities={getAllActivities}
              researcherId={researcherId}
            />
          )}
          {/* //FIXME: remove?
            {tab === "portal" && (
              <DataPortal
                onLogout={null}
                token={{
                  username: SPARK.Auth._auth.id,
                  password: SPARK.Auth._auth.password,
                  server: SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.lamp.digital",
                  type: "Researcher",
                  id: researcher.id,
                  name: researcher.name,
                }}
                data={SPARK.Auth}
              />
              )} */}
        </ResponsivePaper>
      </Box>
    </Container>
  )
}
