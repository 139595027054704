import React from "react"

export default function useDetectClickAway(elementRef: any, onClickAway: (element: Element) => void) {
  const element = elementRef.current

  // Detect click outside component
  React.useEffect(() => {
    if (!element) {
      return
    }

    const handleClickAway = (event) => {
      if (!event.target.closest(`#${element.id}`)) {
        onClickAway(element)
      }
    }

    document.addEventListener("click", handleClickAway)

    return () => {
      document.removeEventListener("click", handleClickAway)
    }
  }, [element, onClickAway])

  return element
}
