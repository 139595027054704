import { Button, InputBase, Paper, Theme, createStyles, makeStyles } from "@material-ui/core"
import { Add, Search } from "@material-ui/icons"
import { IconButton } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import AddUserDialog from "./AddUserDialog"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    researcherMenu: {
      background: "#ffffff",
      maxWidth: "100%",
      border: 0,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flexDirection: "row",
      },
      "& span": { fontSize: 12 },
      "& div.Mui-selected": {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        "& path": { fill: theme.palette.primary.main },
      },
    },
    menuItems: {
      display: "inline-block",
      textAlign: "left",
      color: "rgba(0, 0, 0, 0.4)",
      paddingTop: 10,
      paddingBottom: 30,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 16,
        paddingBottom: 9,
      },
      [theme.breakpoints.down("xs")]: {
        padding: 6,
      },
    },
    menuIcon: {
      minHeight: "auto",
      [theme.breakpoints.down("xs")]: {
        top: 5,
        position: "relative",
      },
      "& path": { fill: "rgba(0, 0, 0, 0.4)", fillOpacity: 0.7 },
    },
    tableContainerWidth: {
      maxHeight: 1055,
      height: "80%",
      [theme.breakpoints.down("md")]: {
        padding: 0,
      },
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    backdrop: {
      zIndex: 111111,
      color: "#fff",
    },
    tableContainerWidthPad: {
      maxWidth: 1055,
      paddingLeft: 0,
      paddingRight: 0,
    },
    tableContainerDataPortalWidth: {
      height: "calc(100vw - 100px)",
      width: "calc(100vh - 50px)",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxHeight: "100vw",
      maxWidth: "100vh",
      top: "0px",
      left: "100px",
      overflow: "scroll",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        left: "0px",
        height: "100vw",
        width: "calc(100vh - 150px)",
      },
    },
    dataPortalPaper: {
      height: "100%",
    },
    logResearcher: {
      zIndex: 1111,
      [theme.breakpoints.up("md")]: {
        height: "50px",
        width: "100%",
        flexDirection: "row",
        marginTop: 51,
      },
      [theme.breakpoints.down("sm")]: {
        height: "50px",
        width: "100%",
        flexDirection: "row",
        marginBottom: 0,
      },
      outline: "#D0D5DD solid 1px",
    },
    btnCursor: {
      "&:hover div": {
        cursor: "pointer !important",
      },
      "&:hover div > svg": {
        cursor: "pointer !important",
      },
      "&:hover div > svg > path": {
        cursor: "pointer !important",
      },
      "&:hover div > span": {
        cursor: "pointer !important",
      },
    },
  })
)

export default function HeaderButtons({ users, refreshUsers, organizationId }) {
  const [search, setSearch] = useState("")
  const { t } = useTranslation()
  const [openDialog, setOpenDialog] = useState(false)

  return (
    <React.Fragment>
      <Paper
        component="form"
        style={{
          padding: "2px 4px",
          display: "flex",
          alignItems: "center",
          maxWidth: 320,
          marginLeft: "auto",
          marginRight: 10,
          marginTop: 6.75,
          marginBottom: 6.75,
          borderRadius: "8px",
        }}
      >
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <Search />
        </IconButton>
        <InputBase
          style={{ flex: 1, marginLeft: 1 }}
          placeholder="Search "
          onChange={(event) => setSearch(event.target.value)}
        />
      </Paper>
      {/* <Button
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadOutlined />}
        disableElevation={true}
        style={{
          marginLeft: 10,
          marginRight: 10,
          color: "#000",
          backgroundColor: "#fff",
          border: "1px solid #E0E0E0",
          marginTop: 6.75,
          marginBottom: 6.75,
          borderRadius: "8px",
        }}
        onClick={(event) => {
          console.log("TODO: import")
        }}
      >
        {`${t("Import")}`}
      </Button> */}
      <Button
        style={{
          marginLeft: 10,
          marginRight: 35,
          color: "#fff",
          backgroundColor: "#EE9142",
          border: "1px solid #E0E0E0",
          marginTop: 6.75,
          marginBottom: 6.75,
          borderRadius: "8px",
        }}
        startIcon={<Add />}
        onClick={() => {
          console.log("click")
          setOpenDialog(true)
        }}
      >
        {`${t("Add User")}`}
      </Button>

      <AddUserDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        researcher={null}
        researchers={users}
        refreshResearchers={refreshUsers}
        organizationId={organizationId}
      />
    </React.Fragment>
  )
}
