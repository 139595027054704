"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *
 */
var AccessCitation = /** @class */ (function () {
    function AccessCitation() {
    }
    return AccessCitation;
}());
exports.AccessCitation = AccessCitation;
/**
 *
 */
var Metadata = /** @class */ (function () {
    function Metadata() {
    }
    return Metadata;
}());
exports.Metadata = Metadata;
/**
 *
 */
var Document = /** @class */ (function () {
    function Document() {
    }
    return Document;
}());
exports.Document = Document;
